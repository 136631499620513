import React, { useEffect, useState } from "react";
import { Breadcrumb, InputGroup, Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../../../Common/Footer";
import Header from "../../../Common/Header";
import eye from "../../../../Assets/images/visibleNew.png";
import bag from "../../../../Assets/images/lockNew.png";
import profile from "../../../../Assets/images/user1.png";
import editIcon from "../../../../Assets/images/edit-fill.png";
import closeIcon from "../../../../Assets/images/closeIcon.png";
import {
  getUserDatas,
  updatename,
  updategender,
  updatedob,
  updateaddress,
  updateemergencycontact,
  removeemergencycontact,
  usersdata,
  updatePhonenumber,
  getusersdatahooks,
  getUserEmail,
  sendotphooks,
  verifyotphooks,
} from "../../../../Hooks/useUserHook";
import { FaPlus } from "react-icons/fa";
import { BiSolidDownArrow } from "react-icons/bi";

import Cookies from "universal-cookie";
import Countrycode from "../../../../config/countrycode.json";
import languages from "../../../../config/languages.json";
import { getEmail } from "../../../../config/userRouteFront";
import { toast } from "react-hot-toast";
import { RxSlash } from "react-icons/rx";
import { FaChevronLeft } from "react-icons/fa6";
import AddressModal from "../../../Common/Modals/AddressModal";
import AddBank from "../../../AddBank";

//
import { API_URL, toastOptions } from "../../../../config/env";
import EditProfileModal from "../../../Common/Modals/EditProfileModal";
import { updateuserdetailsHooks } from "../../../../Hooks/useUserHostingHook";



const apiKey = "AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

const cookies = new Cookies();
const PersonalInfo = (props) => {
  const MAX_AGE = 100;
  const MIN_AGE = 18;

  const getMinAndMaxDateForDob = () => {
    const currenTime = new Date();
    const currentYear = currenTime.getFullYear();
    const currentMonth = currenTime.getMonth() + 1;
    const minYear = currentYear - MAX_AGE;
    const maxYear = currentYear - MIN_AGE;
    const minDate = new Date(`${minYear}-${currentMonth}-01`);
    const maxDate = new Date(`${maxYear}-${currentMonth}-01`);
    console.log("111 dobbbbbbbbbbbbbbbb", currentMonth, currentYear);
    return { minDate, maxDate };
  };

  const [dob, setDob] = useState(getMinAndMaxDateForDob().maxDate);
  console.log("222 dobbbbbbbbbbbbbbbb", dob);
  const [show, setShow] = useState(0);
  const [showadd, setShowAdd] = useState(false);
  const [addanother, setAddAnother] = useState(false);

  const [toggle, settoggle] = useState(true);
  console.log("toggle", toggle);
  const [userdata, setUserdata] = useState([]);
  const [firstname, setFirstname] = useState("");
  console.log("firstname--------------", firstname);
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");

  const [country, setCountry] = useState("Afghanistan");
  const [street, setStreet] = useState("");
  console.log("streeeeeeeeeeeeeet", street);
  const [flat, setFlat] = useState("");
  const [city, setCity] = useState("");
  const [stateprovince, setStateprovince] = useState("");
  const [postcode, setPostcode] = useState("");
  const [epname, setEpname] = useState("");
  console.log("epnameeeeeee", epname);
  const [relationship, setRelationship] = useState("");
  const [eplanguage, setEplanguage] = useState("");
  console.log("eplanguage", eplanguage);
  const [epemail, setEpemail] = useState("");
  console.log("epemaaaaaaaaaaaaaaaaaaaaaaaaa", epemail);
  const [epcountrycode, setEpcountrycode] = useState("Afghanistan(+93)");
  const [epphonenumber, setEpphonenumber] = useState("");
  console.log("epphoneNumber >>>>>>>", epphonenumber, epphonenumber.length);
  const [language, setLanguage] = useState("");
  const [error, setError] = useState({
    firstname: "",
    lastname: "",
    epname: "",
    gender: "",
    eprelationship: "",
    epphonenumber: "",
  });
  const [emailerr, setEmailerr] = useState(false);
  console.log("errorsssssssss", emailerr);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errState, setErrorState] = useState("");
  const [errors, setEpError] = useState({});

  const [kyc, setKyc] = useState({});
  const [phonecode, setPhonecode] = useState({});
  const [otpverify, setOtpverify] = useState(false);
  const [otp, setOtp] = useState("");
  const [comments, setComments] = useState("");
  const [joined, setJoined] = useState("");
  // const MAX_AGE = 100;
  // const MIN_AGE = 18;
  console.log("erorrrrrrrrr", errors);

  const [phonenum, setPhonenum] = useState("");
  const location = useLocation();
  const [addressmodal, setAddressModal] = useState(false);
  const [address, setAddress] = useState();
  const [coordsLoaded, setCoordsLoaded] = useState(false);
  const [locations, setLocations] = useState({});
  const [position, setPosition] = useState({});
  const [addressdata, setAddressdata] = useState({});

  //New
  const [about, setAbout] = useState("");
  const [imgUpd, setImgUpd] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [viewimg, setViewimg] = useState("");
  const [editprofimg, setEditProfimg] = useState(profile);
  const [profilePicturePath, setprofilePicturePath] = useState("");


  const [govtId, setGovtId] = useState("");
const [emergencycontact, setEmergencyContact] = useState("")
  const [editProfileShow, setEditProfileShow] = useState(false);

console.log("profilePicturePath data xcvxvcxcsdf", profilePicturePath)


  const handleProfileShow = () => {
    setEditProfileShow(true);
  };
  const handleProfileClose = () => {
    setEditProfileShow(false);
  };

  const removeBlur = (count) => {
    console.log("countd data", count);
    // console.log('count hihi', count, count == 7 ,!errors.email != '', !errors.epname != '', !errors?.epphonenumber != '', !errors?.eprelationship != '')
    // // if(!errors.email != '' && !errors.epname != '' && !errors?.epphonenumber != '' && !errors?.eprelationship != '')
    // // {
    // document.querySelectorAll(".blur_ul li").forEach(function (a) {
    //     console.log("a hihi", a);
    //  count == 7 ? (errors?.epname && errors?.epemail && errors?.epphonenumber && errors?.eprelationship) ? console.log('a.style.opacity = "0.5"') (a.style.opacity = "0.5") : (toggle ? (a.style.opacity = "0.5") : (a.style.opacity = "1")) :  toggle ? (a.style.opacity = "0.5") : (a.style.opacity = "1")
    //     // toggle ? (a.style.opacity = "0.5") : (a.style.opacity = "1")
    // });

    if (document.getElementById("cancel_" + count)) {
      document
        .querySelectorAll(".blur_ul li button.editbtn")
        .forEach(function(a) {
          a.style.pointerEvents = "none";
        });
      var k = document.getElementById("cancel_" + count);

      console.log("toggggggg", toggle);
      toggle
        ? (k.style.pointerEvents = "unset")
        : document.querySelectorAll(".blur_ul li button").forEach(function(a) {
            a.style.pointerEvents = "unset";
          });

      // console.log(document.querySelector(".blur_ul li" + [count]), "fdsf");
      document.getElementById("blur_" + count).style.opacity = "1";
      // toggle
      //   ? (document.getElementById("cancel_" + count).innerText = "Cancel")
      //   : (document.getElementById("cancel_" + count).innerText = "Edit");
      toggle
        ? (document.getElementById("cancel_" + count).innerHTML =
            '<img src="' + closeIcon + '" width="14px" height="14px" />')
        : (document.getElementById("cancel_" + count).innerHTML =
            '<img src="' + editIcon + '" width="14px" height="14px" />');

      settoggle(!toggle);
      toggle ? setShow(count) : setShow("");
    }
  };

  // const getMinAndMaxDateForDob = () => {
  //   const currenTime = new Date();
  //   const currentYear = currenTime.getFullYear();
  //   const currentMonth = currenTime.getMonth();
  //   const minYear = currentYear - MAX_AGE;
  //   const maxYear = currentYear - MIN_AGE;
  //   const minDate = new Date(`${minYear}-${currentMonth}-01`);
  //   const maxDate = new Date(`${maxYear}-${currentMonth}-01`);
  //   return { minDate, maxDate };
  // };

  console.log("userdetails data", userdata)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log("userdata language data", language, userdata);
    // setLanguage(language);
    async function fetchdata() {
      var userdataaa = getUserDatas();
      var email = getUserEmail();
      var userdata = await getusersdatahooks(email);
      // console.log("userdata cookie data", userdata , email);
      // var userdata = userdatas;
      console.log(
        "userdata cookie data",
        userdata,
        userdata?.dob,
        userdata,
        userdataaa,
        userdata?.govtproof[0]
      );

      setUserdata(userdata);
      if (userdata?.govtproof?.length > 0) {
        setKyc(userdata?.govtproof[userdata?.govtproof?.length - 1]);
      }
      setComments(userdata?.govtidcomment);
      setFirstname(userdata?.firstName);
      setLastname(userdata?.lastName);
      // setDob(new Date(userdata?.dob));
      setStreet(userdata?.address?.street);
      setFlat(userdata?.address?.flat);
      setCity(userdata?.address?.city);
      setPostcode(userdata?.address?.postcode);
      setStateprovince(userdata?.address?.stateprovince);
      setCountry(userdata?.address?.country);
      setUserdata(userdata);
      setPhoneNumber(userdata?.phoneNumber);
      if (userdata?.Joined) {
        const dateObj = new Date(userdata?.Joined);
        const month = dateObj.getMonth();
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        var monthName = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ][month];
        console.log(
          "userdata?.address userAddressdata)_data",
          new Date(userdata?.Joined),
          dateObj,
          month,
          day,
          monthName
        );
        setJoined(`${day} ${monthName} ${year}`);
      }

      let addressdata = {
        street: userdata?.address?.street,
        flat: userdata?.address?.flat,
        state: userdata?.address?.stateprovince,
        country: userdata?.address?.country,
        postcode: userdata?.address?.postcode,
        city: userdata?.address?.city,
      };
      console.log("1111 userdata?.address userAddressdata)_data", addressdata);
      setAddressdata(addressdata);
      setPhonecode(userdata?.phonecode ? userdata?.phonecode : "");
      // setEpname(userdata?.emergencycontact[0].name)
      // setEpemail(userdata?.emergencycontact[0].email)
      // setEplanguage(userdata?.emergencycontact[0].language)
      // setEpcountrycode(userdata?.emergencycontact[0].countrycode)
      // setEplanguage(userdata?.emergencycontact[0].relationship)
    }
    fetchdata();
  }, []);

  // let errors = {

  // };

  useEffect(() => {
    console.log("useEff userdatasgal",userdata, userdata?.about, userdata?.govtid)
    setGender(userdata?.gender);
    setGovtId((userdata?.govtid != "" && userdata?.govtid != undefined) ? userdata?.govtid : "");
    setFirstname((userdata?.firstName != "" && userdata?.firstName != undefined) ? userdata?.firstName : "");
    setDob((userdata?.dob != "" && userdata?.dob != undefined) ? userdata?.dob : "");
    setAddress((userdata?.address != "" && userdata?.address != undefined) ? userdata?.address : "");
    setEmergencyContact((userdata?.emergency != "" && userdata?.emergency != undefined) ? userdata?.emergency : "");
    setprofilePicturePath(userdata?.profilePicturePath);
    setAbout(userdata?.about);
    setLanguage(userdata?.language);
  }, [userdata])

  const onchange = async (e) => {
    var { name, value } = e?.target;
    console.log("name", name);
    console.log("value", value);
    if (name == "firstname") {
      if (value?.length == 0) {
        setError({ ...error, ...{ ["firstname"]: "! Firstname is required" } });
      } else {
        setFirstname(value);
        setError({ ...error, ...{ ["firstname"]: "" } });
      }
    }
    if (name == "lastname") {
      if (value?.length == 0) {
        setLastname("");
        setError({ ...error, ...{ ["lastname"]: "! Lastname is required" } });
      } else {
        setLastname(value);
        setError({ ...error, ...{ ["lastname"]: "" } });
      }
    }
    if (name == "gender") {
      // if(value?.length==0)
      // {
      //     setError({...error ,...{['gender'] : "! Gender is required"}})
      // }
      // {
      setGender(value);
      setError({ ...error, ...{ ["gender"]: "" } });
      // }
    }
    if (name == "dob") {
      // if(value?.length==0)
      //     error.dob = '! Date of birth is required'
      setDob(value);
    }

    if (name == "country") {
      setCountry(value);
    }
    console.log("name", name);
    if (name == "street") {
      console.log("value", value);
      setStreet(value);
    }
    if (name == "flat") {
      setFlat(value);
    }
    if (name == "city") {
      setCity(value);
    }
    if (name == "stateprovince") setStateprovince(value);
    if (name == "postcode") setPostcode(value);
    if (name == "epname") {
      if (value?.length == 0) {
        setEpname("");
        setError({ ...error, ...{ ["epname"]: "! Name is required" } });
      } else {
        setEpname(value);
        setError({ ...error, ...{ ["epname"]: "" } });
      }
    }

    if (name == "eprelationship") {
      if (value?.length == 0) {
        setRelationship("");
        setError({
          ...error,
          ...{ ["eprelationship"]: "! Relationship is required" },
        });
      } else {
        setRelationship(value);
        setError({ ...error, ...{ ["eprelationship"]: "" } });
      }
    }

    if ((name = "eplanguage")) {
      if (value?.length == 0) {
        setEplanguage("");
        setError({ ...error, ...{ ["eplanguage"]: "! Language is required" } });
      } else {
        setEplanguage(value);
        setError({ ...error, ...{ ["eplanguage"]: "" } });
      }
    }

    if (name == "epemail") {
      console.log("epnam enter this", value);
      if (value?.length == 0) {
        console.log("entet thsisiaodfjuaopsdj");
        setEpemail("");
      } else {
        setEpemail(value);
      }
    }

    if (name == "epcountrycode") setEpcountrycode(value);
    if (name == "epphonenumber") {
      console.log("epphonenumber", value?.length);
      if (value?.length == 0) {
        setEpphonenumber("");
        setError({
          ...error,
          ...{ ["epphonenumber"]: "! Phone number is required" },
        });
      } else {
        setEpphonenumber(value);
        setError({ ...error, ...{ ["epphonenumber"]: "" } });
      }
    }

    if (name == "language") setLanguage(value);
    // setError(errors);
  };



  const getLat = async (lat, lng) => {
    let data = {
      latitude: lat,
      longitude: lng,
    };
    let pos = {
      lat: lat,
      lng: lng,
    };
    console.log("positionsssssssss", pos);
    setCoordsLoaded(true);
    setPosition(pos);
    const value = await reverseGeocode(data);
    setLocations(value);
    console.log("getLat eiei", value);
  };

  const extractAddress = (place) => {
    const address = {
      street: "",
      area: "",
      city: "",
      state: "",
      postcode: "",
      countrycode: "",
      plain() {
        const street = this.street ? this.street + ", " : "";
        const area = this.area ? this.area + ", " : "";
        const city = this.city ? this.city + ", " : "";
        const postcode = this.postcode ? this.postcode + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return street + area + city + postcode + state + this.countrycode;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      console.log("component.types vjj", component.types);
      const value = component.long_name;
      console.log("component.long_name vjj", component.long_name);

      if (types.includes("street_number")) {
        address.street = value;
      }

      if (types.includes("locality")) {
        address.area = value;
      }

      if (types.includes("administrative_area_level_3")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.postcode = value;
      }
      if (types.includes("country")) {
        address.countrycode = value;
      }
      if (types.includes("plus_code")) {
        address.street = value;
      }
    });
    console.log("addressinside", address);
    return address;
  };

  const getAddressFromGmap = async (data) => {
    console.log("data>>>>>>>>>>>>>....", data);
    // setShowAdd(false);
    console.log("getAddressFromGmap", data);
    const pos = { lat: data.latitude, lng: data.longitude };
    setPosition(pos);
    const value = await reverseGeocode(data);
    value.lat = pos.lat;
    value.lng = pos.lng;
    setLocations(value);
    console.log("getAddressFromGmap eiei", value);
    props.onGetPlaceLocation(value);
  };

  const getCurrentLocation = async () => {
    // setShowAdd(false);
    navigator.geolocation.getCurrentPosition(async (location) => {
      console.log("current locatio.mmmn :", location);
      const pos = {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      };
      setPosition(pos);
      const value = await reverseGeocode(location.coords);
      value.lat = pos.lat;
      value.lng = pos.lng;
      setLocations(value);
      console.log("getCurrentLocation eiei", value);

      props.onGetPlaceLocation(value);
    });
  };

  const reverseGeocode = async ({ latitude: lat, longitude: lng }) => {
    console.log("lat,lng", lat, lng);
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    // searchInput.current.value = "Getting your location...";
    let addr = {};
    await fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        console.log("placessssssssssss", place, location);

        let _address = extractAddress(place);
        delete _address.plain;
        // setAddress(_address);
        //   console.log("_address.plain()",_address.plain());
        //   searchInput.current.value = _address.plain();
        console.log("address object:", _address);
        setCoordsLoaded(true);
        addr = _address;
      });

    return addr;
  };

  const updateusername = async () => {
    console.log("updated name function");
    let updatenameerror = {};
    const payload = {
      email: userdata.email,
      firstname: firstname,
      lastname: lastname,
    };
    console.log(
      "payload data",
      payload,
      firstname?.length != 0 &&
        firstname != undefined &&
        firstname != "undefined" &&
        (lastname?.length != 0 &&
          lastname != undefined &&
          lastname != "undefined")
    );
    if (
      firstname?.length != 0 &&
      firstname != undefined &&
      firstname != "undefined" &&
      (lastname?.length != 0 &&
        lastname != undefined &&
        lastname != "undefined")
    ) {
      setShow(0);
      let updatedname = await updatename(payload);
      console.log("updated name", updatedname?.data?.record);
      // cookies.set("User-Datas", updatedname.data.record);
      if (updatedname?.data?.record) {
        setUserdata(updatedname.data.record);
      }
      removeBlur(1);
    } else {
      if (
        firstname?.length == 0 ||
        firstname != undefined ||
        firstname != "undefined"
      ) {
        updatenameerror.firstname = "! Firstname is required";
        // setError({...error ,...{['firstname'] : "! Firstname is required"}})
      }
      if (
        lastname?.length == 0 ||
        lastname != undefined ||
        lastname != "undefined"
      ) {
        console.log("invalid lastname", error);
        updatenameerror.lastname = "! Lastname is required";
        // setError({...error ,...{['lastname'] : "! Lastname is required"}})
      }

      setError(updatenameerror);
      removeBlur(1);
    }
    console.log("errors", error);
  };

  const handlegender = async () => {
    console.log("handle gender function");
    setShow(0);
    let payload = {
      email: userdata.email,
      gender: gender,
    };
    console.log("payload data", payload);

    let updatedgender = await updategender(payload);
    console.log("updated gender", updatedgender.data.record);
    setUserdata(updatedgender.data.record);
    // cookies.set("User-Datas", updatedgender.data.record);
    removeBlur(2);
    // setError({ ...error, ...{ ["gender"]: "! Gender is required" } });
  };

  const handledob = async () => {
    console.log("handle dob function");
    setShow(0);
    let payload = {
      email: userdata.email,
      dob: dob,
    };
    console.log("handledob payload", payload);
    let updateddob = await updatedob(payload);
    console.log("updated dob ", updateddob.data.data.record);
    setUserdata(updateddob.data.data.record);
    removeBlur(3);
    // cookies.set("User-Datas", updateddob.data.data.record);
  };

  const handleaddress = async (addressdata) => {
    console.log(
      "handle address function",
      street,
      flat,
      city,
      stateprovince,
      postcode,
      country,
      addressdata
    );
    // setShow(0);
    if (address) {
      setShow(0);
      let payload = {
        address: {
          street: addressdata?.street,
          flat: addressdata?.area,
          city: addressdata?.city,
          stateprovince: addressdata?.state,
          postcode: addressdata?.postcode,
          country: addressdata?.countrycode,
        },
        email: userdata.email,
      };
      console.log("address payload", payload);
      let updatedaddress = await updateaddress(payload);
      setUserdata(updatedaddress?.data?.data?.record);
      removeBlur(6);
    } else {
      toast.error("Fill all the fields !");
    }
    // cookies.set("User-Datas", updatedaddress?.data?.data?.record);
  };

  const emailValidation = () => {
    console.log(
      "epemail>.............",
      epemail,
      epemail != "",
      epemail == null
    );
    if (
      epemail != "" &&
      epemail != null &&
      epemail != undefined &&
      epemail != "undefined"
    ) {
      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
      var validation = emailRegex.test(epemail);
      console.log("validztauoin", validation);

      if (!validation) {
        return validation;
      } else {
        return validation;
      }
    } else {
      let eperror = {};
      console.log("eperroer", eperror);
      eperror.epemail = "! Email is required";
      return eperror;
    }
  };

  const emergencycontactValidation = async () => {
    let errors = {};
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

    if (epname == "" || epname == "undefined") {
      errors.epname = "Name is required";
    }

    if (relationship == "" || relationship == "undefined") {
      errors.eprelationship = "Relationship is required";
    }

    if (epphonenumber == "" || epphonenumber == "undefined") {
      errors.epphonenumber = "PhoneNumber is required";
    }

    if (epemail == "" || epemail == "undefined") {
      errors.epemail = "Email is required";
    } else {
      let err = emailRegex.test(epemail);
      if (!err) {
        errors.epemail = "Invalid Email Address";
      }
    }

    setEpError(errors);
    console.log("errrrrrrrrrrrrrrrrrr", errors);
    return errors;
  };

  const handleemergencycontact = async () => {
    console.log("handle emerfgency contact function");
    let eperror = {};
    console.log("eperrorsxssdfadfasd", eperror);
    // const valid = emailValidation();
    let validation = await emergencycontactValidation();
    console.log("valid valueeeee", validation);

    console.log("eperrrrorssssssss", eperror);

    let payload = {
      email: userdata.email,
      emergencycontact: {
        name: epname,
        relationship: relationship,
        email: epemail,
        countrycode: epcountrycode,
        phonenumber: epphonenumber,
        language: language,
      },
    };

    let Objlen = Object.keys(validation).length;
    console.log("Obklength", Objlen);

    if (Objlen == 0) {
      let updatedemergencycontact = await updateemergencycontact(payload).then(
        async () => {
          let datas = await usersdata(payload.email);
          console.log("userdaas", datas.data.data[0]);
          // cookies.set("User-Datas", datas?.data?.data[0]);
          setUserdata(datas?.data?.data[0]);
        }
      );
      await removeBlur(7);
      // console.log("updated emergencycontact", updatedemergencycontact.data);
    }
    console.log("handleemergencycontact payload", payload);
    // if (epname.length != 0 && relationship.length != 0 && epphonenumber.length != 0 && valid) {
    //     setShow(0);
    //
    //     // console.log("updated emergencycontact", updatedemergencycontact.data);

    // }
    // else {
    //     console.log('enter this', epname.length, relationship.length, epphonenumber.length, valid)
    //     if (epname.length == 0) {
    //         console.log('enter this one epname')
    //         eperror.epname = '! Name is required';
    //         // setError({...error ,...{['epname'] : "! Name is required"}});
    //         // setEpError({...errors,...{'epname':'!Name is required'}})
    //     }
    //     if (relationship.length == 0) {
    //         console.log('enter this one relationship')
    //         eperror.eprelationship = '! Relationship is required';
    //         // setEpError({...errors,...{'eprelationship':'!Relationship is required'}})
    //         // setError({...error ,...{['eprelationship'] : "! Relationship is required"}});
    //     }
    //     console.log('epphonenumber.length', epphonenumber.length)
    //     if (epphonenumber.length == 0) {
    //         console.log('enter this one epname')
    //         eperror.epphonenumber = '! Phone number is required';

    //     }
    //     if (!valid) {
    //         console.log('enter this onesrasdfas')
    //         setEmailerr(true)
    //     }
    //     else if (valid) {
    //         console.log('enter this', valid?.epemail)
    //         eperror.epemail = valid?.epemail
    //         // setEpError(eperror)
    //         setEmailerr(false)
    //     }
    //     setEpError(eperror)
    // }
  };

  const handleremove = async (data) => {
    let payload = {
      email: userdata?.email,
      phonenumber: data,
    };
    let removeddata = await removeemergencycontact(payload).then(async () => {
      let datas = await usersdata(payload.email);
      console.log("userdaas", datas?.data?.data[0]);
      // cookies.set("User-Datas", datas?.data?.data[0]);
      setUserdata(datas?.data?.data[0]);
    });
  };
  // updatePhoneNumber
  //   if (e?.target?.value == "") {
  //     setErrorState("Please enter mobile number");
  //   }
  //   setPhoneNumber(e.target.value);
  // };
  const updatePhoneNumber = async () => {
    console.log("STATE : ", phoneNumber, userdata?.email);
    let payload = {
      email: userdata?.email,
      phonenumber: phoneNumber,
    };
    let result = await updatePhonenumber(payload);
    console.log("THE LIVE DATA : ", result?.data);
    setUserdata(result?.data);
    // cookies.set("User-Datas", result?.data);
  };

  const handlePhoneNumber = (value, country) => {
    console.log("phoneeeeeeeee", value, country);
    const { dialCode } = country;
    let newPhoneNo = value;
    // let formData = formValue;
    if (dialCode) {
      setPhonecode(dialCode);
      setPhoneNumber(newPhoneNo.slice(dialCode.length));
      // formData = {
      //     ...formData,
      //     phoneCode: dialCode,
      //     phoneNo: newPhoneNo.slice(dialCode.length)
      // }
    } else if (value) {
      setPhoneNumber(value);
      // formData = { ...formData, ...{ phoneNo: value } }
      // setOtpStatus(true)
    }
    if (value && country) {
      // setValidateError({})
    }
    // setFormValue(formData)
    // setValidateError(validation(formData))
  };

  const handlesend = async () => {
    let payload = {
      mobile: phoneNumber,
      phonecode: phonecode,
      email: getUserEmail(),
    };
    let result = await sendotphooks(payload);
    console.log("result in handle send otp", result);
    if (result?.success == true) {
      setOtpverify(true);
      toast.success(result?.message);
    } else {
      toast.error(result?.errors?.phoneNo);
    }
  };

  const handleverify = async () => {
    let payload = {
      mobile: phoneNumber,
      phonecode: phonecode,
      otp: otp,
      email: getUserEmail(),
    };
    let result = await verifyotphooks(payload);
    console.log("result in verify otp hooks", result);
    if (result?.type == "Success") {
      toast.success("Mobile number updated successfully!");
      setOtp("");
    } else {
      toast.error(result?.errors?.phoneNo);
    }
    // removeBlur(1);
    // setError("");
    // setOtp("")
  };

  const getimage = (e) => {
    //  console.log(e.target.files[0])
    //     setEditProfimg(e.target.files[0].name)
    var imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;
    const files = e.target.files[0];
    console.log("filesdetails", files);
    if (files && files?.name) {
      if (files && files?.size > 9437184) {
        errors.image = "Too_large";
      } else if(!imageFormat.test(files?.name)) {
        errors.image = "Invalid Image";
      }
    } else {

    }
    setSelectedFile(files);
    if (files) {
      setViewimg(window.URL.createObjectURL(e.target.files[0]));
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function() {
        console.log("this", this, "this.result", this.result);
        setEditProfimg(this.result);
        setImgUpd(true);
      });
    }
  };

  const PersonalInfoSave = async () => {
    try {
      let reqData = {
        firstName: firstname,
        gender: gender,
        dob: dob,
        govtid: govtId,
        address: address,
        emergency : emergencycontact,
        email :userdata?.email,
        about : about,
        language: language,
        profilePic: selectedFile,
      }
      console.log("req datasgal", reqData,selectedFile);
      let resp = await updateuserdetailsHooks(reqData);
      console.log("resp datasga", resp);
      if(resp?.data?.type == "success") {
        toast.success("Shared your details with our team, Please  wait for admin approval, You will receive mail soon. After that, try to provide all your room details ", toastOptions);
        window.location.reload();
      }

    } catch (e) {
      console.log("PersonalInfoSave_err", e);
    }
  };

  return (
    <div className="personalinfo nocenterhead">
      <Header />
      <div className="container container_custom  person_info_edit py-4">
        <div className="header-section">
          <h2 className="header-txt mb-0 fw-700">Your Profile</h2>
          <p className="header-desc fw-500">
            Provide your personal details so we know how to reach you
          </p>
        </div>
        <div className="edit-prof mt-4">
        
          <h4 className="mb-0 fw-700">Edit Your Profile</h4>
          <div className="edit-your-profile mt-4">
            <div className="head-sec d-flex align-items-center gap-3 justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div className="profile position-relative">
                  <img
                    src={
                      !imgUpd
                        ? profilePicturePath
                          ? API_URL + profilePicturePath
                          : editprofimg
                        : viewimg
                    }
                    className="img-fluid profile-logo"
                  />
                  <span className="custom-upload-img">
                    <Button className="upload-btn">
                      <input
                        type="file"
                        onChange={(e) => {
                          setImgUpd(true);
                          getimage(e);
                        }}
                      />
                      <FaPlus className="plus-icon" />
                    </Button>
                  </span>
                </div>
                <div>
                  <h5 className="mb-0 fw-700">Hi, I’m {firstname}</h5>
                  {/* <p className="mb-0">Venice, CA</p> */}
                </div>
              </div>
              {/* <Button className="cmn-btn cmn-bg-orange px-4">Edit</Button> */}
            </div>
            <div className="prof-desc mt-4">
              <div className="mb-3">
                <h5 className="mb-2 fw-700">About</h5>
                <p className="mb-0 f-15 fw-500">
                  {about}
                  {/* Professional social media evangelist. Friendly zombie fanatic.
                  Food practitioner. Avid analyst. Evil entrepreneur. Falls down
                  a lot. Analyst. Coffee guru. Music fan. Professional thinker.
                  Avid social media fanatic. Unable to type with boxing gloves
                  on. Prone to fits of apathy. Falls down a lot. */}
                </p>
              </div>
              <div className="mb-3">
                <h5 className="mb-2 fw-700">Languages spoken</h5>
                <p className="mb-0 f-15 fw-500">{language}</p>
              </div>
              <div className="mb-3">
                <h5 className="mb-2 fw-700">Joined</h5>
                <p className="mb-0 f-15 fw-500">{joined}</p>
              </div>
              <div className="">
                <h5 className="mb-2 fw-700">
                  Email{" "}
                  <span className="themeclr1">(hidden from user profile)</span>
                </h5>
                <p className="mb-0 f-15 fw-500">
                  {userdata?.email ? userdata?.email : "Not provided!"}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-3">
                {/* <Button
                  className="cmn-btn cmn-bg-orange px-4"
                  onClick={handleProfileShow}
                >
                  Edit
                </Button> */}
              </div>
            </div>
          </div>
          {/* <div className="prof-btns mt-4">
            <Button className="cmn-btn cmn-bg-black">Cancel</Button>
            <Button className="cmn-btn cmn-bg-orange">Save</Button>
          </div> */}
        </div>

        <div className="edit-prof personal-info mt-4">
          <h4 className="mb-0 fw-700">Personal Info</h4>
          <div className="edit-your-profile mt-4">

          {userdata?.govtidverification == "rejected" && <div>
          <p className="text-danger fw-800 text-center"> Your personal details  rejected by admin.  Please reupload it.</p>
          </div>}
            <div className="mb-3">
              <p className="label fw-600">Legal Name</p>
              <input
                className="form-control"
                value={firstname}
                type="text"
                placeholder={firstname}
                name="firstname"
                onChange={(e) => {
setFirstname(e?.target?.value);
                }}
              />
            </div>
            <div className="mb-3">
              <p className="label fw-600">Gender</p>
              <input
                className="form-control"
                value={gender}
                type="text"
                name="gender"
                onChange={(e) => setGender(e?.target?.value)}
              />
            </div>
            <div className="mb-3">
              <p className="label fw-600">Date of birth</p>
              {/* <Datepicker
                                className="datepicker_theme_flutter"
                                //   minDate={new Date()}
                                maxDate={getMinAndMaxDateForDob().maxDate}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                selected={dob}
                                onChange={(e) => {
                                  setDob(e);
                                }}
                              /> */}
              { <input className="form-control" type="text" value={dob} onChange={(e) => setDob(e?.target?.value)}
              /> }
            </div>
            <div className="mb-3">
              <p className="label fw-600">Email Address</p>
              <input
                className="form-control"
                value={userdata?.email ? userdata?.email : "Not provided!"}
                type="text"
              />
            </div>
            <div className="mb-3">
              <p className="label fw-600">Phone Number</p>
              <input
                className="form-control"
                value={
                  userdata?.phoneNumber
                    ? userdata?.phonecode + userdata.phoneNumber
                    : "Not specified"
                }
                type="text"
              />
            </div>
            <div className="mb-3">
              <p className="label fw-600">Government ID</p>
              <input className="form-control" type="text" value={govtId} onChange={(e) => setGovtId(e?.target?.value)}/>
            </div>
            <div className="mb-3">
              <p className="label fw-600">Address</p>
              <input
                className="form-control"
                value={address}
                type="text"
                onChange={(e) => setAddress(e?.target?.value)}
              />
            </div>
            <div className="mb-3">
              <p className="label fw-600">Emergency Contact</p>
              <input
                className="form-control"
                value={emergencycontact}
                type="text"
                onChange={(e) => setEmergencyContact(e?.target?.value)}
              />
            </div>

            <div className="mb-3">
              <p className="label fw-600">About</p>
              <input
                className="form-control"
                value={about}
                type="text"
                onChange={(e) => setAbout(e?.target?.value)}
              />
            </div>


            <div className="mb-3">
              <p className="label fw-600">Language</p>
              <input
                className="form-control"
                value={language}
                type="text"
                onChange={(e) => setLanguage(e?.target?.value)}
              />
            </div>

            {/* <div className="mb-3">
              <p className="label fw-600">About</p>
              <input
                className="form-control"
                value={about}
                type="text"
                onChange={(e) => setAbout(e?.target?.value)}
              />
            </div> */}
          </div>   {console.log("userdata?.govtidverification" , userdata?.govtidverification)}
         {/* {!userdata?.govtidverification || userdata?.govtidverification == "pending" || userdata?.govtidverification == "rejected" &&  */}
         {/* {userdata?.govtidverification != "accepted" &&  */}
         <div className="prof-btns mt-4">
            <Button className="cmn-btn cmn-bg-black">Cancel</Button>
            <Button
              className="cmn-btn cmn-bg-orange"
              onClick={() => PersonalInfoSave()}
            >
              Save
            </Button>
          </div>
          {/* // } */}
          {/* } */}
        </div>

        <div className="personal-info mt-4">
          <h4 className="mb-0 fw-700">Bank Details</h4>
          <AddBank />
        </div>
        {/* <div className="membership-deatils mt-4">
          <h4 className="mb-0 fw-700">Membership Details</h4>
          <div className="edit-your-profile mt-4">
            <h5 className="mb-1 fw-600">Need to start a subscription?</h5>
            <p className="mb-0 f-15 fw-500">
              Please email{" "}
              <span className="themeclr1">email@flutterpads.com</span> to begin
              your FlutterPads membership
            </p>
            <Button className="mt-4 px-3 cmn-btn cmn-bg-orange">Email</Button>
          </div>
          <div className="prof-btns mt-4">
            <Button className="cmn-btn cmn-bg-black">Cancel</Button>
            <Button className="cmn-btn cmn-bg-orange">Save</Button>
          </div>
        </div> */}
      </div>
      <Footer />

      {/* Edit profile modal */}
      <EditProfileModal
        show={editProfileShow}
        handleClose={handleProfileClose}
      />
    </div>
  );
};

export default PersonalInfo;

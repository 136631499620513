import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../Assets/images/landingPage/logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getUserDatas,
  getUserEmail,
  getusersdatahooks,
  switchhostandguesthooks,
  userLogout,
} from "../../Hooks/useUserHook";
import { toast } from "react-hot-toast";
import Login from "../Common/Modals/LoginModal";
import { Link, useNavigate } from "react-router-dom";
import { toastOptions } from "../../config/env";

function NewNavbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [email, setEmail] = useState("");
  const [userDatas, setUserDatas] = useState({});
  const [showlog, setShowLog] = useState(false);
  const [key, setKey] = useState("");

  const navigate = useNavigate();
  console.log("userDatas_user", userDatas);
  const hostDashboard = async () => {
    if (email) {
      window.location.href =
        window.location.origin + `/host-dashboard/${email}`;
    } else {
      setShowLog(true);
    }
  };

  const logout = () => {
    userLogout().then((data) => {
      console.log("dataslogut", data);
      toast.success(data, toastOptions);
      if (data.status) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    const data = getUserEmail();
    setEmail(data);
    setUserDatas(getUserDatas());

    console.log("userDAATATASTAWETA", data, getUserDatas());
    const handleScroll = () => {
      // Check the scroll position and update state
      const scrollPosition = window.scrollY;
      if (scrollPosition > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const checkUserLogin = async (type) => {
    console.log("check user login :", email);
    if (email && email.toLowerCase() != "undefined") {
      console.log("checkalreadygovtproof",userDatas?.govtproof
      )
      console.log("userDatas?.govtidverification", userDatas?.govtidverification,
      userDatas?.govtidverification
      )
      if (
        // userDatas?.govtproof?.length > 0 &&
        // userDatas?.govtproof[0]?.verification == "accepted"
        userDatas?.govtidverification == "accepted"
      ) {
        if (parseFloat(userDatas?.hostsubscribedaycount) > 0) {
          if (type == "room") {
            window.location.href = window.location.origin + `/host/${email}`;
          } else if (type == "experience") {
            window.location.href =
              window.location.origin + `/host-experience/${email}`;
          }
        } else {
          // toast.error("Need Host Subscription!..");
          toast.error("Purchase the subscription plan and then provide all your room details")
          window.location.href = window.location.origin + `/account`;
        }
      } else {
        let vrfy =(userDatas?.govtidverification == "rejected")
          // userDatas?.govtproof?.length > 0
          //   ? userDatas?.govtproof[0]?.verification
          //   : "";
          ;
          console.log("vrfy ddata", vrfy)
          // userDatas?.govtproof?.length == 0 || vrfy == "rejected"
        if (userDatas?.govtidverification == "rejected") {
          // toast.error("Admin reject your government id & so please reupload your id");
          toast.error("your personal details rejected by admin, please reupload the details");
          window.location.href = window.location.origin + `/account/personal-info`;
        }
        if (userDatas?.govtidverification == "pending") {
          // toast.error("your government id verification is pending!");
          // toast.error("need your government id")
          toast.error("Still your personal details not provided, Please fill those details");
          window.location.href = window.location.origin + `/account/personal-info`;
        }
      }
    } else {
      setShowLog(true);
    }
  };

  const className = isScrolled ? "navbar_wht_bg" : "navbar_default_bg";

  return (
    <div className="new_navbar">
      {showlog && <Login logs={key} onDismiss={() => setShowLog(false)} />}
      <Navbar
        // expand="lg"
        className={
          isScrolled
            ? "navbar_wht_bg cmn_web_view"
            : "navbar_default_bg cmn_web_view"
        }
      >
        <Container className="container container_custom head-sec">
          <Navbar.Brand href="#">
            <div className="d-flex align-items-center">
              <img src={logo} className="navbar_custom_logo" alt="logo" />
              <h3
                className={
                  isScrolled
                    ? "logo_txt_blk ms-3 mb-0"
                    : "logo_txt_wht ms-3 mb-0"
                }
              >
                FlutterPads
              </h3>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
              <div className="nav_header_ryt_sec">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={isScrolled ? "blk_clr" : "wht_clr"}
                  >
                    Why FlutterPads?
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dd_menu_shadow">
                    <Dropdown.Item onClick={() => navigate("/about-us")}>
                      About
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate("/terms-of-service")}
                    >
                      Terms & Conditions
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={isScrolled ? "blk_clr" : "wht_clr"}
                  >
                    List your property
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dd_menu_shadow">
                    <Dropdown.Item
                      onClick={() => {
                        checkUserLogin("room");
                      }}
                    >
                      Host a Room
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => {
                      checkUserLogin("experience");
                    }}>
                      Host an Experience
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="login_btn">
                    Join Us
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dd_menu_shadow">
                    {userDatas && userDatas.email && (
                      <Dropdown.Item href="#" onClick={() => hostDashboard()}>
                        Dashboard
                      </Dropdown.Item>
                    )}
                    {userDatas && userDatas.email && (
                      <Link to="/account/personal-info" className="dropdown-item">
                        Account
                      </Link>
                    )}
                    {userDatas && userDatas.email && (
                      <Link to="/wishlist" className="dropdown-item">
                        Saved Listings
                      </Link>
                    )}
                    {userDatas && userDatas.email && <Link to="/subscriptions-list" className="dropdown-item">
                      Subscriptions
                    </Link>}
                    {userDatas && userDatas.email && (
                      <Dropdown.Item
                        onClick={async () => {
                          let payload = {
                            id: userDatas?._id,
                            host: !userDatas?.isHost,
                          };
                          let res = await switchhostandguesthooks(payload);
                          let up = await getusersdatahooks(getUserEmail());
                          setTimeout(window.location.reload(), 500);
                        }}
                      >
                        Switch To{" "}
                        {userDatas?.isHost == true ? "Travelling" : "Hosting"}
                      </Dropdown.Item>
                    )}
                    {!userDatas.email ? (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            setKey("login");
                            setShowLog(true);
                          }}
                        >
                          Login
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setKey("");
                            setShowLog(true);
                          }}
                        >
                          Sign Up
                        </Dropdown.Item>
                      </>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          logout();
                        }}
                      >
                        Logout
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Button className="login_btn" onClick={() => {
                  setKey("login");
                  setShowLog(true);
                }}>Login</Button> */}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar expand="lg" className="navbar_wht_bg cmn_mobile_view">
        <Container className="container container_custom head-sec">
          <Navbar.Brand href="#">
            <div className="d-flex align-items-center">
              <img src={logo} className="navbar_custom_logo" alt="logo" />
              <h3 className="logo_txt_blk ms-2 mb-0">FlutterPads</h3>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
              <div className="nav_header_ryt_sec">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="blk_clr">
                    Why FlutterPads?
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dd_menu_shadow">
                    <Dropdown.Item onClick={() => navigate("/about-us")}>
                      About
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate("/terms-of-service")}
                    >
                      Terms & Conditions
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="blk_clr">
                    List your property
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dd_menu_shadow">
                    <Dropdown.Item
                      onClick={() => {
                        checkUserLogin("room");
                      }}
                    >
                      Host a Room
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => {
                      checkUserLogin("experience");
                    }}>
                      Host an Experience
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="text-center mbl_login_btn">
                  {!userDatas?.email ? <Dropdown.Toggle id="dropdown-basic" className="login_btn">
                    Login
                  </Dropdown.Toggle> :
                    <Dropdown.Toggle id="dropdown-basic" className="login_btn" onClick={() => {
                      logout();
                    }}>
                      Logout
                    </Dropdown.Toggle>}
                  {console.log('userDatas?.email', userDatas?.email, !userDatas?.email)}
                  {!userDatas?.email ? (
                    <>
                      <Dropdown.Menu className="dd_menu_shadow">
                        <Dropdown.Item
                          onClick={() => {
                            setKey("login");
                            setShowLog(true);
                          }}
                        >
                          Login
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setKey("");
                            setShowLog(true);
                          }}
                        >
                          Sign Up
                        </Dropdown.Item>
                      </Dropdown.Menu>

                    </>
                  ) : <></>



                  }
                </Dropdown>

                {/* <Button className="login_btn">Login</Button> */}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NewNavbar;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UpdateCalendar from "../Modals/UpdateCalendar";
import MinStayModal from "../Modals/MinStayModal";
import MaxStayModal from "../Modals/MaxStayModal";
import AdNoticeModal from "../Modals/AdNoticeModal";
import cardimg2 from "../../../../Assets/images/calendar1.png";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import { LuPlusCircle, LuMinusCircle } from "react-icons/lu";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import HeaderDashTwo from "../../../Common/HeaderDashTwo";

const ConfirmAvail = () => {
  const history = useNavigate();
  const [customprice, setCustomPrize] = useState(false);
  const [updatecal, setUpdatecal] = useState(false);
  const [minstay, setMinStay] = useState(false);
  const [maxstay, setMaxStay] = useState(false);
  const [adnotice, setAdNotice] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <HeaderDashTwo />
      <div className="container container_custom">
        {updatecal && <UpdateCalendar onDismiss={() => setUpdatecal(false)} />}

        {minstay && <MinStayModal onDismiss={() => setMinStay(false)} />}
        {maxstay && <MaxStayModal onDismiss={() => setMaxStay(false)} />}
        {adnotice && <AdNoticeModal onDismiss={() => setAdNotice(false)} />}

        <div className="house-rules common-dash-guest py-4 py-lg-5">
          <div className="header-sec">
            <h1 className="mb-1 fw-700 header-txt">
              Confirm your availability
            </h1>
            <p className="mb-0 fw-500 header-desc">
              Use your calendar and availability settings to customize how and
              when you want to host. You can always fine-tune these later.
            </p>
          </div>
          <div className="row">
            <div className="order-2 order-md-1 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="mt-4">
                <h4 className="mb-0 fw-700">Hosting Details</h4>
                <ul className="ps-0 setrule mt-3">
                  <li className="mb-3">
                    <div className="">
                      <p className="mb-0 rule-heading">When can you host?</p>

                      <Dropdown className="custom-host-drop">
                        <Dropdown.Toggle id="dropdown-basic">
                          Select a date
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            open={true}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">
                          How short can guests stay?
                        </p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">1 Night</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">
                          What is the longest a guest can stay?
                        </p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">1 Night</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">
                          Notice is required before a guest can check in?
                        </p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">12 Hours</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="order-1 order-md-2 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center mt-3 mt-lg-0">
              <img src={cardimg2} className="img-fluid guest" />
            </div>
          </div>
          {/* <div className="d-flex align-items-center justify-content-start mt-2">
          <button className="btn btn-theme">Save</button>{" "}
        </div> */}
        </div>
      </div>
    </>
  );
};

export default ConfirmAvail;

import React, { Component, useCallback, useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";

import Scrollbars from "react-custom-scrollbars";

import img1 from "../../../Assets/images/filter/beaches.png";
import img2 from "../../../Assets/images/filter/bed.png";
import img3 from "../../../Assets/images/filter/camp.png";
import img4 from "../../../Assets/images/filter/cave.png";
import img5 from "../../../Assets/images/filter/design.png";
import img6 from "../../../Assets/images/filter/island.png";
import img7 from "../../../Assets/images/filter/lake.png";
import img8 from "../../../Assets/images/filter/national.png";
import img9 from "../../../Assets/images/filter/omg.png";
import img10 from "../../../Assets/images/filter/pools.png";
import img11 from "../../../Assets/images/filter/tiny.png";

import { API_URL } from "../../../config/env";
const Amenities = (props) => {
  console.log("props jije", props);
  const [amenitiesList, setAmenitiesList] = useState(
    localStorage.getItem("amenitiesIndex")
      ? JSON.parse(localStorage.getItem("amenitiesIndex"))
      : []
  );
  console.log("amenitiesList", amenitiesList);
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("amenities")
      ? JSON.parse(localStorage.getItem("amenities"))
      : []
  );
  const [checked, setChecked] = useState(false);
  var category = [];
  const setData = async (val, index, path) => {
    category = amenitiesList;
    var index = selectedIndex;
    console.log("val in path", val, index, path, category);
    if (!category?.includes(val?.type)) {
      console.log("val in include", val?.type);
      category?.push(val?.type);
      index?.push(val);
      setAmenitiesList(category);
      setSelectedIndex(index);
      console.log("Category :", category);
      localStorage.setItem("amenitiesIndex", JSON.stringify(category));
    } else if (category?.includes(val?.type)) {
      console.log("val in not  include", val?.type);
      let inx = category?.indexOf(val?.type);
      if (inx > -1) {
        category?.splice(inx, 1);
        index?.splice(inx, 1);
        setAmenitiesList(category);
        setSelectedIndex(index);
      }

      console.log("category :", category);
      localStorage.setItem("amenitiesIndex", JSON.stringify(category));
    }
    console.log("onGetAmenities daiii", index);
    props.onGetAmenities(index);
    setChecked(!checked);
  };
  useEffect(() => {
    props?.onstatus();
    localStorage.getItem("amenitiesIndex") &&
      setAmenitiesList(JSON.parse(localStorage.getItem("amenitiesIndex")));
    // localStorage.getItem("amenities") &&setSelectedIndex(JSON.parse(localStorage.getItem("amenities")));

    // setSelectedIndex((localStorage.getItem("amenitiesIndex"))?JSON.parse(localStorage.getItem("amenitiesIndex")):selectedIndex);
    localStorage.getItem("amenities")
      ? props.onGetAmenities(JSON.parse(localStorage.getItem("amenities")))
      : localStorage.removeItem("amenitiesIndex");
  }, [
    selectedIndex,
    amenitiesList,
    localStorage.getItem("amenities"),
    localStorage.getItem("amenitiesIndex"),
  ]);

  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="header-txt">
        <h2 className="fw-800">Any standout amenities?</h2>
        <p className="des-txt">
          Let guests know if your place has anything additional to offer
        </p>
      </div>
      <div className="row mt-5 standoutAmenities">
        {props.data &&
          props.data.content.map((val, index) => (
            <>
              {val?.status != "Inactive" && (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4">
                  <div
                    className={
                      amenitiesList && amenitiesList?.includes(val?.type)
                        ? "ur-plc-card amenity active"
                        : "ur-plc-card amenity"
                    }
                    onClick={() => {
                      setData(val, index, props.data.path);
                    }}
                  >
                    <h5 className="heading-txt mb-2 fw-700">{val.type}</h5>
                    <p className="mb-2 desc-txt f-15 fw-500">
                     {val?.description}
                    </p>
                    <img
                      src={API_URL + props.data.path + "/" + val.image}
                      className="img-fluid offer-img"
                    />
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default Amenities;

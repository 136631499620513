import React, { useEffect, useState } from "react";
import { Form, Accordion, Dropdown } from "react-bootstrap";
import Counter from "./counter";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  reserveRoom,
  getUserEmail,
  getUserDatas,
  getCancellationPolicy,
  getusersdatahooks,
  splitReserveRoom,
  getsubscriptiondetailshooks,
} from "../../Hooks/useUserHook";
import { FaInfoCircle } from "react-icons/fa";
import Login from "../Common/Modals/LoginModal.js";
import { toast, Toaster } from "react-hot-toast";
import { API_URL, toastOptions } from "../../config/env";
import { useLocation, useNavigate } from "react-router-dom";
import NightModal from "./Modals/NightModal";
import FeeModal from "./Modals/FeeModal.js";
import moment from "moment";
import "../../Assets/css/landing.css";
import { useDispatch } from "react-redux";
import { bookingmethod } from "../../redux/action.js";
import { getUserDetails } from "../../Hooks/useUserHostingHook.js";
import user from "../../Assets/images/newLogo.png";
// import Login from "../Common/Modals/LoginModal.js";

const AvailCont = (props) => {
  let dispatch = useDispatch();
  console.log("propsssssss", props);
  const [guestcount, setGuestcount] = useState({});
  const [isRefundable, setIsRefundable] = useState(false);
  const [email, setEmail] = useState("");
  const [showlog, setShowLog] = useState(false);
  const [userData, setUserData] = useState({});
  // const [startDates , setStartDate] = useState({})
  const [startDatenew, setStartDatenew] = useState("");
  const [endDatenew, setEndDatenew] = useState("");
  const [isdates, setIsdates] = useState(true);
  const [infoNightModal, setInfoNightModal] = useState(false);
  const [infofeeModal, setInfoFeeModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [dates, setDates] = useState([]);

  const [endDate, setEndDate] = useState();
  const [arrival, setArrival] = useState(false);
  const [departure, setDeparture] = useState(false);
  const [percentage, setPercentage] = useState(0);
  // console.log('adfjhkbbashdgfa',startDates)
  // // const [legal , setLegal] = useState([]);
  const [Cancellationdate, setCancellationdate] = useState("");
  // console.log('legalsdata',legal)
  const navigate = useNavigate();
  const [diffdays, setDiffDays] = useState("");
  const [normalpercentage, setNormalPercentage] = useState("");
  const [hostuserdata, setHostUserData] = useState({});


  const [book, setBook] = useState(true)
console.log("books_datsgal", book)
  let location = useLocation()
  useEffect(() => {
    // getsubscriptiondetailshooks
    async function fetchData() {
      //
      console.log(
        "userData?.guestsubscribedaycount",
        userData,
        userData?.guestsubscribedaycount,
        userData?.guestsubscribedaycount > 0
      );
      if (userData?.guestsubscribedaycount > 0) {
        console.log("getsubscriptiondata");
        let data = await getsubscriptiondetailshooks();
        console.log("getsubscriptiondata", data?.record);
        let rec = data?.record?.find((el) => el["type"] == "Guest");
        console.log("sdfasdfasdgetsubscriptiondetailshooks recdata", rec);
        let percentage = rec["offerpercentage"];
        setPercentage(percentage);
      }
    }
    fetchData();
  }, [userData]);

  useEffect(() => {
    async function fetchData() {
      let data = await getsubscriptiondetailshooks();
      console.log("useEffect_getsubscriptiondata", data?.record);
      let rec = data?.record?.find((el) => el["type"] == "Guest");
      let percentage = rec["offerpercentage"];
      setNormalPercentage(percentage);
    }
    fetchData();
  }, [props]);

  useEffect(() => {
    if (props?.startDate && props?.endDate) {
      const date1 = props?.startDate;
      const date2 = props?.endDate;
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDiffDays(diffDays);
      console.log(diffDays + "diffDays_days");
    }
  }, [props]);

  useEffect(() => {
    if (props?.roomDetails) {
      async function fetchData() {
        console.log(
          "props?.roomDetailsprops?.roomDetailsprops?.roomDetails",
          props?.roomDetails
        );
        let gethostdata = await getUserDetails(props?.roomDetails?.email);
        console.log("get host data", gethostdata);
        setHostUserData(gethostdata[0]);
      }
      fetchData();
    }
  }, [props?.roomDetails]);


  useEffect(() => {
console.log('location data', location.pathname, location.pathname.split('/')[1] == "host");
// if(location.split("/")[0] )
if(location.pathname.split('/')[1] == "host") {
setBook(false)
} else {
  setBook(true)
}
  }, [location])
  console.log("percentage_percentage", percentage);
  // const handleDateChange = (date) => {
  //     console.log("handle date change :", date);
  //     // initial change: start by setting the startDate
  //     if (!startDatenew && !endDatenew) {
  //       setStartDatenew(date);
  //       // startDate has been set, set the end date
  //     } else if (startDatenew && !endDatenew && startDatenew < date) {
  //       setEndDatenew(date);
  //       setIsdates(false);
  //     }
  //     if (startDatenew && startDatenew > date) {
  //       setStartDatenew(date);
  //       // setEndDate('');
  //     }
  //     // user is choosing another range => set the start date
  //     // and set the endDate back to null
  //     if (startDatenew && endDatenew) {
  //       setStartDatenew(date);
  //       setEndDatenew(null);
  //     }
  //   };
  console.log("showLog_showLog", showlog);
  const bookRoom = async () => {
    console.log(
      "bookroomdata",
      startDate,
      endDate,
      new Date(endDate) > new Date(startDate)
    );
    if (new Date(endDate) > new Date(startDate)) {
      const bodyformData = new FormData();
      bodyformData.append("checkInDate", startDate);
      bodyformData.append("checkOutDate", endDate);
      bodyformData.append("id", userData?._id);
      const data = {
        email: props.email,
        hostEmail: props.roomDetails.email,
        roomId: props.roomDetails._id,
        roomTitle: props.roomDetails.title,

        firstName: props.roomDetails.firstName,
        lastName: props.roomDetails.lastName,

        totalAmount: parseInt(props.rentAmount) + parseInt(props.serviceFee),
        serviceFeeAmount: parseInt(props.serviceFee),
        nights: props.days,
        isVerified: false,
        guestCount: guestcount,
        isRefundable: isRefundable,
        accommodationAddress: props.roomDetails.location,
        instantBooking: props.roomDetails.instantBooking,
        cancellationPolicy: props.roomDetails.cancellationPolicy,
      };
      bodyformData.append("data", JSON.stringify(data));
      // let totalData = {}
      // totalData.data = data;
      // totalData.formdata = bodyformData;
      console.log("bookroom", startDate, endDate);
      console.log(
        "guestcount",
        guestcount,
        "props.rentAmount",
        props.rentAmount,
        "props.days",
        props.days,
        "props.startDate&&props.endDate",
        props.startDate,
        props.endDate
      );
      console.log("bodyFormdata", bodyformData);
      console.log(
        "rentdatesgal",
        startDate,
        endDate,
        guestcount,
        props?.rentAmount,
        props?.day
      );
      if (
        startDate &&
        endDate &&
        guestcount &&
        props.rentAmount &&
        props.days &&
        getUserEmail()
      ) {
        console.log("userData.tokenuserData.tokenuserData.token", userData.token)
        await reserveRoom(bodyformData, userData.token).then((val) => {
          console.log("data from reserve room hook :", val, val.data.message);
          if (val?.data?.type == "error") {
            toast.error("Need Guest Subscription!");
          } else if (val.data.status == false) {
            toast.error(val.data.message, toastOptions);
          } else {
            toast.success(val.data.message, toastOptions);
            // if(props.roomDetails.instantBooking){
            // window.location.href = window.location.origin + "/host-dashboard/trips/stay/" + userData?.email
            navigate(`/host-dashboard/trips/stay/${userData?.email}`, {
              state: "reservebooking",
            });
            dispatch({ type: bookingmethod, payload: "reservebooking" });
            localStorage.setItem("bookingmethod", "reservebooking");
          }
        });
        // let stringdata = JSON.stringify(data);
        // stringdata = btoa(stringdata);
        // window.location.href=`${window.location.origin}/confirm-and-pay/${stringdata}`
      } else if (!getUserEmail()) {
        setShowLog(true);
      } else {
        toast.error("complete all details to reserve!", toastOptions);
      }
    } else {
      toast.error("Choose End date should be greater than Enddate date");
    }
  };

  const splitbookRoom = async () => {
    try {
      const bodyformData = new FormData();
      bodyformData.append("checkInDate", props.startDate);
      bodyformData.append("checkOutDate", props.endDate);
      bodyformData.append("id", userData?._id);

      const data = {
        email: props.email,
        hostEmail: props.roomDetails.email,
        roomId: props.roomDetails._id,
        roomTitle: props.roomDetails.title,

        firstName: props.roomDetails.firstName,
        lastName: props.roomDetails.lastName,

        totalAmount: parseInt(props.rentAmount) + parseInt(props.serviceFee),
        serviceFeeAmount: parseInt(props.serviceFee),
        nights: props.days,
        isVerified: false,
        guestCount: guestcount,
        isRefundable: isRefundable,
        accommodationAddress: props.roomDetails.location,
        instantBooking: props.roomDetails.instantBooking,
        cancellationPolicy: props.roomDetails.cancellationPolicy,
      };
      bodyformData.append("data", JSON.stringify(data));
      console.log("splitbookroom", bodyformData);
      console.log(
        "guestcount",
        guestcount,
        "props.rentAmount",
        props.rentAmount,
        "props.days",
        props.days,
        "props.startDate&&props.endDate",
        props.startDate,
        props.endDate
      );
      if (
        props.startDate &&
        props.endDate &&
        guestcount &&
        props.rentAmount &&
        props.days &&
        getUserEmail()
      ) {
        await splitReserveRoom(bodyformData, userData?.token).then((val) => {
          console.log(
            "data from split reserve room hook :",
            val,
            val.data.message
          );
          if (val?.data?.type == "error") {
            toast.error("Need Guest Subscription!");
          } else {
            if (val.data.status == false) {
              toast.error(val.data.message, toastOptions);
            } else {
              toast.success(val.data.message, toastOptions);
              navigate(`/host-dashboard/trips/stay/${userData?.email}`, {
                state: "splitreservebooking",
              });
              dispatch({ type: bookingmethod, payload: "splitreservebooking" });
              localStorage.setItem("bookingmethod", "splitreservebooking");
            }
          }
        });
      } else if (!getUserEmail()) {
        setShowLog(true);
      } else {
        toast.error("complete all details to reserve!", toastOptions);
      }
    } catch (e) {
      console.log("splitbookRoom_err", e);
    }
  };

  const handleDateChange = (date) => {
    let dt = dates;
    if (!startDate || arrival) {
      console.log("enterrrrrr");
      setStartDate(date);
      dt[0] = date;
      setArrival(!arrival);
      setDeparture(true);
    } else if (startDate && departure && !endDate) {
      console.log("111 enterrrrrr");
      setEndDate(date);
      dt[1] = date;
    }
    if (startDate && endDate && arrival) {
      //startDate
      console.log("222 enterrrrrr", endDate);
      setStartDate(date);
      dt[0] = date;
    } else if (startDate && endDate && departure) {
      //endDate
      console.log(
        "333 enterrrrrr",
        new Date(startDate),
        new Date(endDate),
        new Date(date) > new Date(startDate)
      );
      if (new Date(date) > new Date(startDate)) {
        setEndDate(date);
        dt[1] = date;
      } else {
        toast.error("Choose End date should be greater than Start date");
      }
    }
    if (departure && !startDate && !endDate) {
      console.log("444 enterrrrrr");
      setEndDate(date);
      dt[1] = date;
      setStartDate();
      setDeparture(false);
    }
    // if (arrival) {
    //   setDeparture(false);
    // } else if (departure) {
    //   setArrival(false);
    // }

    console.log("dates", dates);
    setDates(dt);
  };

  // const handleDateChange = (date) => {
  //   if (!startDate || arrival) {
  //     setStartDates(moment(date).format("DD-MM-YYYY"));
  //     setArrival(!arrival);
  //     setDeparture(true);
  //   } else if (startDate && departure && !endDate) {
  //     setEndDate(moment(date).format("DD-MM-YYYY"));
  //   }
  //   if (startDate && endDate && arrival) {
  //     setStartDates(moment(date).format("DD-MM-YYYY"));
  //   } else if (startDate && endDate && departure) {
  //     setEndDate(moment(date).format("DD-MM-YYYY"));
  //   }
  //   if (departure && !startDate && !endDate) {
  //     setEndDate(moment(date).format("DD-MM-YYYY"));
  //     setStartDates();
  //     setDeparture(false)
  //   }

  //   // if (arrival) {
  //   //   setDeparture(false);
  //   // }
  //   // if (departure) {
  //   //   setArrival(false);
  //   // }
  // };

  const setGuestCount = (val) => {
    console.log("setGuestCount :", val);
    setGuestcount(val);
    props.onSetGuestCount(val);
  };

  const setRefundable = (value) => {
    console.log("setRefundable :", value);
    if (value == "true") {
      setIsRefundable(true);
      props.onSetCancellation(true);
    } else {
      setIsRefundable(false);
      props.onSetCancellation(false);
    }
  };

  const checkUserLogin = async (data) => {
    console.log("check user login :", email, userData);
    if (data == "reservebooking") {
      if (email) {
        // if (parseFloat(userData?.guestsubscribedaycount) > 0) {
        bookRoom();
        // } else {
        //   toast.error("Need Guest Subscription!");
        //   window.location.href = window.location.origin + `/account`;
        // }
      } else {
        setShowLog(true);
      }
    } else if (data == "splitreservebooking") {
      if (email) {
        // if (parseFloat(userData?.guestsubscribedaycount) > 0) {
        splitbookRoom();
        // } else {
        //   toast.error("Need Guest Subscription!");
        //   window.location.href = window.location.origin + `/account`;
        // }
      } else {
        setShowLog(true);
      }
    }
  };

  useEffect(() => {
    setEmail(getUserEmail());
    const data = getUserEmail();
    getusersdatahooks(data).then((dat) => {
      console.log("userdatsgal", dat);
      setUserData(dat);
    });
    setUserData(getUserDatas());
  }, [showlog]);

  useEffect(() => {
    if (props.startDate && props.endDate && props?.legal?.length > 0) {
      console.log("props?.datasgal", props);
      let data = {};
      data.startDate = props.startDate;
      data.endDate = props.endDate;
      data.legal = props.legal;
      console.log("datasdfasfas", data);
      getCancellationPolicy(data).then((res) => {
        const month = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        console.log("responeddate", res);
        let date = new Date(res.data);
        const year = new Date(res.data).getFullYear();
        console.log(
          "djflasdflasdjkfasjd",
          year,
          month[date.getMonth()],
          date.getDate()
        );
        let getdate = date.getDate();
        let getMonth = month[date.getMonth()];
        setCancellationdate({
          date: getdate,
          month: getMonth,
        });
        let startdate = new Date(props.startDate);
        let checkindate = startdate.getDate();
        let checkinmonth = month[startdate.getMonth()];
        console.log("asfjdhfgsdj", startdate.getDate());
        setStartDate(startdate);
        let enddate = new Date(props.endDate);
        setEndDate(enddate);
        // setStartDate({
        //   checkindate: checkindate,
        //   checkinmonth: checkinmonth,
        // });
        //
        console.log("sdfasdfasdfasd", startdate, enddate);
      });
    }
  }, [props.startDate, props.endDate]);

  const handleArrival = () => {
    // if (startDate) {
    setArrival(true);
    setDeparture(false);
    // }
  };

  const handleDeparture = () => {
    setDeparture(true);
    setArrival(false);
  };

  return (
    <>
      {showlog && <Login onDismiss={() => setShowLog(false)} />}
      {infoNightModal && (
        <NightModal
          spl={props?.spl}
          startDate={startDate}
          endDate={endDate}
          days={props.days}
          rentAmount={props.rentAmount}
          onDismiss={() => setInfoNightModal(false)}
        />
      )}

      {infofeeModal && <FeeModal onDismiss={() => setInfoFeeModal(false)} />}

      <div className="shadowbox p-4 stikybox room_detail">
        <div className="d-flex pricepernight gap-3 align-items-center">
          {/* <div>
            <p className="mb-3">
              {" "}
              <span>
                Regular Price <b>${props.amountPerNight} </b>
              </span>
              <span>Pernight (It may be differ)</span>
            </p>
            <p className="f-12">{props.txt}</p>
          </div>
          {!props.img ? "" : <img src={props.img} />} */}
          <div className="user-logo">
            {console.log("userData_", userData)}

            {/* https://api.flutterpads.com/userProfileImage/venkateshjjmaticz@gmail.com/profilePic.webp */}
            {!book ? 
            <img
            // src={require("../../Assets/images/user1.png")}userData
            //
            // require("../../Assets/images/user1.png")
            src={ user
            }
            className="user img-fluid"
            alt="user"
          />
            :
            <img
              // src={require("../../Assets/images/user1.png")}userData
              //
              // require("../../Assets/images/user1.png")
              src={
                hostuserdata?.profilePicturePath != ""
                  ? `${API_URL}${hostuserdata?.profilePicturePath}`
                  : user
              }
              className="user img-fluid"
              alt="user"
            />}
            {/* <img
              src={require("../../Assets/images/user.png")}
              className="user img-fluid"
              alt="user"
            /> */}
          </div>
          {hostuserdata?.firstName != undefined &&
          hostuserdata?.firstName != "" ? (
            <div className="user-details">
              <h6 className="mb-0 name f-600">
                Hosted by {hostuserdata?.firstName} 
              </h6>
            </div>
          ) : (
            <div className="user-details">
              <h6 className="mb-0 name f-600">Hosted by FlutterPads</h6>
            </div>
          )}
        </div>
        <div className="price-details my-3">
          <p className="mbr_price">
            {console.log(
              "propsssssssss_data",
              props,
              props?.normalrentamount,
              percentage
            )}
            $
            {Math.ceil(
              Number(props?.normalrentamount) -
                (Number(props?.normalrentamount) * Number(normalpercentage)) /
                  100
            )}
            <span>/month Member-Price</span>
          </p>
          <p className="non_mbr_price">
            ${props?.normalrentamount}
            <span>/month Non-Member Price</span>
          </p>
        </div>
        <div className="selectdate selct_ne h-55">
          <div className="d-flex dates exp-detail-custom_datepicker">
            <div className="col_card_in custom-width-collapse custom-width-collapse-new-cal room_detail_date_picker room_detail_date_picker_width_set">
              <Dropdown className="dd_dropdown_card" drop="down">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic-2"
                  className="inbtn inbtn_datepick_new btn btn_trans_icon p-0"
                >
                  <div className="cal_pick_dates_display">
                    <div
                      className={
                        arrival
                          ? "disabled_statedate_true"
                          : "disabled_statedate_false"
                      }
                      onClick={() => handleArrival()}
                    >
                      <Form.Group
                        className="position-relative"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Check-in</Form.Label>

                        <Form.Control
                          placeholder="Check-in"
                          // value={
                          //   props.startDate
                          //     ? new Date(props.startDate).toLocaleDateString()
                          //     : "Add Date"
                          // }
                          readOnly
                          value={
                            startDate
                              ? new Date(startDate).toLocaleDateString()
                              : "Add Date"
                          }
                          type="text"
                          aria-label="Username"
                        />
                      </Form.Group>
                    </div>
                    <div
                      className={
                        departure
                          ? "disabled_statedate_true"
                          : "disabled_statedate_false"
                      }
                      onClick={() => handleDeparture()}
                    >
                      <Form.Group className="position-relative">
                        <Form.Label>Check-out</Form.Label>
                        <Form.Control
                          placeholder="Checkout"
                          // value={
                          //   props.endDate
                          //     ? new Date(props.endDate).toLocaleDateString()
                          //     : "Add Date"
                          // }
                          readOnly
                          value={
                            endDate
                              ? new Date(endDate).toLocaleDateString()
                              : "Add Date"
                          }
                          type="text"
                          aria-label="Username"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <hr className="custom_hr mb-sm-1 mt-sm-2 my-0" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dd_menu_shadow_card country_card filter_date_picker_cutom room_detail_date_picker">
                  {/* <div className="dd_menu_shadow_card country_card filter_date_picker_cutom room_detail_date_picker"> */}

                  <Datepicker
                    multiple
                    onChange={(date) => handleDateChange(date)}
                    selectsStart={true}
                    monthsShown={2}
                    minDate={new Date()}
                    shouldCloseOnSelect={false}
                    // selected={startDate}
                    // startDate={startDate}
                    // endDate={endDate}
                    excludeDates={props?.exludedates}
                    startDate={startDate}
                    endDate={endDate}
                    inline={true}
                    // value={dates}
                  />
                  {/* </div> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {/* <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="text-dark">
                  <p className="mb-0 f-12 ">Guests</p>

                  <p className="mb-0 f-12 ">
                    {guestcount != undefined || guestcount != {}
                      ? `Adults ${guestcount.Adults},Children ${
                          guestcount.Children
                        },Infants ${guestcount.Infants},Pets ${guestcount.Pets}`
                      : ""}
                  </p>
               
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Counter
                  roomDetails={props.roomDetails}
                  onSetGuestCount={setGuestCount}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
          <div className="accordion custom_accor_body">
            <Counter
              roomDetails={props.roomDetails}
              onSetGuestCount={setGuestCount}
              book={book}
            />
          </div>
        </div>

        {/* <div className='cancell'>
                            <p className='f-14 upper mt-3 mb-2'>Cancellation policy</p>
                            <div className='cancelbox cancelbox_witho_border'>
                                <div className='d-flex jc-between '>

                                    <p className='mb-0 f-14'>non refundable <span>.</span> ${props.rentAmount} total</p>
                                    <Form.Check type="radio" name="1" value="false" defaultChecked onChange={(e)=>{setRefundable(e.target.value)}}/>

                                </div>
                                <hr className='themehr' />
                                <div className='d-flex jc-between ' >
                                    <p className='mb-0 f-14'>

                                        Refundable <span>.</span> $
                                        {props.rentAmount} total <br/>

                                    
                                  <>
                                 { props.legal  && <>{
                                     props.legal == "moderate" ? <span className='f-12' style={{width:"90%"}}>Free cancellation before {Cancellationdate.date} {Cancellationdate.month}, Cancel 5 days before checkin {startDates.checkindate} {startDates.checkinmonth}  that way you can get refund amount & service fee will be detected</span> :
                                     props.legal == "strict" ? <span className='f-12' style={{width:"90%"}}>Free cancellation before {Cancellationdate.date} {Cancellationdate.month}, Cancel 1week before checkin {startDates.checkindate} {startDates.checkinmonth}  that way you can get partial refund amount  & service fee will be detected</span>:
                                     <span className='f-12' style={{width:"90%"}}>Free cancellation before {Cancellationdate.date} {Cancellationdate.month}, Cancel 1day  before checkin {startDates.checkindate} {startDates.checkinmonth}  that way you can get refund   & service fee amount</span> } 
                                    </>}
                                        </>    
                                    
                                    </p>

                                    <Form.Check type="radio" name="1" value="true" onChange={(e)=>{setRefundable(e.target.value)}}/>

                                </div>
                            </div>

                          
                        </div> */}
        {console.log(
          "Insafdasdfasdf ",
          props.roomDetails,
          props.roomDetails.instantBooking,
          props?.roomDetails?.instantBooking,
          props.roomDetails.email,
          getUserEmail(),
          getUserEmail() && props.roomDetails.email != getUserEmail()
        )}
        {getUserEmail() && props.roomDetails.email != getUserEmail() ? (
          <>
            {props.roomDetails.instantBooking && Number(diffdays) < 31 && (
              <div className="text-center my-4">
                <button
                  className="btn btn-theme m-auto"
                  onClick={() => {
                    checkUserLogin("reservebooking");
                  }}
                >
                  {/* {props.roomDetails.instantBooking ? "Instant Booking" : "Reserve"}/ */}

                  {/* Instant Booking  */}
                  {props.roomDetails.instantBooking ? "Reserve" : ""}
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="text-center my-4">
            {console.log(
              "getUserEmailgetUserEmailgetUserEmail",
              getUserEmail(),
              getUserEmail() == undefined || getUserEmail() == ""
            )}
            {(getUserEmail() == undefined || getUserEmail() == "") && (
              <button
                className="btn btn-theme m-auto"
                onClick={() => {
                  setShowLog(true);
                }}
              >
                {"Signup / Login"}
              </button>
            )}
          </div>
        )}
        {console.log(
          "checkasdfasdfemail",
          getUserEmail() && props.roomDetails.email
        )}
        {Number(diffdays) >= 31 &&
          getUserEmail() &&
          props.roomDetails.email != getUserEmail() && (
            <>
              {props.roomDetails.instantBooking && (
                <div className="text-center my-4">
                  <button
                    className="btn btn-theme m-auto"
                    onClick={() => {
                      checkUserLogin("splitreservebooking");
                    }}
                  >
                    {props.roomDetails.instantBooking ? "Split Reserve" : ""}
                  </button>
                </div>
              )}
            </>
          )}
        {console.log("get guest user subscription", props)}
        <p className="f-12 text-center my-3">You won't be changed yet</p>
        {/* <div className="">
          <p className="d-flex jc-between f-14 mb-2">
           
            <span>{props.days} Nights</span>
       
            <span>
              {console.log(
                "diffbetweendate",
                Math.abs(new Date(props?.startDate) - new Date(props?.endDate))
              )}
              ${props.rentAmount}
              {props?.startDate && props?.endDate && (
                <button
                  className="fa fa-info-circle ms-2 btn_info_tdan"
                  onClick={() => setInfoNightModal(true)}
                />
              )}
            </span>
          </p>
          <p className="d-flex jc-between f-14">
            <span>Service fee</span>
            <span>
              ${props.serviceFee}
              <button
                className="fa fa-info-circle ms-2 btn_info_tdan"
                onClick={() => setInfoFeeModal(true)}
              />
            </span>
          </p>
        </div> */}
        {/* <hr />
        <p className="d-flex jc-between f-14 pb-4 fw-500">
          <span>Total fee</span>
          {console.log("rentAmount_rentAmount", props?.rentAmount)}
          <span>${props?.rentAmount + props?.serviceFee}</span>
        </p> */}
        <div className="price-breakdown mt-4">
          <h6 className="mb-2">Price Breakdown</h6>
          {console.log("hostuserdata_hostuserdata", hostuserdata)}
          <p className="d-flex align-items-center mb-1">
            Total cost for {props.days} days:
            {userData?.guestsubscribedaycount > 0 ? (
              <span className="amount d-flex align-items-center mx-1">
                $
                {Math.ceil(
                  Number(props?.normalrentamount) -
                    (Number(props?.normalrentamount) *
                      Number(normalpercentage)) /
                      100
                )}
              </span>
            ) : (
              <span className="amount d-flex align-items-center mx-1">
                ${props.rentAmount}
              </span>
            )}
            {props?.startDate && props?.endDate && (
              <FaInfoCircle
                size={12}
                onClick={() => {
                  setInfoNightModal(true);
                }}
              />
            )}
          </p>
          {console.log('serviceFee', props?.serviceFee)}
          <p className="d-flex align-items-center">
            Service fee:
            <span className="amount d-flex align-items-center mx-1">
              ${props?.serviceFee}
            </span>
            <FaInfoCircle size={12} onClick={() => setInfoFeeModal(true)} />
          </p>
        </div>

        {/* Request To Book  */}
        <div className="request-to-book-btn mt-4">
          {book && getUserEmail() &&
          props.roomDetails.email != getUserEmail() &&
          Number(diffdays) >= 31 &&
          !props?.roomDetails?.instantBooking ? (
            <>
              {" "}
              <button
                onClick={() => {
                  checkUserLogin("splitreservebooking");
                }}
              >
                Request to Split Book
              </button>{" "}
            </>
          ) : (
            <>
              {
              
              book && getUserEmail() &&
                props.roomDetails.email != getUserEmail() &&
                Number(diffdays) < 31 &&
                // !props?.roomDetails?.instantBooking && (
                  <button
                    onClick={() => {
                      checkUserLogin("reservebooking");
                    }}
                  >
                    Request to Book
                  </button>
                // )
                }
            </>
          )}
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default AvailCont;

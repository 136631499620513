import React, { Component, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";

import room1 from "../../../Assets/images/hostroom/room1.png";

import { API_URL } from "../../../config/env";

const PlaceType = (props) => {
  console.log("placetypess ----------", props);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  console.log("selectedValue", selectedValue);

  const setData = (val, index) => {
    console.log("val,index", val, index);
    setSelectedIndex(index);
    setSelectedValue(val);
    console.log("localStorage.setItem(placeTypeIndex,selectedIndex)", index);
    localStorage.setItem("placeTypeIndex", index);
    props.onGetPlaceType(val);
  };

  useEffect(() => {
    console.log(
      "localStorage.getItem(placeTypeIndex)",
      localStorage.getItem("placeTypeIndex"),
      'localStorage.getItem("propertyTypeGroup")',
      localStorage.getItem("propertyTypeGroup")
    );
    setSelectedIndex(
      localStorage.getItem("placeTypeIndex")
        ? localStorage.getItem("placeTypeIndex")
        : selectedIndex
    );
    localStorage.getItem("propertyTypeGroup")
      ? props.onGetPlaceType(
          JSON.parse(localStorage.getItem("propertyTypeGroup"))
        )
      : localStorage.removeItem("placeTypeIndex");
  }, []);

  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="header-txt">
        <h2 className="fw-800">Describe your place</h2>
        <p className="des-txt">
          Which of these best describes where will your guests be staying?
        </p>
      </div>
      <div className="">
        <div className="describeYour-place row mt-4">
          {console.log("props.data_privatedata", props.data)}
          {props.data &&
            props.data.content.map(
              (val, index) =>
                val?.status != "Inactive" && (
                  <>
                    {/* <ul className="col-lg-4 bg-nth-child">
                    <li
                      onClick={() => {
                        setData(val, index);
                      }}
                      className={
                        index == selectedIndex
                          ? "active become-host-card-bottom-style"
                          : "become-host-card-bottom-style"
                      }
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center py-4">
                        <img
                          src={API_URL + props.data.path + "/" + val.image}
                        />

                        <h6 className="mt-3 mb-0 host-text-name">{val.type}</h6>
                      </div>
                    </li>
                  </ul> */}
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4">
                      <div
                        onClick={() => {
                          setData(val, index);
                        }}
                        className={
                          index == selectedIndex
                            ? "active ur-plc-card"
                            : "ur-plc-card"
                        }
                      >
                        <img src={API_URL + props.data.path + "/" + val.image} className="img-fluid room-img" />
                        <div className="desc-sec">
                          <h5 className="name mb-1 fw-700">{val.type}</h5>
                          <p className="decs mb-0 f-15 fw-500">
                           {val?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default PlaceType;

import React, { useState, useEffect } from "react";
import "../../Assets/css/landing.css";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import flutterPadsLogo from "../../Assets/images/landingPage/flutterpadlogo.png";
import Logo from "../../Assets/images/landingPage/logo.png";
import phoneIcon from "../../Assets/images/landingPage/phone.png";
import carousalBg from "../../Assets/images/landingPage/headCarousal.png";
import liteBg from "../../Assets/images/landingPage/liteBg.png";
import location from "../../Assets/images/landingPage/location-pin.png";
import bannerBg from "../../Assets/images/landingPage/header_bg.png";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import {
  getUserEmail,
  getUserDatas,
  userLogout,
  getusersdatahooks,
  switchhostandguesthooks,
  getalllandcmsdata,
} from "../../Hooks/useUserHook";
import Login from "../Common/Modals/LoginModal.js";
import { toast, Toaster } from "react-hot-toast";
import { API_URL, toastOptions } from "../../config/env.js";
import liteBgimage from "../../Assets/images/landingPage/liteBg.png";
import { currency } from "../../config/currencycode.js";
import { useDispatch } from "react-redux";
import { USER_CURRENCY } from "../../constant";
import NewNavbar from "./NewNavbar.js";

function HeadSection(props) {
  const [showlog, setShowLog] = useState(false);
  const [email, setEmail] = useState("");
  const [userDatas, setUserDatas] = useState({});
  const [selectedcurrency, setSelectedcurrency] = useState(
    localStorage.getItem("PCURENCY") ? localStorage.getItem("PCURENCY") : "USD"
  );
  const [key, setKey] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cmsdata, setCmsData] = useState([]);

  useEffect(() => {
    const data = getUserEmail();
    setEmail(data);
    setUserDatas(getUserDatas());
    // setLogout()
  }, [showlog]);

  useEffect(() => {
    async function fetchdata() {
      const data = getUserEmail();
      var userdata = await getusersdatahooks(data);
      // setUserDatas(userdata);
    }
    fetchdata();
  }, []);

  useEffect(() => {
    async function fetchdata() {
      const res = await getalllandcmsdata();
      console.log("res cmsdata", res);
      if (res?.data?.type == "success") {
        setCmsData(res?.data?.data);
      }
    }
    fetchdata();
  }, []);

  const cmsFilter = (data) => {
    try {
      let filterdata = cmsdata?.find(
        (el) => el.Identifier == data && el?.status == "active"
      );
      console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
      return filterdata;
    } catch (e) {
      console.log("cmsFilter_err", e);
    }
  };

  const logout = () => {
    userLogout().then((data) => {
      console.log("dataslogut", data);
      toast.success(data, toastOptions);
      if (data.status) {
        window.location.reload();
      }
    });
  };
  //   const setCurrency = (data) => {
  //     return dispatch({
  //         type: USER_CURRENCY,
  //         data
  //     })
  // }

  const hostDashboard = async () => {
    if (email) {
      window.location.href =
        window.location.origin + `/host-dashboard/${email}`;
    } else {
      setShowLog(true);
    }
  };

  const checkUserLogin = async (type) => {
    console.log("check user login :", email);
    if (email && email.toLowerCase() != "undefined") {
      if (
        // userDatas?.govtproof?.length > 0 &&
        // userDatas?.govtproof[0]?.verification == "accepted"
        userDatas?.govtidverification == "accepted"
      ) {
        if (parseFloat(userDatas?.hostsubscribedaycount) > 0) {
          if (type == "room") {
            window.location.href = window.location.origin + `/host/${email}`;
          } else if (type == "experience") {
            window.location.href =
              window.location.origin + `/host-experience/${email}`;
          }
        } else {
          // toast.error("Need Host Subscription!..");
          toast.error("Purchase the subscription plan and then provide all your room details")
          window.location.href = window.location.origin + `/account`;
        }
      } else {
        let vrfy =
          // userDatas?.govtproof?.length > 0
          //   ? userDatas?.govtproof[0]?.verification
          //   : "";
          userDatas?.govtidverification == "rejected";
           // userDatas?.govtproof?.length == 0 || vrfy == "rejected"
        if ( userDatas?.govtidverification == "rejected") {
          // toast.error("Admin reject your government id & so please reupload your id");
          toast.error("your personal details rejected by admin, please reupload the details");
          window.location.href = window.location.origin + `/account/personal-info`;
        }
        if (userDatas?.govtidverification == "pending") {
          // toast.error("your government id verification is pending!");
          // toast.error("need your government id")
          toast.error("Still your personal details not provided, Please fill those details");
          window.location.href = window.location.origin + `/account/personal-info`;
        }
      }
    } else {
      setShowLog(true);
    }
  };
  console.log(
    "Images",
    `${API_URL}adminImages/cmsimages/${cmsFilter("land_banner01")?.Image}`
  );
  return (
    <>
      {showlog && <Login logs={key} onDismiss={() => setShowLog(false)} />}

      {/* <div className="head-section"  style={cmsFilter("land_banner01")?.Image 
      ? { backgroundImage: `url${API_URL}adminImages/cmsimages/${cmsFilter("land_banner01")?.Image}` }
       : { backgroundImage: `url${liteBg}`}}  > */}
      {/* <div className="head-section" style={{ backgroundImage: `url(${bannerBg})` }} > */}
      {cmsdata && cmsdata?.length > 0 && cmsFilter("land_banner01")?.Image && (
        <div
          className="head-section"
          style={{
            backgroundImage: `url(${API_URL}adminImages/cmsimages/${
              cmsFilter("land_banner01")?.Image
            })`,
          }}
        >
          {/* <NewNavbar /> */}
          <div className="container container_custom head-sec">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-sm-4 mb-4 mb-md-4 mb-lg-0 mb-lg-0 first-head-sec">
                <div className="lite-bg-image">
                  <div className="pt-5 left-side-content-section">
                    {/* <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <img
                        src={phoneIcon}
                        alt="phone icon"
                        className="home_phn_icon"
                      />
                      <span className="contact-no ps-2">323-475-7424</span>
                    </div>
                  </div>
                  <div className="flutterpads-logo">
                    <img src={flutterPadsLogo} alt="flutterpads logo" />
                  </div> */}
                    <div className="content-section">
                      <p className="mb-0">Live <span className="org_txt">where</span> you want, <span className="org_txt">when</span> you want.</p>
                      
                      {/* {cmsdata && cmsdata?.length > 0 && (
                        <p className="mb-0">
                          {cmsFilter("land_banner01")?.Title}
                        </p>
                      )} */}
                      <p className="mb-3">No baggage.</p>
                      {/* <span className="sub_head">Membership-based Extended-Stay rentals</span> */}
                    </div>
                    <div className="view-all-properties">
                      {!userDatas.email && (
                        <Button
                          variant="primary"
                          onClick={() => {
                            setKey("");
                            setShowLog(true);
                          }}
                        >
                          {cmsFilter("land_banner01")?.Content}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 px-0">
              <div className="carousal-section position-relative">
                <div className="d-flex justify-content-center">
                  <div className="carousal-signin-sec">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Why FlutterPads
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dd_menu_shadow">
                        <Dropdown.Item href="#/action-1">About</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Terms & Conditions
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {userDatas?.isHost && (
                      <Dropdown className="user_dd ps-0">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          List your property
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dd_menu_shadow">
                          <Dropdown.Item
                            onClick={() => {
                              checkUserLogin("room");
                            }}
                          >
                            Host a Room
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              checkUserLogin("experience");
                            }}
                          >
                            Host an Experience
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <Dropdown className="">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {userDatas && userDatas.email
                          ? "Logout"
                          : "Login/Signup"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dd_menu_shadow">
                        {userDatas && userDatas.email && (
                          <Dropdown.Item
                            href="#"
                            onClick={() => hostDashboard()}
                          >
                            Dashboard
                          </Dropdown.Item>
                        )}
                        {userDatas && userDatas.email && (
                          <Link to="/account" className="dropdown-item">
                            Account
                          </Link>
                        )}
                        {userDatas && userDatas.email && (
                          <Link to="/wishlist" className="dropdown-item">
                            Saved Listings
                          </Link>
                        )}
                        {userDatas && userDatas.email && (
                          <Dropdown.Item
                            onClick={async () => {
                              let payload = {
                                id: userDatas?._id,
                                host: !userDatas?.isHost,
                              };
                              let res = await switchhostandguesthooks(payload);
                              let up = await getusersdatahooks(getUserEmail());
                              setTimeout(window.location.reload(), 500);
                            }}
                          >
                            Switch To{" "}
                            {userDatas?.isHost == true
                              ? "Travelling"
                              : "Hosting"}
                          </Dropdown.Item>
                        )}
                        {!userDatas.email ? (
                          <>
                            <Dropdown.Item
                              onClick={() => {
                                setKey("");
                                setShowLog(true);
                              }}
                            >
                              Signup
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setKey("login");
                                setShowLog(true);
                              }}
                            >
                              Login
                            </Dropdown.Item>
                          </>
                        ) : (
                          <Dropdown.Item
                            onClick={() => {
                              logout();
                            }}
                          >
                            Logout
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <Carousel>
                  <Carousel.Item className="carousal-bg">
                    <Carousel.Caption>
                      <div className="caption-location-sec d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center">
                          <img src={location} alt="location" />
                          <span className="location-name px-2">Los Angles</span>
                          <span className="px-2">3</span>
                          <span className="pe-2 fw-500">bedroom stay</span>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item className="carousal-bg">
                    <Carousel.Caption>
                      <div className="caption-location-sec d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center">
                          <img src={location} alt="location" />
                          <span className="location-name px-2">Los Angles</span>
                          <span className="px-2">3</span>
                          <span className="pe-2 fw-500">bedroom stay</span>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item className="carousal-bg">
                    <Carousel.Caption>
                      <div className="caption-location-sec d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center">
                          <img src={location} alt="location" />
                          <span className="location-name px-2">Los Angles</span>
                          <span className="px-2">3</span>
                          <span className="pe-2 fw-500">bedroom stay</span>
                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HeadSection;

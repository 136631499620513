import React from "react";
import HostHeader from "../BecomeHost/AfterHost/HostHeader";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { getallcmsdatahook } from "../../Hooks/useUserHook";
// Import images

import aboutBanner from "../../Assets/images/About/1.png";
import dreamImg from "../../Assets/images/About/2.jpg";
import workplaceImg from "../../Assets/images/About/3.jpg";
import { Link } from "react-router-dom";
import { API_URL } from "../../config/env";
import Header from "./Header";
import NewNavbar from "../Landing/NewNavbar";
import HeadSection from "../Landing/HeadSection";
import AboutHeadSection from "../Landing/AboutHeadSection";
import Logo from "../../Assets/images/landingPage/logo1.png";

import profile1 from "../../Assets/images/aboutUs/profile1.png";
import profile2 from "../../Assets/images/aboutUs/profile2.png";
import profile3 from "../../Assets/images/aboutUs/profile3.png";
import profile4 from "../../Assets/images/aboutUs/profile4.png";
import profile5 from "../../Assets/images/aboutUs/profile5.png";
import profile6 from "../../Assets/images/aboutUs/profile6.png";
import { getaboutcmshooks } from "../../Hooks/filterHook";

const Aboutus = () => {
  const [cms, setCms] = useState([]);
  const [content, setContent] = useState({});
  const [cmsdata, setCmsdata] = useState([]);
  const [AboutUs, setAboutus] = useState([]);
  const [Header, setHeader] = useState({});
  // useEffect(() => {
  //   async function fetchdata() {
  //     console.log("");
  //     await getallcmsdatahook().then((result) => {
  //       console.log("get all crm hook result", result?.data?.data);
  //       result?.data?.data?.map((data, i) => (data.sno = i + 1));
  //       setCms(result?.data?.data);
  //     });
  //   }
  //   fetchdata();
  // }, []);
  // const cmsFilter = (data) => {
  //   try {
  //     let filterdata = cms?.find(
  //       (el) => el.identifier == data && el?.status == "active"
  //     );
  //     console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
  //     return filterdata;
  //   } catch (e) {
  //     console.log("cmsFilter_err", e);
  //   }
  // };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });


    async function fetchdata() {
      console.log("");
      await getallcmsdatahook().then((result) => {
        console.log("get all crm hook result", result?.data?.data);
        result?.data?.data?.map((data, i) => (data.sno = i + 1));
        setCms(result?.data?.data);
        let filterdata = result?.data?.data?.find(
          (el) => el.name == "aboutus" && el?.status == "Active"
        );
        console.log(
          "filterdata_filterdata",
          filterdata,
          filterdata?.content,
          filterdata?.updatedAt
        );
        setContent(filterdata?.content);
      });
      let result = await getaboutcmshooks();
      console.log("result", result);
      setAboutus(result?.data?.data?.record);
      setHeader(result?.data?.data?.header);
    }

    fetchdata();
  }, []);

  const cmsFilter = (data) => {
    try {
      let filterdata = cms?.find(
        (el) => el.identifier == "termsofservice" && el?.status == "Active"
      );
      console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
      return filterdata;
    } catch (e) {
      console.log("cmsFilter_err", e);
    }
  };
  console.log("abourus", AboutUs);
  // const AboutUs = [
  //   {
  //     profile: profile1,
  //     name: "Daniel Ameer",
  //     designation: "Chief Executive Officer",
  //     description:
  //       "A former banker with extensive knowledge and passion for real-estate development and finance with a history of working on underwriting decisions for residential real estate loans throughout the country. In 2015, he founded a real estate development company, successfully constructing assets worth millions in the subsequent years. In 2022, a critical realization led to the conception of FlutterPads, and in less than two years, he validated the concept in Los Angeles. Daniel now channels his singular focus on unleashing the business on a planned course of mass expansion.",
  //     mobile: "(323) 475-7424",
  //     email: "Daniel.Ameer@FlutterPads.com",
  //   },
  //   {
  //     profile: profile2,
  //     name: "Alexander Veselov",
  //     designation: "Enforcer of Unforgettable Experiences",
  //     description:
  //       "Alexander boasts a track record of prioritizing customer-first service rooted in hospitality. He spent 2 years as a Company Performance Manager overseeing staff performance quality, cohesiveness, and contentment. He then spent 8 years as the Assistant Performance Manager for Princess Cruises, where he hosted thousands of international guests while also managing logistics and the training of on-board performances and performers. Today, he oversees the total guest experience at FlutterPads, ensuring that every guest has an exceptional experience when staying at FlutterPads properties, while maintaining the team spirit of our performance staff.",
  //     mobile: "(323) 477-3041",
  //     email: "Alex.Veselov@FlutterPads.com",
  //   },
  //   {
  //     profile: profile3,
  //     name: "Andrew Mezger",
  //     designation: "Spymaster",
  //     description:
  //       "Andrew, a thriving real estate and mortgage broker in Southern California, augments his expertise with insights gained from years dedicated to a distinguished career in industrial Supply Chain & Logistics management. With Flutterpads Inc., Andrew assumes a pivotal advisory role, spearheading real estate transactions and offering invaluable guidance on regulatory compliance, ensuring the company remains aligned with state laws and regulations.",
  //     mobile: "(323) 573-6033",
  //     email: "Andrew.Mezger@adcapcorp.com",
  //   },
  //   {
  //     profile: profile4,
  //     name: "Juan Anguiano",
  //     designation: "Captain of the Catadel",
  //     description:
  //       "With over two decades of construction expertise Juan's technical knowledge, project management prowess, and the breadth of his knowledge base have fortified him as a force in the residential construction network in Los Angeles. Today Juan oversees every aspect of FlutterPad’s properties from Construction/Remodeling, Maintenance Staff Planning, Budgeting, and City Inspections.",
  //     mobile: "(323) 573-6033",
  //     email: "info@flutterpads.com",
  //   },
  //   {
  //     profile: profile5,
  //     name: "Braxton Huff",
  //     designation: "Wizard of the Castle",
  //     description:
  //       "A self-taught prodigy since high school, Braxton has a history of corporate triumphs. This includes the redesign of the Major League Soccer’s app that boasts over 2 million users as well as working to overhaul of an app for CVS Health. Braxton leverages his expertise in interface fluidity and data-driven design at FlutterPads Inc., guaranteeing a seamless and user-centric experience for our clients.",
  //     mobile: "(323) 573-6033",
  //     email: "info@flutterpads.com",
  //   },
  //   {
  //     profile: profile6,
  //     name: "Simon Park",
  //     designation: "Oracle of Architecture",
  //     description:
  //       "Renowned in South Korea for his modern and breathtaking architectural designs, Simon Park moved to California nearly 20 years ago where he established an office in Santa Monica. Simon’s awards and accolades as an architect led him to be selected by the Korean government to design and orchestrate the construction of the Korean Consulate Building in Los Angeles. Los Angeles Unified School District also awarded multiple new school projects to Simon’s firm based on his visionary concepts. Simon is noted by his clients and peers for his ability to incorporate social, ecological sustainability, and economic principles into his designs. Simon acts as the primary Adviser for construction or remodeling projects of Flutterpads Inc.",
  //     mobile: "(323) 573-6033",
  //     email: "info@flutterpads.com",
  //   },
  // ];
  return (
    <>
      {/* <HostHeader /> */}

      {/* Privacy Policy */}

      {/* <section className="cms_cmn_style about_us"> */}
      <section className="about_us">
        {/* <div
          className="container"
          dangerouslySetInnerHTML={{ __html: content }}
        /> */}
        {/* <div className="container"> */}
        <NewNavbar />
        {/* <HeadSection /> */}
        <AboutHeadSection data={Header} />
        <div className="content_sec container container_custom">
          <div className="filter">
            <div className="where_to_sec">
              <div>
                <h3 className="mb-2 org_txt">Our Team</h3>
                <p>
                  Meet the team creating dynamic value for exceptional living.
                </p>
              </div>
              <div className="search_detail_logo">
                <img src={Logo} alt="logo" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="management_details py-3 py-lg-4">
            {AboutUs && AboutUs?.length > 0 && AboutUs?.map((item, index) => {
              return (
                <div className="row row custom-row">
                  <div className="mb-3 custom-col1">
                    <div className="profile-logo">
                      <img
                        src={API_URL + item?.path + item?.Image}
                        className="img-fluid"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="mb-3 custom-col2">
                    <div className="profile-details">
                      <div>
                        <h3 className="mb-2 name">{item?.Content1}</h3>
                        <h6 className="mb-0 designation">{item?.Heading}</h6>

                        <p className="mb-0 description py-3 py-lg-5">
                          {item.Content}
                        </p>
                      </div>

                      <div>
                        <p className="mb-2 mobile-no">
                          Office:{" "}
                          <span className="org-txt">{item?.Content2}</span>
                        </p>
                        <p className="mb-0 email">
                          Email:{" "}
                          <span className="org-txt">{item?.Content3}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* </div> */}
      </section>
      <Footer />
      {/* col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12  */}
      {/* col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 */}
    </>
  );
};

export default Aboutus;

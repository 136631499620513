import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FloatingLabel, Form } from "react-bootstrap";

const EditCreateDescriptionContent = (props) => {
  const [charcount, setCharCount] = useState(0);
  const [title, setTitle] = useState("");
  const [rulecount , setRulecount] = useState(0);
  const [rules , setRules] = useState("");
  const handleChange = (val) => {
    console.log('logsz data', val != "", val, val?.length <= 0)
    if (val?.length >= 0) {
      setCharCount(val.length);
      setTitle(val);
      props.onGetDescriptionContent(val);
    } else {
      setTitle("");
      setCharCount(val.length);
      props.onGetDescriptionContent(val);
    }
  };

  const handleChangerules = (val) => {

    console.log('vvvvvvvvvv', val != "", val, val?.length)
    if (val?.length >= 0) {
      setRulecount(val.length);
      setRules(val);
      props.onGetRules(val);
    } else {
      setRules("");
      setRulecount(val.length);
      props.onGetRules(val);
    }
    // if(val?.length) {
    //     setRulecount(val?.length)
    // }
    // setRules(val)
    // props.onGetRules(val);
  };



  useEffect(() => {
    if (props?.roomdata) {
        let title = localStorage.getItem("EditdescriptionDetails") != null ? localStorage.getItem("EditdescriptionDetails") : props?.roomdata?.descriptionDetails ? props?.roomdata?.descriptionDetails : "";
        let titlelen = localStorage.getItem("EditdescriptionDetails") != null ? localStorage.getItem("EditdescriptionDetails")?.length : props?.roomdata?.descriptionDetails ? props?.roomdata?.descriptionDetails?.length : "";


        let rules = localStorage.getItem("Editrules") != null ? localStorage.getItem("Editrules") : props?.roomdata?.rules ? props?.roomdata?.rules : "";
        let ruleslen = localStorage.getItem("Editrules") != null ? localStorage.getItem("Editrules")?.length : props?.roomdata?.rules ? props?.roomdata?.rules?.length : "";

        props?.onGetDescriptionContent(localStorage.getItem("EditdescriptionDetails") != null ? localStorage.getItem("EditdescriptionDetails") : props?.roomdata?.descriptionDetails ? props?.roomdata?.descriptionDetails : "");
        props?.onGetRules(localStorage.getItem("Editrules") != null ? localStorage.getItem("Editrules") : props?.roomdata?.rules ? props?.roomdata?.rules : "")
        setTitle(title);
        setCharCount(titlelen);

        setRules(rules)
        setRulecount(ruleslen)
    }
}, [localStorage.getItem("title"), props]);

  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="rightcont placetype mb-5 placetype_heoght_fix">
        <Scrollbars style={{ height: "calc(100vh - 320px)" }} className="scroll">
          <div
            className="row  px-3 align-items-center pb-5"
            data-aos="fade-up"
          >
            <div>
              <h3 className="mb-4">Create your Description</h3>

              <Form className="p-0">
                <FloatingLabel controlId="floatingTextarea2">
                  <Form.Control
                    maxlength="2000"
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    value={title}
                    onChange={(e) => {
                      handleChange(e.target.value);props?.Filter("rules")
                    }}
                  />
                </FloatingLabel>
                <div className="char mt-2">
                  {" "}
                  <span>{charcount && charcount}</span>/2000
                </div>
                <h3 className="mb-4 mt-4">Create your Rules</h3>
                <FloatingLabel controlId="floatingTextarea2">
                  <Form.Control
                    maxlength="2000"
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                    value={rules}
                    onChange={(e) => {
                      handleChangerules(e.target.value); props?.Filter("rules")
                    }}
                  />
                </FloatingLabel>
                <div className="char mt-2">
                  {" "}
                  <span>{rulecount && rulecount}</span>/2000
                </div>
              </Form>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default EditCreateDescriptionContent;

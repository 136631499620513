import React, { Component, useState, useRef, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Form, Tooltip, Overlay } from "react-bootstrap";

import { propTypes } from "react-bootstrap/esm/Image";
import calendar from "../../../Assets/images/CreateRoom/calendar.png";

const SetPrice = (props) => {
  const [show, setShow] = useState(false);
  const [showinfo, setShowInfo] = useState(false);
  const target = useRef(null);
  const targetinfo = useRef(null);

  const [price, setPrice] = useState("1");

  const [startDate, setStartDate] = useState(new Date()?.setHours(12, 0, 0));
  const [enddate, setEnddate] = useState(new Date()?.setHours(11, 0, 0));
  // const [startdateref , setStartdateref] = useState("")
  let regex = /^[0-9]+$/;
  const setData = (val, type) => {
    console.log("val", type, val);
    if (val != undefined && val != "undefined") {
      var amount = 0;
      if (type == "add") {
        console.log("aaaaaaaaaaa", amount);
        amount = parseFloat(price || 1) + 100;
        console.log("amount in add", amount, price || 1 + 100, price || 1);
      } else if (type == "sub") {
        console.log(
          "priceeeeeeeee",
          price,
          isNaN(price),
          !isNaN(price),
          price !== NaN
        );
        if (!isNaN(price)) {
          amount = parseFloat(price || 1) - 100;
          console.log("amount in sub", amount);
        }
      } else {
        if (val != "" && val <= 20000 && !isNaN(val)) {
          amount = parseFloat(val);
          console.log("amount in o", amount);
        }
      }
      setPrice(amount);
      let payload = {
        price: amount,
        checkin: new Date(startDate).getTime(),
        checkout: new Date(enddate).getTime(),
      };
      console.log("setdata payload data", payload);
      // props.onGetPrice(payload);
    }
  };

  const checkin = (val) => {
    console.log("checkin", val);
    let payload = {
      price: price,
      checkin: new Date(val).getTime(),
      checkout: new Date(enddate).getTime(),
    };
    console.log("check in payload data", payload);
    // props.onGetPrice(payload);
  };

  const checkout = (val) => {
    console.log("checkout", val);
    let payload = {
      price: price,
      checkin: new Date(startDate).getTime(),
      checkout: new Date(val).getTime(),
    };
    console.log("check out payload data", payload);
    props.onGetPrice(payload);
  };

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  useEffect(() => {
    console.log(
      'localStorage.getItem("price")',
      typeof localStorage.getItem("price"), localStorage.getItem("price")
    );
    setPrice(
      localStorage.getItem("price")
        ? JSON.parse(localStorage.getItem("price"))
        : price
    );
    localStorage.getItem("price")
      ? props.onGetPrice(parseInt(localStorage.getItem("price")))
      : localStorage.removeItem("price");
  }, []);


  const onchange = (e) => {
    let payload = {
      price: price,
      checkin: new Date(startDate).getTime(),
      checkout: new Date(enddate).getTime(),
    };
    setPrice(e)
    console.log("check out payload data", payload);
    props.onGetPrice(e);
    localStorage.setItem("price",e)
  };
  
  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 setYourPrice">
        <div className="header-txt">
          <h2 className="fw-800">Set your price</h2>
          <p className="des-txt">
            This will be the price of your listing per night, you can adjust
            this at any time
          </p>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-8 order-2 order-md-1">
            <div className="">
              <h5 className="fw-700 mb-2 pb-1">Set a nightly price</h5>
              <p className="mb-1 fw-600 orange-txt">Nightly price</p>
              <Form.Control
                type="text"
                className="priceInp"
                value={price}
                placeholder=""
                onChange={(e) => {
                  if (
                    (regex.test(e?.target?.value) ||
                    e?.target?.value == "") && (e?.target?.value <= 2000)
                  ) {
                    console.log("eeeeeeeeeeeeee", e?.target?.value);
                    onchange(e.target.value);
                  }
                }}
              />
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-4 order-1 order-md-2 d-flex align-items-center justify-content-center mb-4 mb-md-0">
            <img src={calendar} className="img-fluid" />
          </div>
        </div>
      </div>

      {/* <div className="col-md-12">
        <div className="placetype mb-5 placetype_heoght_fix">
          <Scrollbars
            style={{ height: "calc(100vh - 320px)" }}
            className="scroll"
          >
            <div
              className="row  px-3 text-center mt-3 align-items-center pb-5"
              data-aos="fade-up"
            >
              {console.log("startdate , enddate", startDate, enddate)}
              <div>
                <div className="d-flex countbtns">
                  <button
                    className="countbtn"
                    disabled={price < 100}
                    onClick={() => {
                      setData(0, "sub");
                    }}
                  >
                    -
                  </button>
                  {console.log("price_dataaaaaaa", price)}
                  <input
                    type="text"
                    value={price}
                    placeholder="00"
                    onChange={(e) => {
                      console.log(
                        "checcccccccccc",
                        price,
                        e?.target?.value,
                        e?.target?.value == "",
                        isNaN(e?.target?.value),
                        regex.test(e?.target?.value)
                      );
                      if (
                        regex.test(e?.target?.value) ||
                        e?.target?.value == ""
                      ) {
                        console.log("eeeeeeeeeeeeee", e?.target?.value);
                        setData(e.target.value);
                      }
                    }}
                  />
                  <button
                    className="countbtn"
                    onClick={() => {
                      setData(0, "add");
                    }}
                  >
                    +
                  </button>
                </div>

                <div>
                  <div className="flex_time_div">
                    <div className="mx-1">
                      <p className="pb-3">Check-in Time</p>

                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="h:mm aa"
                        timeFormat="h:mm aa"
                        selected={new Date(startDate)}
                        // showIcon
                        onChange={(date) => {
                          // console.log("start time" , new Date(date)?.getHours());
                          setStartDate(date);
                          checkin(date);
                        }}
                        timeClassName={handleColor}
                        // dateFormat="LLL"
                      />
                    </div>

                    <div className="mx-1">
                      <p className="pb-3">Check-out Time</p>
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="h:mm aa"
                        timeFormat="h:mm aa"
                        selected={new Date(enddate)}
                        // showIcon
                        onChange={(date) => {
                          setEnddate(date);
                          checkout(date);
                        }}
                        timeClassName={handleColor}
                        // dateFormat="LLL"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        <Overlay target={target.current} show={show} placement="right">
          <Tooltip id="overlay-example">
            When you offer this promotion, your first 3 guests get 20% off your
            nightly price. It expires after your first 3 reservations are made
            or 30 days after you publish your listing, whichever happens first.
            <a href="#" className="text-dark">
              Get details
            </a>
          </Tooltip>
        </Overlay>
        <Overlay target={targetinfo.current} show={showinfo} placement="left">
          <Tooltip id="overlay-example1">
            Suggested prices are based on your listing’s location and amenities,
            as well as nearby prices and demand. You choose your price, and you
            can adjust it at any time.
          </Tooltip>
        </Overlay>
      </div> */}
    </>
  );
};

export default SetPrice;

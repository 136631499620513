import React, { useState, useCallback, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";

import CameraModal from '../../Common/Modals/CameraModal'
import { API_URL } from "../../../config/env";
const EditHighlights = (props) => {


  const [charcount, setCharCount] = useState(0);
  const [highlightsList, setHighlightsList] = useState(localStorage.getItem("EditHighlights") ? 
  JSON.parse(localStorage.getItem("EditHighlights")) : []);
  console.log('initial highlightListdata', highlightsList)
  const [selectedIndex, setSelectedIndex] = useState(localStorage.getItem("EditHighlightsIndex") 
  ? JSON.parse(localStorage.getItem("EditHighlightsIndex")) : []);
  const [showcammodal, setShowCamModal] = useState(false);

  console.log('edithighlightsdata', props)

  const setData = useCallback(
    async (val, index) => {
     var highlightsListdata = typeof highlightsList == 'object' ? highlightsList: JSON.parse(highlightsList);
      var selectedIndexdata = typeof selectedIndex == 'object' ? selectedIndex : JSON.parse(selectedIndex)
      let filter = highlightsList?.filter ((el) => {return(el.type == val?.type ? true : false)})
      console.log('setData_highlightsList', highlightsList, val,filter, Object.values(filter)?.length, !highlightsList.includes(val), highlightsList.includes(val));
     
      if (Object.values(filter)?.length == 0) {
        setHighlightsList([...highlightsListdata, val]);
        setSelectedIndex([...selectedIndexdata, index]);
        localStorage.setItem(
          "EditHighlightsIndex",
          JSON.stringify([...selectedIndexdata, index])
        );
        props.onGetHighlights([...highlightsListdata, val]);
      } else {
        const filteredArray = highlightsListdata.filter((item) => item?.type !== val?.type);
        const filteredIndex = selectedIndexdata.filter((item) => item !== index);

        console.log('filteredArray', filteredArray, 'filteredIndex', filteredIndex)
        setHighlightsList(filteredArray);
        setSelectedIndex(filteredIndex);
        localStorage.setItem("EditHighlightsIndex", JSON.stringify(filteredIndex));
        props.onGetHighlights(filteredArray);
      }

    },
    [selectedIndex, setHighlightsList]
  );


  useEffect(() => {
    if (props?.roomdata) {
      console.log('description', props?.roomdata?.description)
      let IndexArr = [];
      props?.roomdata?.description && props?.roomdata?.description?.length > 0 && props?.roomdata?.description?.map((val, ind) => {
        IndexArr.push(Number(val?.id) - 1);
      });
      let setIndex = localStorage.getItem("EditHighlightsIndex") != null ? localStorage.getItem("EditHighlightsIndex") :
        IndexArr?.length > 0 ? IndexArr : [];
      setSelectedIndex(setIndex);

      let setHighLightArr = localStorage.getItem("EditHighlights") != null ? JSON.parse(localStorage.getItem("EditHighlights")) :
        props?.roomdata?.description ? props?.roomdata?.description : []
      localStorage.getItem("EditHighlights") != null ? props?.onGetHighlights(JSON.parse(localStorage.getItem("EditHighlights"))) :
        props?.roomdata?.description ? props?.onGetHighlights(props?.roomdata?.description) : localStorage.removeItem("EditHighlightsIndex");

      setHighlightsList(setHighLightArr)

      console.log('IndexArr_IndexArr', IndexArr)
    }

  }, [props, localStorage.getItem("EditHighlights")])

 
  return (
    <div className="col-lg-12 col-xl-12 col-md-12 hignlights">

      <div className="placetype placetype_heoght_fix mb-5">
        {showcammodal && <CameraModal onDismiss={() => setShowCamModal(false)} />}
        <Scrollbars
          style={{ height: "calc(100vh - 320px)" }}
          className="scroll"
        >

          <div
            className="row  px-3  align-items-center pb-5"
            data-aos="fade-up"
          >
            <div>
              <h5 className="mb-5 lastquest">Choose upto 2 highlights
                <button
                  className="fa fa-info-circle ms-2"
                  onClick={() => setShowCamModal(true)}
                />
              </h5>
              <ul className="mt-4">
                {console.log("props.data.path", props.data.path)}
                {props.data &&
                  props.data.content.map((val, index) => (


                    val?.status != "Inactive" && <li
                      className={selectedIndex.includes(index) ? "active" : ""}
                      onClick={() => {
                        setData(val, index); props?.Filter("highlights")
                      }}
                    >
                      <img src={API_URL + props.data.path + "/" + val.image} />
                      <span>{val.type}</span>
                    </li>


                  ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default EditHighlights;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import cardimg2 from "../../../../Assets/images/calendar1.png";
import { Form } from "react-bootstrap";
import HeaderDashTwo from "../../../Common/HeaderDashTwo";
const CleaningFee = () => {
  const history = useNavigate();

  return (
    <>
      <HeaderDashTwo />
      <div className="container container_custom">
        <div className="house-rules common-dash-guest py-4 py-lg-5">
          <div className="header-sec">
            <h1 className="mb-1 fw-700 header-txt">Add a cleaning fee</h1>
            <p className="mb-0 fw-500 header-desc">
              You can charge this fee to guests to cover the cost of cleaning
              services and supplies.
            </p>
          </div>
          <div className="row">
            <div className="order-2 order-md-1 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="mt-4">
                <h4 className="mb-0 fw-700">Cleaning Fees</h4>
                <ul className="ps-0 setrule mt-3">
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div className="w-100">
                        <p className="mb-0 rule-heading">Cleaning fee cost</p>
                        <input
                          type="text"
                          className="discount-percent"
                          value="$50"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="mt-3 promote-list-btns d-flex align-items-center justify-content-end gap-2 mt-2">
                  <Button className="btn cmn-bg-black">Cancel</Button>{" "}
                  <Button className="btn cmn-bg-orange">Save</Button>{" "}
                </div>
              </div>
            </div>
            <div className="order-1 order-md-2 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center mt-3 mt-lg-0">
              <img src={cardimg2} className="img-fluid guest" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CleaningFee;

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Cookies from 'universal-cookie';
// import { post } from '../../../Backend/Routes/userRoute';

import { API_URL } from '../config/env';
import {
    signUpApi, checkUserApi,
    checkOTPApi, loginApi,
    updateUserDataApi, resendOTPApi,
    reserveRoomApi,
    confirmRoomApi,
    confirmExperienceApi,
    paymentApi,
    logoutApi,
    sendMessageApi,
    checkBookingStatusApi,
    getHostDetailsApi,
    updatenameApi,
    updategenderApi,
    updatedobApi, updateaddressApi,
    updateemergencycontactApi,
    pullemergencycontactApi, updategovtidApi,
    getuserdataApi,
    updateUserProfileApi,
    createRazorPaymentApi,
    verifyRazorPaymentApi, addReviewApi,
    getExperienceBookingDetails, getCompletedExperienceBooking,
    addExperienceReviewApi, updateUserPhoneNumber, getEmail, 
    checkloginUserApi, getPriceApi, CancellationPolicyAPi, getuserdatasApi, 
    getsingleroomprice, getunreadnotificationApi, markasreadoneApi, 
    markasreadallApi, getallnotificationApi, editroompriceApi, 
    editexperiencepriceApi, getstaydateApi, gethostactivesubscriptionApi, 
    getguestactivesubscriptionApi, subscribehostApi, subscribeguestApi, 
    confirmguestsubscription, confirmhostsubscription,
    getcmsApi, getfaqApi, updatebankdetailsApi, getwithdrawhistoryApi, checksignupotpApi, forgotpasswordApi, checkotpforgotpasswordApi, updatepasswordApi, hostinstantbookingstatusApi, switchhostandguestApi, getguestsubscriptionApi, sendotpApi, verifyotpApi, unblockdateApi, editspecilpriceApi, addconcactusApi, getjoinusApi, splitreserveRoomApi, getsubscriptiondetailsApi, getusersubscripdataApi, getlandcmsApi
} from '../config/userRouteFront';

import { login } from '../redux/action';
import { toast } from 'react-hot-toast';


const cookies = new Cookies();
console.log('coookies', cookies, cookies.get('accessToken'))
const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': cookies.get('accessToken') ? `Bearer ${cookies.get('accessToken')}` : (localStorage.getItem("USRDT") ? `Bearer ${JSON.parse(atob( localStorage.getItem("USRDT"))).token}` : "")
        // 'Authorization': `Bearer ${cookies.get('accessToken')}`
    }
}

export const userSignUp = async (data) => {
    let dataToReturn = {}
    var day = 60 * 60 * 24 * 1000;
    await axios.post(API_URL + signUpApi, data).then((data) => {
        console.log("data in user sign up :", data);
        dataToReturn = data.data;
        if(data?.data?.message?.loginType && data?.data?.message?.loginType !="email"){alert("inside")
            cookies.set('email', data.data.message.email, { path: '/' }, { expires: new Date(Date.now() + day) });
            cookies.set('User-Datas', data.data.message, { path: '/' }, { expires: new Date(Date.now() + day) })
            let email = btoa(data.data.message.email);
            localStorage.setItem("USRID" , email);
            let userdata = btoa(JSON.stringify(data.data.message));
            localStorage.setItem("USRDT" , userdata);
        }
        // cookies.set('email', data.data.message.email, { path: '/'},{expires: new Date(Date.now()+600000) });
        // cookies.set('refreshToken', data.data.refreshToken, { path: '/' });
        // cookies.set('accessToken', data.data.accessToken, { path: '/' });
        // cookies.set('User-Datas',data.data.message, { path: '/'},{expires: new Date(Date.now()+600000) })
        // console.log("cookies.get('refreshToken')",cookies.get('refreshToken'),"cookies.get('accessToken')",cookies.get('accessToken')); 
    })
    return dataToReturn;
}


export const Loginusercheck = async (email) => {
    console.log('Loginusercheck', email)
    let dataToReturn;
    await axios.get(`${API_URL}${checkloginUserApi}/${email}`).then((data) => {
        console.log("usercheck hook:>>>>>>>>>>>>>>.....", data, data.data.status);
        let datas = { 'status': data.data.status, userdata: data.data.userdata }
        dataToReturn = datas;
    })
    return dataToReturn;
}


export const userCheck = async (email) => {
    let dataToReturn;
    await axios.get(`${API_URL}${checkUserApi}/${email}`).then((data) => {
        console.log("usercheck hook:", data.data.status);
        let datas = { 'status': data.data.status, 'record': data.data.userdata }
        dataToReturn = datas;
    })
    return dataToReturn;
}

export const checkOtp = async (data) => {
    await axios.post(API_URL + checkOTPApi, data, headers).then((data) => {
        console.log("data in check otp :", data, data.status);
    })
}

export const LoginUser = async (data) => {
    // const dispatch = useDispatch();
    let dataToReturn = {};
    var day = 60 * 60 * 24 * 1000;
    console.log("data in login user hook function :", data);
    await axios.post(API_URL + loginApi, data).then((data) => {
        console.log(data, "GOOGLE DATA")
        console.log("data login user, status", data, "message", data.data.message);
        console.log('email LoginUser eiji', data.data.message);
        // cookies.remove('User-Datas');
        // if(getUserDatas()){
            // cookies.remove('User-Datas');
            // cookies.set('User-Datas', JSON.stringify(data.data.message), { path: '/' }, { expires: new Date(Date.now() + day) })
        // }
        // document.cookie = `User-Datas=${}`
        // document.cookie = 'User-Datas' + "=" + JSON.stringify(data.data.message) + ";" + ";path=/";
        if(data?.data?.message?.status == "Active"){
            let email = btoa(data.data.message.email);
            localStorage.setItem("USRID" , email);
            let userdata = btoa(JSON.stringify(data.data.message));
            localStorage.setItem("USRDT" , userdata);
            cookies.set('User-Datas', data.data.message , { path: '/' }, { expires: new Date(Date.now() + day) })
            cookies.set('email', data.data.message.email, { path: '/' }, { expires: new Date(Date.now() + day) });
        }
        else if(data?.data?.message?.status == "Inactive"){
            toast.error("User deactivate contact support team!");
            // return;
        }
        // document.cookie = 'email' + "=" + data.data.message?.email + ";" + ";path=/";
        dataToReturn.status = data.data.status;
        dataToReturn.message = data.data.message;
        // dispatch({
        //     type:login,
        //     loginData:data.data.message
        // });
    })
    console.log(dataToReturn, "dataToReturn");
    return dataToReturn;
}

export const checksignupotp = async(data) => {
    let dataToReturn = {};
     let res = await axios.post(API_URL + checksignupotpApi, data);

    // .then((res) => {
        console.log("checksignupotp_data.status" , res);
    //     dataToReturn.status = res.data.status;
    //     dataToReturn.message = res.data.message;
    // // })
    return res;
}


export const updateUserData = async (data) => {
    console.log("update user data hook :", data);
    let dataToReturn = {};
    var day = 60 * 60 * 24 * 1000;
    await axios.post(API_URL + updateUserDataApi, data, headers).then((result) => {
        dataToReturn = result.data;
        console.log('enter this email updateUserData eiji')
        // cookies.set('email', result.data.message.email, { path: '/' }, { expires: new Date(Date.now() + day) });
        console.log('result.data', result.data, 'result.data.message', result.data.message)
       
        // let userdata = btoa(JSON.stringify(result.data.message));
        // localStorage.setItem("USRDT" , userdata);
        // cookies.set('User-Datas', result.data.message, { path: '/' }, { expires: new Date(Date.now() + day) })
        // document.cookie = 'User-Datas' + "=" + JSON.stringify(data.data.message) + ";" + ";path=/";
    })
    return dataToReturn;
}

export const resendOTP = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + resendOTPApi, data, headers).then((result) => {
        console.log("resend otp hook :", result);
        dataToReturn = result.data;
    });
    return dataToReturn;
}

export const loginUserFacebook = async (data) => {
    const usercheck = await userCheck(data.email);
    console.log("user check" , usercheck);
    const dataFinal = {
        email: data.email,
        userId: data.userID,
        appId: data.id,
        type: data.type
    };
    let dataToReturn = {};
    if (usercheck?.status) {
        await LoginUser(dataFinal).then((data) => {
            dataToReturn = data;
        })
    } else {
        await userSignUp(dataFinal).then((data) => {
            dataToReturn = data
        })
    }
    return dataToReturn;
}

export const loginUserGoogle = async (data) => {
    console.log('datagoogle', data)
    const usercheck = await userCheck(data.email);
    console.log('usercheck---------------', usercheck)
    let dataToReturn = {};
    if (usercheck?.status) {
        await LoginUser(data).then((data) => {
            dataToReturn = data;
        })
    } else {
        await userSignUp(data).then((data) => {
            dataToReturn = data
        })
    }
    return dataToReturn;
}

export const reserveRoom = async (data, token) => {
    let dataToReturn = {};
    await axios.post(API_URL + reserveRoomApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}


export const getCancellationPolicy = async (data) => {
    let dataToReturn = {};
    console.log('fasb dvfhasdvgasdgh',data, data.startDate, new Date(), new Date(data.startDate))
    let formData = new FormData();
    formData.append('startDate', data.startDate);
    formData.append('endDate', data.endDate);
    formData.append('legal', data.legal[0]);

    await axios.post(API_URL + CancellationPolicyAPi, formData).then((data) => {
        dataToReturn = data.data;
    });
    return dataToReturn;
}

export const getreview = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + addReviewApi, data).then((data) => {
        console.log("review", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const confirmRoom = async (data) => {
    let dataToReturn = {};
    // await axios.post(API_URL+paymentApi,data,headers).then((data)=>{
    await axios.post(API_URL + confirmRoomApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}


export const confirmExperience = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + confirmExperienceApi, data, headers).then((data) => {
        dataToReturn = data;
    })
    return dataToReturn;
}

export const getUserEmail = () => {
    // const email = cookies.get('email');
    const email = localStorage.getItem("USRID") ? atob( localStorage.getItem("USRID")) : "" ;
    return email;
}


export const getUserDatas = () => {
    // let data = cookies.get('User-Datas');
    // console.log("cookies data ii getuserdeata" , document.cookie);
   if(localStorage.getItem("USRDT")){
    const data = JSON.parse(atob( localStorage.getItem("USRDT")));
    console.log("get user datas in cookies:", data);
    return data;
   }
   else{
    return {};
   }
}


export const userLogout = async () => {

    let dataToReturn = {};
    const email = cookies.get("email");
    console.log("email userLogout:", email);
    await axios.get(`${API_URL}${logoutApi}/${email}`).then((data) => {
        console.log("data userLogout:", data);
        if (data?.data) {
            console.log("data userLogout:innnnn", data);
            cookies.remove("email");
            cookies.remove("User-Datas");
            localStorage.clear("USRDT");
            localStorage.clear("USRID");
            window.location.href = window.location.origin;
            dataToReturn = data.data;
        }
    })
    return dataToReturn;
}





export const checkBookingStatus = () => {
    axios.get(API_URL + checkBookingStatusApi)
    return true;
}

export const getHostDetails = async (email) => {
    console.log('userdetails email', email)
    let dataToReturn = {};
    await axios.get(`${API_URL}${getHostDetailsApi}/${email}`).then((data) => {
        dataToReturn = data.data;
    })
    return dataToReturn;
}

export const sendMessage = async (data) => {
    await axios.post(API_URL + sendMessageApi, data).then((data) => {

    })
}

export const getPrice = async (data) => {
    console.log('roomIddata', data)
    let dataToReturn = {};
    await axios.post(API_URL + getPriceApi, data).then((res) => {
        console.log('datasaaaaaa', res.data)
        dataToReturn = res.data
    })
    return dataToReturn;
}
export const updatename = async (data) => {
    console.log("getfilter option data", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data?.email);
            formdata.append("firstname", data?.firstname);
            formdata.append("lastname", data?.lastname);
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + updatenameApi,
                'credentials': true,
                'headers': {
                    'content-Type': 'application/json',
                },
                data: formdata,
            });
            console.log("response", respdata);
            return {
                data: respdata.data
            }
        }
        catch (err) {
            return {
                error: err
            }
        }
    }
    catch (e) {
        console.log("error", e);
    }
}

export const updategender = async (data) => {
    console.log("updategender data axios:", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data?.email);
            formdata.append("gender", data?.gender);
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + updategenderApi,
                'credentials': true,
                'headers': {
                    'content-Type': 'application/json'
                },
                data: formdata
            });
            console.log("REsponse", respdata);
            return {
                data: respdata.data
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error :", e);
    }
}

export const updatedob = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data?.email);
            formdata.append("dob", data?.dob);
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + updatedobApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            });
            return {
                data: respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }
}


//   export const updatemobilenumber = async(data) => {
//     console.log("updatedob axios" , data);
//     var formdata = new FormData();
//     try {
//         if(data) {
//           formdata.append("email" ,data.email);
//           formdata.append("dob" , data.dob);
//         }
//         try{
//             let respdata = await axios({
//                 'method':'POST',
//                 'url':API_URL + updatedobApi,
//                 'headers':{
//                     'content-type' : 'application/json'
//                 },
//                 data :formdata
//             });
//             return {
//                 data : respdata
//             }
//         }
//         catch(e){
//             return {
//                 error : e
//             }
//         }
//     }
//     catch(e){
//         console.log("error in update dob axios" ,e);
//     }
// }

export const updateaddress = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data?.email);
            formdata.append("address", JSON.stringify(data?.address));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + updateaddressApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            });
            return {
                data: respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }
}

export const updateemergencycontact = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data?.email);
            formdata.append("emergencycontact", JSON.stringify(data?.emergencycontact));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + updateemergencycontactApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            });
            return {
                data: respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }
}

export const removeemergencycontact = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data?.email);
            formdata.append("phonenumber", data?.phonenumber);
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + pullemergencycontactApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            });
            return {
                data: respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }
}

export const uploadgovtid = async (data) => {
    console.log("add content option data", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data?.email)
            formdata.append("proof", data?.id);
            formdata.append("image", data.image);
            // formdata.append("content" , JSON.stringify(data.content));
            formdata.append("front", data?.front);
            formdata.append("back", data?.back);
            formdata.append("country", data?.country);
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + updategovtidApi,
                'credentials': true,
                'headers': {
                    // 'content-type' : 'application/json'
                    'content-Type': 'multipart/form-data',
                },
                data: formdata,
            });
            console.log("response", respdata);
            return {
                data: respdata.data
            }
        }
        catch (err) {
            return {
                error: err
            }
        }
    }
    catch (e) {
        console.log("error", e);
    }
}


export const usersdata = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data);
            //   formdata.append("address" , JSON.stringify(data?.address));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + getuserdataApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            });
            return {
                data: respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }

}


export const updateUserProfile = async (data) => {
    console.log("update user profile hook :", data);
    let dataToReturn;
    const formData = new FormData();
    formData.append(`profilePic`, data.profilePic);
    formData.append(`bio`, data.bio);
    formData.append(`address`, data.address);
    formData.append(`work`, data.work);
    formData.append(`languagesKnown`, data.languagesKnown);
    formData.append(`email`, data.email);
    for (const value of formData.values()) {
        console.log("values of formdata inside useUserHostingHook:", value);
    }
    await axios({
        'method': 'post',
        'url': API_URL + updateUserProfileApi,
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': cookies.get('accessToken') ? `Bearer ${cookies.get('accessToken')}` : ((cookies.get('User-Datas')) ? `Bearer ${(cookies.get('User-Datas')).token}` : "")
        },
        'data': formData,

    }).then((result) => {
        console.log('resulstdata', result.data.userdate.record)
        var day = 60 * 60 * 24 * 1000;
        cookies.set('User-Datas', result.data.userdate.record ,  { path: '/' }, { expires: new Date(Date.now() + day)});
        document.cookie = 'User-Datas' + "=" + JSON.stringify(result.data.userdate.record) + ";" + ";path=/";
        
            let userdata = btoa(JSON.stringify(result.data.userdate.record));
            localStorage.setItem("USRDT" , userdata);
        return dataToReturn = result.data;
    })
    return dataToReturn;
}

export const createRazorPayment = async (data) => {
    let dataToReturn;
    await axios.post(API_URL + createRazorPaymentApi, data).then((response) => {
        dataToReturn = response.data;
    });
    return dataToReturn;
}

export const verifyRazorPayment = async (data) => {
    let dataToReturn;
    await axios.post(API_URL + verifyRazorPaymentApi, data).then((response) => {
        dataToReturn = response.data;
    });
    return dataToReturn;
}

export const bookingExperienceUserDetails = async (data) => {
    let dataToReturn;
    console.log('Hook Data', data)
    await axios.get(`${API_URL}${getExperienceBookingDetails}/${data}`).then((response) => {
        dataToReturn = response.data;
        console.log('-=-=-=-', response.data)
    });
    return dataToReturn;
}



export const GetEmail = async (data) => {
    console.log('datassss', data)
    let dataToReturn = {};
    if(data){
        await axios.get(`${API_URL}${getEmail}/${data}`).then((data) => {
            console.log("review------------------", data);
            dataToReturn = data;
        });
        return dataToReturn;
    }
}


export const completedExperienceBookingDetails = async (data) => {
    let dataToReturn;
    console.log('Hook Data', data)
    await axios.get(`${API_URL}${getCompletedExperienceBooking}/${data}`).then((response) => {
        dataToReturn = response.data;
        console.log('-=-=-=-', response.data)
    });
    return dataToReturn;
}

export const experienceAddreview = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + addExperienceReviewApi, data).then((data) => {
        console.log("review", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const updatePhonenumber = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + updateUserPhoneNumber, data).then((data) => {
        console.log("updatePhonenumber", data);
        dataToReturn = data;
        
    });
    return dataToReturn;
}

export const getusersdatahooks = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("email", data);
            //   formdata.append("address" , JSON.stringify(data?.address));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + getuserdatasApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            })
            if(respdata?.data?.record && data){
                var day = 60 * 60 * 24 * 1000;
                // cookies.remove("User-Datas")
                // const cookies = new Cookies(null ,{ path: '/' });
                // cookies.remove("email");
                // cookies.remove("User-Datas")
                // cookies.set('email', respdata.data.record.email, { path: '/' }, { expires: new Date(Date.now() + day) });
                // document.cookie = 'User-Datas' + "=" + JSON.stringify(data.data.message) + ";" + ";path=/";
                
                let userdata = btoa(JSON.stringify(respdata.data.record));
                localStorage.setItem("USRDT" , userdata);
                cookies.set('User-Datas', respdata.data.record, { path: '/' }, { expires: new Date(Date.now() + day) }) 
                return respdata?.data?.record
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }

}

export const updatebankdetailhooks = async (data, token) => {
    let dataToReturn = {};
    await axios.post(API_URL + updatebankdetailsApi, data, headers).then((data) => {
        console.log("updatebankdetailhooks", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const getsingleroompricehooks = async (data) => {
    console.log("getsingleroompricehooks axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("roomid", data);
            //   formdata.append("address" , JSON.stringify(data?.address));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + getsingleroomprice,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            })
            if(respdata?.data?.record){
                return respdata?.data?.record
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }

}

export const editroompricehooks = async (data, token) => {
    let dataToReturn = {};
    await axios.post(API_URL + editroompriceApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const editexperiencepricehooks = async (data, token) => {
    let dataToReturn = {};
    await axios.post(API_URL + editexperiencepriceApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const getunreadnotificationhooks = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("userid", data);
            //   formdata.append("address" , JSON.stringify(data?.address));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + getunreadnotificationApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            })
            if(respdata){
                return respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }

}

export const readonehooks = async (data ) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            // formdata.append("userid", data);
            formdata.append("id", data);
            //   formdata.append("address" , JSON.stringify(data?.address));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + markasreadoneApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            })
            if(respdata){
                return respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }

}

export const readallhooks = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("userid", data);
            //   formdata.append("address" , JSON.stringify(data?.address));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + markasreadallApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            })
            if(respdata){
                return respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }

}



export const getallnotificationhooks = async (data) => {
    console.log("updatedob axios", data);
    var formdata = new FormData();
    try {
        if (data) {
            formdata.append("userid", data);
            //   formdata.append("address" , JSON.stringify(data?.address));
        }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + getallnotificationApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: formdata
            })
            if(respdata){
                return respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }

}

export const getallcmsdatahook = async (data) => {

    try {
        let dataToReturn = {};
        await axios.post(API_URL+getcmsApi,data,headers).then((data)=>{
            console.log("cmsdata_front",data);
            dataToReturn = data;
        });
        return dataToReturn;
    }
    catch (err) {
      return {
        error: err.response.data
      }
    }
  }

  export const getalllandcmsdata = async () => {

    try {
        let dataToReturn = {};
        await axios.post(API_URL+getlandcmsApi,headers).then((data)=>{
            console.log("cmsdata_front",data);
            dataToReturn = data;
        });
        return dataToReturn;
    }
    catch (err) {
      return {
        error: err.response.data
      }
    }
  }






// getstaydateApi
export const getstaydayhooks = async () => {
    // console.log('userdetails email', email)
    let dataToReturn = {};
    await axios.get(`${API_URL}${getstaydateApi}`).then((data) => {
        dataToReturn = data.data;
    })
    return dataToReturn;
}

export const Gethostusersubscrptionhooks = async () => {
    // console.log('datassss', data)
    let dataToReturn = {};
    await axios.get(`${API_URL}${gethostactivesubscriptionApi}`).then((data) => {
        console.log("review------------------", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const Getguestusersubscrptionhooks = async () => {
    // console.log('datassss', data)
    let dataToReturn = {};
    await axios.get(`${API_URL}${getguestactivesubscriptionApi}`).then((data) => {
        console.log("review------------------", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const hostsubscriptionhooks = async (data) => {
    console.log("updatedob axios", data);
    // var formdata = new FormData();
    try {
        // if (data) {
        //     formdata.append("userid", data);
            //   formdata.append("address" , JSON.stringify(data?.address));
        // }
        try {
            let respdata = await axios({
                'method': 'POST',
                'url': API_URL + subscribehostApi,
                'headers': {
                    'content-type': 'application/json'
                },
                data: data
            })
            if(respdata){
                return respdata
            }
        }
        catch (e) {
            return {
                error: e
            }
        }
    }
    catch (e) {
        console.log("error in update dob axios", e);
    }

}

export const guestsubscriptionhooks = async (data) => {
    try {
        let respdata = await axios({
            'method': 'POST',
            'url': API_URL + subscribeguestApi,
            'headers': {
                'content-type': 'application/json'
            },
            data: data
        })
        if(respdata){
            return respdata
        }
    }
    catch (e) {
        return {
            error: e
        }
    }
}

export const confirmguestsubscriptionhooks = async (data) => {
    try {
        let respdata = await axios({
            'method': 'POST',
            'url': API_URL + confirmguestsubscription,
            'headers': {
                'content-type': 'application/json'
            },
            data: data
        })
        if(respdata){
            return respdata
        }
    }
    catch (e) {
        return {
            error: e
        }
    }
}

export const confirmhostsubscriptionhooks = async (data) => {
    try {
        let respdata = await axios({
            'method': 'POST',
            'url': API_URL + confirmhostsubscription,
            'headers': {
                'content-type': 'application/json'
            },
            data: data
        })
        if(respdata){
            return respdata
        }
    }
    catch (e) {
        return {
            error: e
        }
    }
}



export const getallfaqcmsdatahook = async (data) => {

    try {
        let dataToReturn = {};
        await axios.post(API_URL+getfaqApi,data,headers).then((data)=>{
            console.log("cmsdata_front",data);
            dataToReturn = data;
        });
        return dataToReturn;
    }
    catch (err) {
      return {
        error: err.response.data
      }
    }
  }
  



  export const getwithdrawhistoryhooks = async (data) => {

    try {
        let dataToReturn = {};
        await axios.post(API_URL+getwithdrawhistoryApi,data,headers).then((data)=>{
            console.log("cmsdata_front",data);
            dataToReturn = data;
        });
        return dataToReturn;
    }
    catch (err) {
      return {
        error: err?.response?.data
      }
    }
  }


  export const forgotpasswordhooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + forgotpasswordApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const checkotpforgotpasswordhooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + checkotpforgotpasswordApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const changepasswordhooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + updatepasswordApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}


export const hostinstantbookingstatushooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + hostinstantbookingstatusApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const switchhostandguesthooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + switchhostandguestApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const getguestusersubsctiptionhooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + getguestsubscriptionApi, data, headers).then((data) => {
        console.log("reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}

export const sendotphooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + sendotpApi, data, headers).then((result) => {
        console.log("resend otp hook :", result);
        dataToReturn = result.data;
    });
    return dataToReturn;
}

export const verifyotphooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + verifyotpApi, data, headers).then((result) => {
        console.log("resend otp hook :", result);
        dataToReturn = result.data;
    });
    return dataToReturn;
}



export const unblockdatephooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + unblockdateApi, data, headers).then((result) => {
        console.log("resend otp hook :", result);
        dataToReturn = result.data;
    });
    return dataToReturn;
}


export const editspecialdatehooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + editspecilpriceApi, data, headers).then((result) => {
        console.log("resend otp hook :", result);
        dataToReturn = result.data;
    });
    return dataToReturn;
}


export const addcontactushooks = async (data) => {
    let dataToReturn = {};
    await axios.post(API_URL + addconcactusApi, data, headers).then((result) => {
        console.log("resend otp hook :", result);
        dataToReturn = result.data;
    });
    return dataToReturn;
}


export const getjoinushooks = async () => {
    let dataToReturn = {};
    await axios.get(API_URL + getjoinusApi, headers).then((result) => {
        console.log("resend otp hook :", result);
        dataToReturn = result.data;
    });
    return dataToReturn;
}

export const splitReserveRoom = async (data, token) => {
    let dataToReturn = {};
    await axios.post(API_URL + splitreserveRoomApi, data, headers).then((data) => {
        console.log("split reserve room hook after booking", data);
        dataToReturn = data;
    });
    return dataToReturn;
}


export const getsubscriptiondetailshooks = async () => {
    let dataToReturn;
    await axios.get(`${API_URL}${getsubscriptiondetailsApi}`).then((data) => {
        let datas = { 'record': data.data }
        dataToReturn = datas;
    })
    return dataToReturn;
}


export const getusersubscriptiondetailshooks = async (data) => {
    try {
        let res = await axios.post(API_URL+getusersubscripdataApi,data,headers);
        return res;
    } catch (e) {
        console.log("getusersubscriptiondetailshooks_err", e)
    }
}



import React, { Component, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { API_URL } from "../../../config/env";


const EditPlaceType = (props) => {
    console.log("placetypess ----------", props);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedValue, setSelectedValue] = useState("");
    const [filter, setFilter] = useState('')
    console.log("selectedValue", selectedValue);
  
    const setData = (val, index) => {
      console.log("val,index", val, index);
      setSelectedIndex(index);
      setSelectedValue(val);
      console.log("localStorage.setItem(placeTypeIndex,selectedIndex)", index);
      localStorage.setItem("EditplaceTypeIndex", index);
      props.onGetPlaceType(val);
    };

  useEffect(() => {
    if (props?.roomdata) {
      console.log('propppppppppp', props?.roomdata)
      let index = Number(props?.roomdata?.propertyTypeGroup?.id) - 1;
      console.log("indeeeeeeeee", localStorage.getItem("EditplaceTypeIndex"), props?.roomdata?.propertyTypeGroup)
      let setIndex = localStorage.getItem("EditplaceTypeIndex") != null ? localStorage.getItem("EditplaceTypeIndex") : props?.roomdata?.propertyTypeGroup ? index : selectedIndex;
      console.log('setIndexxxxxxxxxx', setIndex, localStorage.getItem("EditpropertyTypeGroup") != null,)
      localStorage.getItem("EditpropertyTypeGroup") != null ? props.onGetPlaceType(JSON.parse(localStorage.getItem("EditpropertyTypeGroup"))) :
        props?.roomdata?.propertyTypeGroup ? props.onGetPlaceType(props?.roomdata?.propertyTypeGroup) : localStorage.removeItem("EditplaceTypeIndex")

      setSelectedIndex(
        setIndex
      );

    }


  }, [localStorage.getItem("placeTypeIndex"), props]);

    return (
      <div className="col-lg-12 col-xl-12 col-md-12">
        <div className="rightcont rightcont placetype placetype_he_us mb-5">
          <Scrollbars
            style={{ height: "calc(100vh - 320px)" }}
            className="scroll"
          >
            <div
              className="row  px-3 align-items-center height-100 py-1"
              data-aos="fade-up bg-nth-child"
            >
              {props.data &&
                props.data.content.map((val, index) => (
                  val?.status != "Inactive" && 
                  <ul className="col-lg-4 bg-nth-child">
                    <li
                      onClick={() => {
                        setData(val, index);
                        props?.Filter("propertyTypeGroup")
                      }}
                      className={
                        index == selectedIndex
                          ? "active become-host-card-bottom-style"
                          : "become-host-card-bottom-style"
                      }
                    >
                      <div
                      className="d-flex flex-column align-items-center justify-content-center py-4"
                      >
                        <img src={API_URL + props.data.path + "/" + val.image} />
  
                        <h6 className="mt-3 mb-0 host-text-name">{val.type}</h6>
                        
                      </div>
                      {/* <p className={`p-bg-color-${(index % 3) + 1}`}/>  */}
                    </li>
                  </ul>
                ))}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  };
  
  export default EditPlaceType;
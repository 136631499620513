import React, { Component, useCallback, useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";

import Scrollbars from "react-custom-scrollbars";

import img1 from "../../../Assets/images/filter/beaches.png";
import img2 from "../../../Assets/images/filter/bed.png";
import img3 from "../../../Assets/images/filter/camp.png";
import img4 from "../../../Assets/images/filter/cave.png";
import img5 from "../../../Assets/images/filter/design.png";
import img6 from "../../../Assets/images/filter/island.png";
import img7 from "../../../Assets/images/filter/lake.png";
import img8 from "../../../Assets/images/filter/national.png";
import img9 from "../../../Assets/images/filter/omg.png";
import img10 from "../../../Assets/images/filter/pools.png";
import img11 from "../../../Assets/images/filter/tiny.png";

import { API_URL } from "../../../config/env";
const RoomCategory = (props) => {
  const [amenitiesList, setAmenitiesList] = useState(
    localStorage.getItem("roomCategoryIndex")
      ? JSON.parse(localStorage.getItem("roomCategoryIndex"))
      : []
  );
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("category")
      ? JSON.parse(localStorage.getItem("category"))
      : []
  );
  // var index = [];
  var category = [];
  const [checked, setCheckrender] = useState(false);
  //  const setData = useCallback(async (val,index,path) => {
  //     category = amenitiesList;
  //     console.log("val in path" , val , index , path);
  //     let pathData = {imagePath:path}

  //     if(!category.includes(val?.type)){
  // setAmenitiesList([...amenitiesList,val])
  // setSelectedIndex([...selectedIndex,index])
  // index.push(val);
  // console.log("selectedIndex in categories list :",[...selectedIndex,index]);
  // localStorage.setItem("roomCategoryIndex",JSON.stringify([...selectedIndex,index]));
  //     category.push(val?.type);
  //     setAmenitiesList(category);
  //     console.log("Category :",[...selectedIndex,index]);
  //     localStorage.setItem("roomCategoryIndex",JSON.stringify(category));
  // }
  // else if(amenitiesList.includes(val?.type)){
  //    let inx = amenitiesList?.indexOf(val?.type);
  //    if(inx>-1){
  //     category?.splice(inx , 1);
  //     setAmenitiesList(category);
  //    }

  // const filteredArray = amenitiesList.filter((item) => item !== val);
  //  const filteredIndex = selectedIndex.filter((item) => item !== index);
  // setAmenitiesList(filteredArray)
  // setSelectedIndex(filteredIndex)
  // console.log("filteredIndex in categories list :",filteredIndex);
  // localStorage.setItem("roomCategoryIndex",JSON.stringify(filteredIndex));
  //         console.log("category :",category);
  //         localStorage.setItem("roomCategoryIndex",JSON.stringify(category));
  //          }

  //            props.onGetPlaceCategory(amenitiesList);
  // },[amenitiesList]);

  const setData = async (val, index, path) => {
    category = amenitiesList;
    var index = selectedIndex;
    console.log("val in path eiji", amenitiesList, category, typeof category);
    if (!category?.includes(val?.type)) {
      console.log("val in include eiji", val?.type);
      category?.push(val?.type);
      index?.push(val);
      setAmenitiesList(category);
      setSelectedIndex(index);
      console.log("Category :", category);
      localStorage.setItem("roomCategoryIndex", JSON.stringify(category));
    } else if (category?.includes(val?.type)) {
      console.log("val in not  include eiji", val?.type);
      let inx = category?.indexOf(val?.type);
      console.log("splice", inx);
      if (inx > -1) {
        category?.splice(inx, 1);
        index?.splice(inx, 1);
        setAmenitiesList(category);
        setSelectedIndex(index);
      }

      console.log("category :", category);
      localStorage.setItem("roomCategoryIndex", JSON.stringify(category));
    }
    console.log("getplacecategory", index);
    props.onGetPlaceCategory(index);
    setCheckrender(!checked);
  };

  useEffect(() => {
    props?.onstatus();
    // localStorage.getItem("roomCategoryIndex") &&setAmenitiesList(JSON.parse(localStorage.getItem("roomCategoryIndex")));
    // localStorage.getItem("category") &&setSelectedIndex(JSON.parse(localStorage.getItem("category")));
    // setSelectedIndex((localStorage.getItem("roomCategoryIndex"))?JSON.parse(localStorage.getItem("roomCategoryIndex")):selectedIndex);
    localStorage.getItem("category")
      ? props.onGetPlaceCategory(JSON.parse(localStorage.getItem("category")))
      : localStorage.removeItem("roomCategoryIndex");
  }, [selectedIndex, amenitiesList, localStorage.getItem("category")]);

  // const setData = useCallback(async (val,index,path) => {
  //     console.log("set data", val , index , path);
  //     let arrayData = [];
  //     let arrayIndex = [];
  //     arrayData = amenitiesList;
  //     arrayIndex = selectedIndex;

  //     let pathData = {imagePath:path}

  //     if(!arrayData.includes(val)){
  //         alert("1")
  //         console.log("value " , val)
  //     arrayData.push(val);
  //     arrayIndex.push(index);

  //     }
  //     else if(arrayData.includes(val)){
  //         alert("2")

  //         const filteredArray = arrayData.filter((item) => item !== val);
  //         console.log("filteredArray",filteredArray)
  //         arrayData=(filteredArray);
  //         const filteredIndex = arrayIndex.filter((item) => item !== index);
  //         console.log("filteredIndex",filteredIndex)

  //         arrayIndex=(filteredIndex);

  //     }
  //     console.log("arrayIndex",arrayIndex);
  //     setAmenitiesList(arrayData);
  //     setSelectedIndex(arrayIndex);
  //     // dummyArr.push(arrayIndex)

  //     // props.onGetAmenities(arrayData);
  // },[selectedIndex,setAmenitiesList])
  // console.log("selectedIndex",selectedIndex)
  // useEffect(()=>{

  // },[selectedIndex]);

  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="header-txt">
        <h2 className="fw-800">What does your place offer?</h2>
        <p className="des-txt">
          Select the amenities that you offer to guests who stay with you
        </p>
      </div>

      <div className="row mt-4 placeOffer">
        {props.data &&
          props.data.content.map((val, index) => {
            if (val?.status != "Inactive") {
              return (
                <>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4">
                    <div
                      className={
                        amenitiesList && amenitiesList?.includes(val?.type)
                          ? "ur-plc-card amenity active"
                          : "ur-plc-card amenity"
                      }
                    >
                      <div
                        onClick={() => {
                          setData(val, index, props.data.path);
                        }}
                      >
                        <h5 className="heading-txt mb-2 fw-700">{val.type}</h5>
                        <p className="mb-2 desc-txt f-15 fw-500">
                          Amenity details will go here, this is an example of a
                          selected card
                        </p>
                        <img
                          src={API_URL + props.data.path + "/" + val.image}
                          className="img-fluid offer-img"
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
      </div>
    </div>
  );
};

export default RoomCategory;

import React, { useEffect, useState } from "react";
import {
    Button,
    InputGroup,
    Form,
    Dropdown,
    Toast,
    TabContainer,
    Row,
    Col,
    Tab,
    Nav,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import EditPlaceType from "../../../../../BecomeHostEdit/PropertyType/EditPlaceType";
import EditDescribePlace from "../../../../../BecomeHostEdit/PropertyType/EditDescribePlace"
import { UpdateHostingDetailshooks, getHostingDetails } from "../../../../../../Hooks/useUserHostingHook";
import EditSpace from "../../../../../BecomeHostEdit/PropertyType/EditSpace";
import EditRoomCategory from "../../../../../BecomeHostEdit/PropertyType/EditRoomCategory";
import EditGuestCount from "../../../../../BecomeHostEdit/PropertyType/EditGuestCount";
import EditLastQuest from "../../../../../BecomeHostEdit/PropertyType/EditLastQuest";
import EditAmenities from "../../../../../BecomeHostEdit/PropertyType/EditAmenities";
import EditHighlights from "../../../../../BecomeHostEdit/PropertyType/EditHighlights";
import EditSetPrice from "../../../../../BecomeHostEdit/PropertyType/EditSetPrice";
import EditCancellationPolicy from "../../../../../BecomeHostEdit/PropertyType/EditCancellationPolicy";
import EditPlacePhotos from "../../../../../BecomeHostEdit/PropertyType/EditPlacePhotos";
import EditCreateTitle from "../../../../../BecomeHostEdit/PropertyType/EditCreateTitle";
import EditCreateDescriptionContent from "../../../../../BecomeHostEdit/PropertyType/EditDescriptionContent";
import { toastOptions } from "../../../../../../config/env";
import toast from "react-hot-toast";

const ListingModal = (props) => {

    //States
const [hostDatas, setHostDatas] = useState([]);
const [count, setCount] = useState(0);
const [host, setHost] = useState(true);
const [swithcount, setSwitchCount] = useState();
const [val, setVal] = useState('')
const [userHostDatas, setUserHostDatas] = useState({});
const [btnStatus, setBtnStatus] = useState(false);
const [filter, setFilter] = useState('');
const [Image, setImageData] = useState([]);
const [video, setVideo] = useState({});

const [showSecondary, setShowSecondary] = useState(false)

console.log("hossssssssss", swithcount)
console.log("roomdata_roomdata hossssssssss", props?.roomdata)
const getHostDatasFunction = async () => {
    getHostingDetails().then((data) => {
      console.log("data from gethosting data function", data);
      setHostDatas(data);
    });
  };


  useEffect(() => {
    getHostDatasFunction();
  }, []);

    useEffect(() => {
        console.log('props?.roomdata_props?.roomdata', props?.roomdata,  localStorage.getItem("EditpropertyTypeGroup"),  localStorage.getItem("EditplaceTypeIndex"))
        if (props?.roomdata) {
            let propertyTypeGroup = props?.roomdata?.propertyTypeGroup;
            console.log("propertyTypeGroup_propertyTypeGroup",props?.roomdata, propertyTypeGroup)
            if(propertyTypeGroup) {
                let index = Number(props?.roomdata?.propertyTypeGroup?.id) - 1
                localStorage.setItem("EditpropertyTypeGroup", JSON.stringify(propertyTypeGroup))
                localStorage.setItem("EditplaceTypeIndex", index);
            }
        }
    }, [props?.roomdata, host, !host])

    const getPlaceType = (data) => {
        console.log("data from get place type:", data);
        userHostDatas.propertyTypeGroup = data;
        localStorage.setItem("EditpropertyTypeGroup", JSON.stringify(data));
        console.log("getPlaceType hihi");
        setBtnStatus(true);
    };


    const getDescribePlace = (data) => {
        console.log("data from get describe place:", data);
        userHostDatas.propertyType = data;
        localStorage.setItem("EditpropertyType", JSON.stringify(data));
        console.log("getDescribePlace hihi");
        setBtnStatus(true);
    };

    const getSpace = (data) => {
        console.log("data from get content:", data);
        userHostDatas.privacyType = data;
        localStorage.setItem("privacyType", data);
        console.log("getSpace hihi");
        setBtnStatus(true);
      };


      const getPlaceCategory = (data) => {
        console.log("data get from category :", data);
        userHostDatas.category = data;
        localStorage.setItem("Editcategory", JSON.stringify(data));
        if (data?.length == 0) {
          setBtnStatus(false);
        } else {
          console.log("setplacecategory hihi");
          setBtnStatus(true);
        }
      };

      const statuschng = () => {
        console.log("category lemngth", localStorage.getItem("Editcategory"));
        JSON.parse(localStorage.getItem("Editcategory"))?.length > 0
          ? setBtnStatus(true)
          : setBtnStatus(false);
      };

      const getGuestCount = (data) => {
        console.log("data from get guest count:", data);
        if (
          data.reduce(
            (accumulator, currentValue) =>
              accumulator + parseFloat(currentValue?.count),
            0
          ) > 0
        ) {
          userHostDatas.floorPlan = data;
          localStorage.setItem("EditfloorPlan", JSON.stringify(data));
          console.log("getGuestCount hihi");
          setBtnStatus(true);
        } else {
          setBtnStatus(false);
        }
      };


      const getLastQuest = (data) => {
        console.log("data from get last quest:", data);
        userHostDatas.cancellationPolicy = data;
        // localStorage.setItem("legal",JSON.stringify(data));
        localStorage.setItem("Editlegal", data);
        console.log("getLastQuest hihi");
        setBtnStatus(true);
      };


      const getAmenities = (data) => {
        console.log("data from get amenities:", data);
        userHostDatas.amenities = data;
        localStorage.setItem("Editamenities", JSON.stringify(data));
        if (data?.length == 0) {
          setBtnStatus(false);
        } else {
          console.log("getAmenities hihi");
          setBtnStatus(true);
        }
      };

      const statuschngammenities = () => {
        JSON.parse(localStorage.getItem("Editamenities"))?.length > 0
          ? setBtnStatus(true)
          : setBtnStatus(false);
      };

      const getHighlights = (data) => {
        console.log("data from get highlights:", data);
        userHostDatas.highlights = data;
        localStorage.setItem("EditHighlights", JSON.stringify(data));
        if (data.length == 0) {
          setBtnStatus(false);
        } else {
          console.log("getHighlights hihi");
          setBtnStatus(true);
        }
      };


      const getPrice = (data) => {
        console.log("data from get price:", data);
        if (data == 0) {
          setBtnStatus(false);
        } else {
          if (data?.price) {
            userHostDatas.price = data?.price;
          } else {
            setBtnStatus(false);
            return;
          }
          if (data?.checkin) {
            userHostDatas.checkintime = data?.checkin;
          } else {
            setBtnStatus(false);
            return;
          }
    
          if (data?.checkout) {
            userHostDatas.checkouttime = data?.checkout;
          } else {
            setBtnStatus(false);
            return;
          }
          console.log('check that side', data)
          localStorage.setItem("Editprice", JSON.stringify(data));
          console.log("getPrice hihi");
          setBtnStatus(true);
        }
      };


      const getCancellationPolicy = (data) => {
        console.log("EditgetcancellationPolicy", data);
        localStorage.setItem("EditcancellationPolicy", JSON.stringify(data));
        userHostDatas.legal = data;
        console.log("getCancellationPolicy hihi");
        setBtnStatus(true);
      };



      const getTitle = (data) => {
        if (data.length == 0) {
          setBtnStatus(false);
        } else {
          console.log("data from get title:", data);
          userHostDatas.title = data;
          localStorage.setItem("title", data);
          console.log("getTitle hihi");
          setBtnStatus(true);
        }
      };


      const getDescriptionContent = (data) => {
        if (data.length == 0) {
          setBtnStatus(false);
        } else {
          if (userHostDatas.rules?.length == 0) {
            setBtnStatus(false);
          } else {
            setBtnStatus(true);
          }
          console.log("getDescriptionContent", data);
          // setBtnStatus(true);
        }
        userHostDatas.descriptionDetails = data;
        localStorage.setItem("EditdescriptionDetails", data);
        console.log("getDescriptionContent hihi");
      };
    
      const getrules = (data) => {
        if (data.length == 0) {
          setBtnStatus(false);
        } else {
          if (userHostDatas.descriptionDetails?.length == 0) {
            setBtnStatus(false);
          } else {
            setBtnStatus(true);
          }
          console.log("getrules_getrules", data);
          console.log("getDescriptionContent hihi");
        }
        userHostDatas.rules = data;
        localStorage.setItem("Editrules", data);
      };


      

    const UpdateHostRoomDetails = async () => {
        try {
            let reqdata = {};
            console.log('filttttttt', filter, filter == "category")
            if (filter == "propertyTypeGroup") {
                console.log("userDtasgal", userHostDatas)
                reqdata = { "_id": props?.roomdata?._id, "propertyTypeGroup": userHostDatas['propertyTypeGroup'], filter : "propertyTypeGroup"}
            } else if (filter == "propertyType") {
                console.log("userDtasgal", userHostDatas)
                reqdata = { "_id": props?.roomdata?._id, "propertyType": userHostDatas['propertyType'], filter : "propertyType" }
            } else if (filter == "privacyType") {
                console.log("userDtasgal", userHostDatas)
                reqdata = { "_id": props?.roomdata?._id, "privacyType": userHostDatas['privacyType'].toLowerCase(),  filter : "privacyType"}
            } else if(filter == "category") {
                console.log("userDtasgal filttttttt", userHostDatas)
                reqdata = { "_id": props?.roomdata?._id, "category": userHostDatas['category'], filter : "category"} 
            } else if (filter == "floorPlan") {
                console.log("userDtasgal filttttttt", userHostDatas);
                reqdata = { "_id": props?.roomdata?._id, "floorPlan": userHostDatas['floorPlan'], filter : "floorPlan"} 
            } else if (filter == "cancellationPolicy") {
                reqdata = { "_id": props?.roomdata?._id, "cancellationPolicy": userHostDatas['cancellationPolicy'], filter : "cancellationPolicy"} 
            } else if (filter == "amenities") {
                const path = { path: hostDatas[4].path };
                reqdata = { "_id": props?.roomdata?._id, "amenities": userHostDatas['amenities'],  filter : "amenities"} 
                 userHostDatas.amenities.push(path);
            } else if (filter == "description") {
                reqdata = { "_id": props?.roomdata?._id, "description": userHostDatas['description'],  filter : "description"} 
            } else if (filter == "price") {
                reqdata = { "_id": props?.roomdata?._id, "price": userHostDatas['price'], "checkintime" : userHostDatas["checkintime"], "checkouttime" : userHostDatas["checkouttime"], filter : "price"} 
            } else if (filter == "legal") {
              reqdata = { "_id": props?.roomdata?._id, "legal": userHostDatas['legal'],  filter : "legal"}
            } else if (filter == "highlights") {
              reqdata = { "_id": props?.roomdata?._id, "description": userHostDatas['highlights'],  filter : "highlights"}
            } else if (filter == "title") {
              reqdata = { "_id": props?.roomdata?._id, "title": userHostDatas['title'],  filter : "title"}
            } else if (filter == "rules") {
              reqdata = { "_id": props?.roomdata?._id, "rules": userHostDatas['rules'],  "descriptionDetails" : userHostDatas['descriptionDetails'], filter : "descriptionDetails"}
            }
            console.log('reqdatas filttttttt', reqdata)
            if (reqdata) {
                let res = await UpdateHostingDetailshooks(reqdata);
                console.log('resdatassssssss', res);
                if (res?.data?.status == true) {
                    // setHost(true)
                    localStorage.removeItem("EditpropertyTypeGroup");
                    localStorage.removeItem("EditplaceTypeIndex");
                    localStorage.removeItem("EditdescribePlaceIndex");
                    localStorage.removeItem("EditpropertyType");
                    localStorage.removeItem("EditspaceIndex");
                    localStorage.removeItem("EditprivacyType");
                    localStorage.removeItem("Editcategory");
                    localStorage.removeItem("EditroomCategoryIndex");
                    localStorage.removeItem("EditfloorPlan");
                    localStorage.removeItem("Editlegal");
                    localStorage.removeItem("Editamenities");
                    localStorage.removeItem("EditamenitiesIndex");
                    localStorage.removeItem("Editprice");
                    localStorage.removeItem("EditcancellationPolicyIndex");
                    localStorage.removeItem("EditcancellationPolicy");
                    localStorage.removeItem("EditHighlightsIndex");
                    localStorage.removeItem("EditHighlights");
                    localStorage.removeItem("title");
                    localStorage.removeItem("EditdescriptionDetails");
                    localStorage.removeItem("Editrules");
                    props?.handleClose()
                }

                if(res?.data?.status == true) {
                  toast.success("Update Successfully", toastOptions);
                  // window.location.reload()
                }
            }

        } catch (e) {
            console.log("UpdateHostRoomDetails_err", e)
        }
    }


    const removeLocalStorage = async () => {
      try {
        localStorage.removeItem("EditpropertyTypeGroup");
        localStorage.removeItem("EditplaceTypeIndex");
        localStorage.removeItem("EditdescribePlaceIndex");
        localStorage.removeItem("EditpropertyType");
        localStorage.removeItem("EditspaceIndex");
        localStorage.removeItem("EditprivacyType");
        localStorage.removeItem("Editcategory");
        localStorage.removeItem("EditroomCategoryIndex");
        localStorage.removeItem("EditfloorPlan");
        localStorage.removeItem("Editlegal");
        localStorage.removeItem("Editamenities");
        localStorage.removeItem("EditamenitiesIndex");
        localStorage.removeItem("Editprice");
        localStorage.removeItem("EditcancellationPolicyIndex");
        localStorage.removeItem("EditcancellationPolicy");
        localStorage.removeItem("EditHighlightsIndex");
        localStorage.removeItem("EditHighlights");
        localStorage.removeItem("title");
        localStorage.removeItem("EditdescriptionDetails");
        localStorage.removeItem("Editrules");
      } catch (e) {
        console.log("removeLocalStorage_err", e)
      }
    }
  {console.log("guest have",props?.HostDetails)}
    return (
        <>
            {host ? <Modal
                show={props?.show}
                onHide={() => {props?.handleClose()}}
                className="split_modal_popup list_edit_modal"
                centered
                size="lg"
            >
                {host ? <Modal.Header closeButton>
                    <Modal.Title>List</Modal.Title>
                </Modal.Header> :
                <Modal.Header closeButton>
                <Modal.Title>{props?.roomdata?.title}</Modal.Title>
            </Modal.Header>}
                <Modal.Body>
                    {props?.HostDetails && props?.HostDetails?.map((item, index) => {
                        return (
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <p className="mb-0">{item.name}</p>
                                <div>
                                    <span className="list_detail_edit">
                                        <i className="fa fa-edit" onClick={() => {setSwitchCount(index); setHost(false);setShowSecondary(true)}}/>
                                    </span>
                                </div>
                                
                            </div>
                        );
                    })}
                     
                </Modal.Body> 
                  
                
               
               {host &&  <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {props?.handleClose()}}
                        className="btn-theme"
                    >
                        Close
                    </Button>
                </Modal.Footer>}
            </Modal>
            :
            
            
            <Modal
            show={showSecondary}
            onHide={() => {props?.handleClose()}}
            className="split_modal_popup list_edit_modal"
            centered
            size="lg"
        >
          <div className="header_modal d-flex justify-content-between align-items-center px-3 mt-3">
                <Modal.Title>List</Modal.Title>
                <i class="fa-solid fa-xmark" onClick={() => {setShowSecondary(false); setHost(true); removeLocalStorage()}} />
                </div>
     
             
                    <Modal.Body>
            {props?.HostDetails && props?.HostDetails?.map((item, index) => {
              { console.log('props?.describe?.rommdata', hostDatas[8], index, Number(swithcount), index, Number(swithcount) == index) }
              if (index == 0 && Number(swithcount) == index) {
                return (
                  <EditPlaceType data={hostDatas[0]} onGetPlaceType={getPlaceType} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
                )
              } 
              // else if (index == 1 && Number(swithcount) == index) {
              //   return (
              //     <EditDescribePlace data={hostDatas[1]} onGetDescribePlace={getDescribePlace} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
              //   )
              // }
              // else if (index == 2 && Number(swithcount) == index) {
              //   return (
              //     <EditSpace data={hostDatas[2]} onGetSpace={getSpace} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
              //   )
              // }
              else if (index == 1 && Number(swithcount) == index) {
                return (
                  <EditRoomCategory data={hostDatas[7]} onGetPlaceCategory={getPlaceCategory} onstatus={statuschng} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
                )
              } else if (index == 2 && Number(swithcount) == index) {
                return (
                  <EditGuestCount data={hostDatas[8]} onGuestCount={getGuestCount} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
                )
              }
              else if (index == 3 && Number(swithcount) == index) {
                return (
                  <EditLastQuest data={hostDatas[5]} onLastQuest={getLastQuest} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
                )
              }
              else if (index == 4 && Number(swithcount) == index) {
                return (
                  <EditAmenities data={hostDatas[3]} onGetAmenities={getAmenities} onstatus={statuschngammenities} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
                )
              } else if (index == 5 && Number(swithcount) == index) {
                return (
                  <EditCreateTitle onGetTitle={getTitle} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
                )
              }
              else if (index == 6 && Number(swithcount) == index) {
                return (
                  <EditHighlights data={hostDatas[4]} onGetHighlights={getHighlights} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
                )
              }
              else if (index == 7 && Number(swithcount) == index) {
                return (
                  <EditCreateDescriptionContent onGetDescriptionContent={getDescriptionContent} onGetRules={getrules} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
                )
              }
              // else if (index == 8 && Number(swithcount) == index) {
              //   return (
              //     <EditSetPrice onGetPrice={getPrice} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
              //   )
              // } 
              // else if (index == 8 && Number(swithcount) == index) {
              //   return (
              //     <EditCancellationPolicy data={hostDatas[6]} onGetCancellationPolicy={getCancellationPolicy} roomdata={props?.roomdata} Filter={(data) => setFilter(data)} />
              //   )
              // }

            })}
                        <Button
                            className="me-3 enable next-btn" onClick={() => {setHost(true); UpdateHostRoomDetails(); }}> Update

                        </Button>
                    </Modal.Body>
          
           
           {host &&  <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {props?.handleClose()}}
                    className="btn-theme"
                >
                    Close
                </Button>
            </Modal.Footer>}
        </Modal>}



            {/* secondary modal */}
           
            {/* end of secondary modal */}
        </>
    );
};

export default ListingModal;

import React from "react";
import HostHeader from "../BecomeHost/AfterHost/HostHeader";
import Footer from "./Footer";

// image imports

import bannerImg from "../../Assets/images/privacy/1.jpg";
import { useState, useEffect } from "react";
import { getallcmsdatahook } from "../../Hooks/useUserHook";
import { API_URL } from "../../config/env";
import PropertyNavHeader from "../Landing/PropertyNavHeader";

const Termsofservice = () => {
  const [cms, setCms] = useState([]);
  const [content, setContent] = useState({});
  useEffect(() => {
    async function fetchdata() {
      console.log("");
      await getallcmsdatahook().then((result) => {
        console.log("get all crm hook result", result?.data?.data);
        result?.data?.data?.map((data, i) => (data.sno = i + 1));
        setCms(result?.data?.data);
        let filterdata = result?.data?.data?.find(
          (el) => el.name == "termsofservice" && el?.status == "Active"
        );
        console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
        setContent(filterdata?.content);
      });
    }
    fetchdata();
  }, []);

  const cmsFilter = (data) => {
    try {
      let filterdata = cms?.find(
        (el) => el.identifier == "termsofservice" && el?.status == "Active"
      );
      console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
      return filterdata;
    } catch (e) {
      console.log("cmsFilter_err", e);
    }
  };
  return cms && cms != 0 && content ? (
    <>
      {/* <HostHeader /> */}

      {/* Privacy Policy */}                                                                                                                                                                                                                                                                 
      <div className="property-header">
        <PropertyNavHeader />
      </div>
      <section className="pb-5 cms_cmn_style terms-of-services">
        {/* <div
          className="container"
          dangerouslySetInnerHTML={{ __html: content }}
        /> */}
        <div className="terms-conditions">
          <div className="container container_custom">
            <h2 className="header-txt fw-700">
              Terms and Conditions of Service
            </h2>
            <div className="content_sec">
              <div>
                <p className="mb-0">
                  By accessing this website, you are agreeing to be bound by
                  these website Terms and Conditions of Service, the Privacy
                  Policy, all applicable laws and regulations, and agree that
                  you are responsible for compliance with any applicable local
                  laws. We may modify these Terms and Conditions at any time
                  without notice to you by posting revised Terms and Conditions
                  of Service. Your use of the website constitutes your binding
                  acceptance of these Terms and Conditions of Service, including
                  any modifications that we make. If you do not agree with any
                  of these terms, you are prohibited from using or accessing
                  this site. The materials contained in this website are
                  protected by applicable copyright and trademark law.
                </p>
                <p className="mb-0">
                  The Service includes a combination of content that we create
                  and that other third party content suppliers create. You
                  understand that the Service are provided "AS IS", and that
                  Copyandpasteemoji.com does not guarantee the accuracy,
                  integrity or quality of any content available on the website.
                  Copyandpasteemoji.com disclaims all responsibility and
                  liability for the accuracy, availability, timeliness, security
                  or reliability of the Service. We reserve the right to modify,
                  suspend or discontinue the Service with or without notice at
                  any time and without any liability to you.
                </p>
                <p className="mb-0">
                  The Service is directed to adults and is not directed to
                  children under the age of 18. You must be 18 years of age or
                  older to use the Service.
                </p>
              </div>
              <div className="mt-3">
                <p className="mb-0">Access to Sites</p>
                <p className="mb-0">
                  Copyandpasteemoji.com grants you a limited license to access
                  and use the website via Web browsers or RSS readers only. You
                  agree not to copy, republish, frame, download, transmit,
                  modify, rent, lease, loan, sell, assign, distribute, license,
                  sublicense, reverse engineer, or create derivative works based
                  on the Content or Design of the website.
                </p>
                <p className="mb-0">
                  In addition, you agree not to use any data mining, robots, or
                  similar data gathering and extraction methods in connection
                  with the website. You further agree that you will not
                  interfere with another member's use and enjoyment of the
                  Service; you will not interfere with or disrupt the security
                  measures of the Service; you will not interfere with or
                  disrupt networks connected to the Service.
                </p>
              </div>

              <div className="mt-3">
                <p className="mb-0">Use License</p>
                <p className="mb-0">
                  Permission is granted to temporarily download one copy of the
                  materials (information or software) on Copyandpasteemoji.com
                  website for personal, non-commercial transitory viewing only.
                  This is the grant of a license, not a transfer of title, and
                  under this license you may not:
                </p>
                <ul className="custom-list">
                  <li>modify or copy the materials;</li>
                  <li>
                    use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial)
                  </li>

                  <li>
                    attempt to decompile or reverse engineer any software
                    contained on Copyandpasteemoji.com website;
                  </li>

                  <li>
                    remove any copyright or other proprietary notations from the
                    materials; or
                  </li>

                  <li>
                    transfer the materials to another person or "mirror" the
                    materials on any other serve
                  </li>

                  <li>
                    transfer the materials to another person or "mirror" the
                    materials on any other server
                  </li>

                  <li>
                    access the site using automated means which may degrade
                    service for others.
                  </li>
                </ul>
                <p className="mb-0">
                  This license shall automatically terminate if you violate any
                  of these restrictions and may be terminated by
                  Copyandpasteemoji.com at any time. Upon terminating your
                  viewing of these materials or upon the termination of this
                  license, you must destroy any downloaded materials in your
                  possession whether in electronic or printed format.
                </p>
              </div>

              <div className="mt-3">
                <p className="mb-0">
                  Conduct on Website and Contribution of Content
                </p>
                <p className="mb-0">
                  Your use of the Website is subject to all applicable laws and
                  regulations, and you are solely responsible for the substance
                  of your communications through the Website. By posting
                  information in or otherwise using any communications service
                  that may be available to you on or through this Website, you
                  agree that you will not upload, share, post, or otherwise
                  distribute or facilitate distribution of any content
                  (including text, communications, data or other information)
                  that:
                </p>
                <ul className="custom-list mt-2">
                  <li className="mb-2">
                    is known by you to be false, inaccurate or misleading
                  </li>
                  <li className="mb-2">
                    is unlawful, obscene, vulgar, shocking, excessive profanity,
                    threatens or advocates for harm on oneself or others,
                    abusive, harassing, defamatory, libelous, deceptive,
                    fraudulent, invasive of another's privacy, tortious,
                    contains explicit or graphic descriptions or accounts of
                    sexual acts (including but not limited to sexual language of
                    a violent or threatening nature directed at another
                    individual or group of individuals), pornographic, adult or
                    mature, or otherwise violates our rules or policies, profane
                    or indecent including any communication that constitutes (or
                    encourages conduct that would constitute) a criminal
                    offense, gives rise to civil liability or otherwise violates
                    any local, state or federal law, bullies, victimizes,
                    harasses, degrades, or intimidates an individual or group of
                    individuals;
                  </li>

                  <li className="mb-2">
                    is malware, adware, hacking, cracking content, Illicit drugs
                    and drug paraphernalia content, or any other content that is
                    illegal, promotes illegal activity or infringes on the legal
                    rights of others;
                  </li>

                  <li className="mb-2">
                    contains links to software viruses or any other computer
                    code, files, or programs itself that are designed or
                    intended to disrupt, damage, or limit the functioning of any
                    software, hardware, or telecommunications equipment or to
                    damage or obtain unauthorized access to any data or other
                    information of any third party;
                  </li>

                  <li className="mb-2">
                    impersonates any person or entity, including any of our
                    employees or representatives;
                  </li>

                  <li className="mb-2">
                    is regarding programs which compensate users for clicking
                    ads or offers, performing searches, surfing websites or
                    reading emails;
                  </li>

                  <li className="mb-2">
                    claims the identity, characteristics or qualifications of
                    another person;
                  </li>

                  <li className="mb-2">
                    for which you were compensated or granted any consideration
                    by any third party;
                  </li>

                  <li className="mb-2">
                    incites hatred against, promotes discrimination of, or
                    disparages an individual or group on the basis of their race
                    or ethnic origin, religion, disability, age, nationality,
                    veteran status, sexual orientation, gender, gender identity,
                    or other characteristic that is associated with systemic
                    discrimination or marginalization;
                  </li>

                  <li className="mb-2">
                    infringes or violates on any patent, trademark, trade
                    secret, copyright, right of publicity, other proprietary
                    right of any party, or other intellectual property rights;
                  </li>

                  <li className="mb-2">
                    promotes, sells, or advertises tobacco or tobacco-related
                    products, alcoholic beverages, prescription drugs, weapons
                    or ammunition (e.g., firearms, firearm components, fighting
                    knives, stun guns), distribution of coursework or student
                    essays, products obtained from endangered or threatened
                    species;
                  </li>

                  <li className="mb-2">
                    constitutes unauthorized or unsolicited advertising, junk or
                    bulk email (also known as "spamming"), chain letters, any
                    other form of unauthorized solicitation, or any form of
                    lottery or gambling.
                  </li>
                </ul>
                <p className="mb-2">
                  We neither endorse nor assume any liability for the contents
                  of any material uploaded or submitted by third party users of
                  the Website. However, we and our agents have the right at
                  their sole discretion to remove any content that, in our
                  judgment, does not comply with our Terms and Conditions of
                  Service, Adsense program policies, and any other rules of user
                  conduct for our Website, or is otherwise harmful,
                  objectionable, or inaccurate.
                </p>

                <p className="mb-2">
                  In addition, you may not use the Service to breach security or
                  attempt to gain unauthorized access to another network or
                  server. Users who violate systems or network security may
                  incur criminal or civil liability. Not all areas of the
                  Website may be available to you or other authorized users of
                  the Website.
                </p>
                <p className="mb-2">
                  Much of the content of this Website is provided by and is the
                  responsibility of the person or people who made those
                  postings. We do not ensure the accuracy or legitimacy of any
                  comments, remarks or other information posted or generated by
                  users of this Website and, therefore, take no responsibility
                  for such content provided by users or other third parties.
                  However, we reserve the right to delete any content at any
                  time without notifying the person from which such content
                  originated. You hereby consent to such removal and waive any
                  claim against us arising out of such removal of content. We
                  are not responsible for any failure or delay in removing
                  content.
                </p>
                <p className="mb-0">
                  Any failure by us to enforce or exercise any provision of
                  these Terms and Conditions of Service or related rights shall
                  not constitute a waiver of that right or provision.
                </p>
              </div>

              <div className="mt-3">
                <p className="mb-0">Use of Materials and Proprietary Rights</p>
                <p className="mb-0">
                  Content displayed on or through the Service is protected by
                  copyright as a collective work and/or compilation. Any
                  reproduction or redistribution of the site or the collective
                  work is prohibited without the express written consent of
                  Copyandpasteemoji.com
                </p>

                <p className="mb-0">
                  By posting Content to any public area of the Service, you
                  automatically grant, and you represent and warrant that you
                  have the right to grant, to Copyandpasteemoji.com an
                  irrevocable, perpetual, non-exclusive, fully paid, worldwide
                  license to use, copy, perform, display, and distribute said
                  Content and to prepare derivative works of, or incorporate
                  into other works, said Content, and to grant and authorize
                  sublicenses of the foregoing. Subject to our Privacy Policy,
                  any communication or material that you transmit to this site
                  or to us, whether by electronic mail, post, or other means,
                  for any reason, will be treated as non-confidential and
                  non-proprietary. Please do not submit confidential or
                  proprietary information to us unless we have mutually agreed
                  in writing otherwise. We are also unable to accept your
                  unsolicited ideas or proposals, so please do not submit them
                  to us in any circumstance.
                </p>

                <p className="mb-0">
                  By posting your content using the website, you are granting an
                  unrestricted, irrevocable, non-exclusive, royalty-free,
                  perpetual, worldwide, and fully transferable, assignable, and
                  sublicensable right and license to use, copy, reproduce,
                  modify, adapt, publish, translate, create collective or
                  derivative works from, distribute, perform and display your
                  content in whole or in part and to incorporate it in other
                  works in any form, media, or technology now known or later
                  developed. You further warrant that all so-called moral rights
                  in the content have been waived. By posting content to the
                  website, you warrant and represent that you either own or
                  otherwise control all of the rights to that content,
                  including, without limitation, all the rights necessary for
                  you to provide, post, upload, input or submit the content, or
                  that your use of the content is a protected fair use. You
                  agree that you will not knowingly provide material and
                  misleading false information. You represent and warrant also
                  that the content you supply does not violate these Terms. It
                  is your sole responsibility to ensure that your postings do
                  not disclose confidential and/or proprietary information,
                  including personal financial information, information covered
                  by a nondisclosure agreement, and information that you are not
                  authorized to disclose. We caution you not to disclose
                  personal information about yourself or your children, such as
                  social security numbers, credit card numbers, etc. You agree
                  to indemnify and hold Us and Our affiliated companies, and
                  their directors, officers and employees, harmless for any and
                  all claims or demands, including reasonable attorney fees,
                  that arise from or otherwise relate to your use of the
                  website, any content you supply to the website, or your
                  violation of these Terms or the rights of another.
                </p>
              </div>

              <div className="mt-3">
                <p className="mb-0">Security and Password</p>
                <p className="mb-0">
                  You are solely responsible for maintaining the confidentiality
                  of your password and account and for any and all statements
                  made and acts or omissions that occur through the use of your
                  password and account. Therefore, you must take steps to ensure
                  that others do not gain access to your password and account.
                  Our personnel will never ask you for your password.You may not
                  transfer or share your account with anyone, and we reserve the
                  right to immediately terminate your account if you do transfer
                  or share your account.
                </p>
                <p className="mb-0">
                  You agree to be responsible for any action that occurs as the
                  result of any access to your account as the result of access
                  with your password. You agree that if it is determine that
                  your password has been compromised that your account may be
                  closed or access to the account may be terminated with no
                  obligation to keep or maintain the information or data in your
                  account.
                </p>
              </div>

              <div className="mt-3">
                <p className="mb-0">Termination of Use</p>
                <p className="mb-0">
                  You agree that we may, in our sole discretion, terminate or
                  suspend your access to all or part of the site with or without
                  notice and for any reason, including, without limitation,
                  breach of these Terms of Use. Any suspected fraudulent,
                  abusive or illegal activity may be grounds for terminating
                  your relationship and may be referred to appropriate law
                  enforcement authorities.
                </p>
                <p className="mb-0">
                  Upon termination or suspension, regardless of the reasons
                  therefore, your right to use the services available on this
                  site immediately ceases, and you acknowledge and agree that we
                  may immediately deactivate or delete your account and all
                  related information and files in your account and/or bar any
                  further access to such files or this site. We shall not be
                  liable to you or any third party for any claims or damages
                  arising out of any termination or suspension or any other
                  actions taken by us in connection with such termination or
                  suspension.
                </p>
              </div>
              <div className="mt-3">
                <p className="mb-0">Warranties</p>
                <p className="mb-0">
                  The materials and all information on Copyandpasteemoji.com
                  website are provided "as is". Copyandpasteemoji.com makes no
                  warranties, expressed or implied, and hereby disclaims and
                  negates all other warranties, including without limitation,
                  implied warranties or conditions of merchantability, fitness
                  for a particular purpose, or non-infringement of intellectual
                  property or other violation of rights.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  ) : (
    <></>
  );
};

export default Termsofservice;

import React, { Component, useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  IResolveParams,
} from "reactjs-social-login";
import PhoneInput from 'react-phone-input-2';
import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  AmazonLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
  MicrosoftLoginButton,
  TwitterLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";
import emailLogo from "../../../Assets/images/email_logo.png";
import lockLogo from "../../../Assets/images/lock_logo.png";

import phoneLogo from "../../../Assets/images/phone-logo.png";
import countryLogo from "../../../Assets/images/country-logo.png";
import FacebookLogin from "react-facebook-login";
// import TwitterLogin from "react-twitter-login";
import InputGroup from "react-bootstrap/InputGroup";
import { Modal, Button, Form, Tab, Row, Col, Nav } from "react-bootstrap";

import ConfirmLog from "../Modals/ConfirmLog";

import facebook from "../../../Assets/images/facebook.png";

import WelcomeModal from "./WelcomeModal";
import ForgotModal from "./ForgotModal";
import countrycode from "../../../config/countrycode.json";
import Debounce from "lodash.debounce";
import {
  userSignUp,
  userCheck,
  LoginUser,
  loginUserFacebook,
  loginUserGoogle,
  GetEmail,
} from "../../../Hooks/useUserHook";
import { Loginusercheck } from "../../../Hooks/useUserHook";
import FinishSign from "./FinishSign";
import { toast, Toaster } from "react-hot-toast";
import { toastOptions } from "../../../config/env";

const Login = (props) => {
  const [showlog, setShowLog] = useState(true);
  console.log("showlog", showlog);
  const [showconfirmlog, setShowConfirmLog] = useState(false);
  console.log("showconfirmlog", showconfirmlog);
  const [showwelcome, setShowWelcome] = useState(false); //welcome
  console.log("showwelcome", showwelcome);
  const [email, setEmail] = useState("");
  console.log("email", email);
  const [countryCode, setCountryCode] = useState("Afghanistan(+93)");
  console.log("countrycode", countryCode);
  const [phoneNumber, setPhoneNumber] = useState("");
  console.log("phoneNumber", phoneNumber);
  const [emailErr, setEmailErr] = useState(false);
  const [pwdErr, setPwdErr] = useState(false);

  const [phoneErr, setPhoneErr] = useState(false);
  const [data, setData] = useState({});
  const [login, setLogin] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [showfinishsign, setShowFinishSign] = useState(false);
  const [googlelogin, setgooglelogin] = useState(false);
  const [fblogin, setfblogin] = useState(false);
  const [isSignup, setIsSignup] = useState(true);
  console.log("isSignuptrue", isSignup);
  const [debounces, setDebounce] = useState("");
  const [disable, setDisabled] = useState(false);
  const [password, setPassword] = useState("");

  const [phonecode, setPhonecode] = useState({});

  const [defaultkey, setDetaultkey] = useState("signin");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordlogin, setShowPasswordLogin] = useState(false);
  const [passworderrortxt, setPassworderrortxt] = useState("Invalid Email!");

  const [logpassword, setLogpassword] = useState("");
  const [logemailerr, setlogemailerr] = useState("");
  const [logpassworderror, setLogpassworderror] = useState("");
  const [passrejx, setPassrejx] = useState(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/);
  const [showforgot, setShowForgot] = useState(false);

  const [otptype , setOtptype] = useState("email");
  const [pherr , setPherr] = useState("");
  const [cshowPassword , setCshowPassword] = useState(false);
  const [cpassword , setCpassword] = useState("");
  const [cpwdErr , setCpwdErr] = useState("");
  const [cpassworderrortxt , setCpassworderrortxt] = useState("");


  console.log("disableeed", disable);
  console.log("debouncesd", debounces);

  const modals = () => {
    // document.getElementById("fade").classList.add("d-none")
    setShowLog(false);
    // props?.onDismiss()
    setShowConfirmLog(true);
  };

  useEffect(() => {
    getEmaildata();
  }, [debounces]);
  useEffect(() => {
    console.log("props", props , props?.logs == "login");
    if (props?.logs == "login") {
      console.log("props" , true);
      setDetaultkey("login")
      setTimeout(() => {
        setIsSignup(false);
      }, 100);
      // setDetaultkey("login")
    }
    else {
      setIsSignup(true);
      setDetaultkey("signin")
    }
  }, [])//props , props?.logs
console.log("props" , defaultkey);
  const getEmaildata = async () => {
    console.log("enter this one", debounces);
    const data = await GetEmail(debounces);
    console.log(
      "datasguuuuuuuuuuuu",
      data,
      data?.data?.email,
      data?.data?.Active
    );
    console.log(
      "data.data.email",
      data?.data?.email,
      data?.data?.email && isSignup && data?.data?.Active
    );
    if (data?.data?.email && isSignup && data?.data?.Active) {
      toast.error("Email  Already Exist!", toastOptions);
      console.log("enter now ");
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const getGoogleUserDetails = async (token) => {
    console.log("google access_token", token);

    setgooglelogin(false);
    setfblogin(false);

    var data = await getGoogleAccountDetails(token);
    console.log("google user", data);
    if (data) {
      setEmail(data.email);
      var googledata = {
        firstName: data.given_name,
        lastName: data.family_name,
        appId: data.id,
        email: data.email,
        type: "google",
      };

      await loginUserGoogle(googledata).then((data) => {
        console.log("login user google response from db:", data);
        if (data.status == true) {
          setLogin(false);
          props.onDismiss();
        } else {
          setShowLog(false);
          setShowFinishSign(true);
        }
      });
      // setShowLog(false);
    } else return toast.error("oops ! cannot log in .", toastOptions);
  };

  const getGoogleAccountDetails = async (token) => {
    console.log("axios access_token", token);
    try {
      var resp = await axios({
        method: "get",
        url: `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`,
      });

      console.log("reps google", resp);
      return resp.data;
    } catch (err) {
      console.log("error", err);
    }
  };

  const debouncedSave = useCallback(
    Debounce((values) => setDebounce(values), 300),
    []
  );

  const handleChange = (e) => {
    if (e.currentTarget.id === "email") {
      setEmailErr(false);
      const values = e.currentTarget.value;
      setEmail(e.currentTarget.value);
      debouncedSave(values);
    }
    if (e.currentTarget.id === "countrycode") {
      setCountryCode(e.currentTarget.value);
    }

    if (e.currentTarget.id == "phone") {
      let regex = /^[0-9]+$/;
      console.log("e.currentTaragel", e.currentTarget.value.length);
      if (e.currentTarget.value == "" || regex.test(e.currentTarget.value)) {
        if (e.currentTarget.value.length <= 10) {
          setPhoneNumber(e.currentTarget.value);
          setPhoneErr(false);
        }
      }
    }
  };

  const userSignUpFunction = async () => {
    // debugger
    var passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/;
    const valid = emailValidation();
    console.log(
      "valid email :",
      valid,
      "isSignup",
      isSignup,
      "phoneNumber",
      phoneNumber
    );
    if (isSignup && !password) {
      setPwdErr(true);
      setPassworderrortxt("Password field is required!")
    }
    else if (isSignup && !cpassword) {
      setCpwdErr(true);
      setCpassworderrortxt("ConfirmPassword field is required!")
    }
    else if (isSignup && !(passwordRegex.test(password))) {
      setCpwdErr(true)
      setCpassworderrortxt("CONFIRMPASSWORD MUST HAVE ONE SPECIAL CHARACTER ONE NUMERIC AND ONE UPPERCASE!")
    }
    else if (isSignup && !(passwordRegex.test(password))) {
      setPwdErr(true)
      setPassworderrortxt("PASSWORD MUST HAVE ONE SPECIAL CHARACTER ONE NUMERIC AND ONE UPPERCASE!")
    }
    else if(password != cpassword){
      setCpwdErr(true)
      setCpassworderrortxt("Confirm password don't match the password!")
    }
    else if(isSignup && (!phonecode || !phoneNumber)){
      setPherr("Mobile number field is required")
    }
    else {
      if (isSignup) {
        console.log("enter tghus", email);
        const datas = await Loginusercheck(email);
        console.log(
          "datassssssssss_Loginusercheck",
          datas,
          !data.status && data?.userdata?.Active == undefined
        );

        if (!data.status || datas.status == false) {
          console.log("enasdklfa;sdjf");
          if (valid) {
            let data = {};
            data.countrycode = countryCode;
            data.email = email;
            data.phoneNumber = phoneNumber;
            data.type = "email";
            data.password = password;
            data.otptype = otptype;
            data.phoneCode = phonecode;
            data.phoneNo = phoneNumber;
            await userCheck(email).then(async (val) => {
              setLoginType("email");
              console.log(
                "val:",
                val.status,
                val?.record?.Active,
                "email",
                email
              );
              
              if (val.status) {
                console.log("dddddddd 111");
                toast.error("Email already exist!", toastOptions)
                // modals();
              } else {
                console.log("data for signup user :", data);
                await userSignUp(data).then((val) => {
                  if(val?.success == false){
                  setPherr(val?.errors?.phoneNo);
                  return;
                }
                  if (val.status == true || val?.success == true) {
                    console.log("zxcvzxc 111")
                    modals();
                  } else {
                    if (val?.userdata?.Active != false) {
                      toast.error(val.message);
                    } else {
                      toast.error("First Signup please", toastOptions);
                    }
                  }
                });
              }
            });
          } else {
            // setEmailErr(true);
          }
        } else {
          toast.error("First Signup please", toastOptions);
        }
      } else {
        if (!email) {
          setlogemailerr("Email field is required!")
          if (!emailValidation(email)) {
            setlogemailerr("Invalid Emal!")
          }
        }
        if (!logpassword) {
          setPwdErr(true)
          setLogpassworderror("Password field is required!")
        }
        else {
          console.log("kdfhasjdfajhksdfbhasjkdf");
          let data = {};
          data.countrycode = countryCode;
          data.email = email;
          data.phoneNumber = phoneNumber;
          data.type = "email";
          data.password = logpassword;
          console.log("dadasdfasdfas", data);

          // userCheck
          await LoginUser(data).then(async (val) => {
            // debugger
            setLoginType("email");
            console.log("val:", val, "email", email);
            if (val?.message?.status == "Active" && val?.message?.email) { //&& val?.record?.Active != false
              console.log("asdfasd 111")
              // modals();
              toast.success("Login Successfully", toastOptions);
              setShowLog(false);
              setTimeout(() => {
                window.location.reload();
              }, (500));
            } else
              // if(){
              //   toast.error("User deactivate contact support team!");
              // }
              if (!val?.message?.email) {
                // console.log("data for signup user :", data);
                // await userSignUp(data).then((val) => {
                //   if (val.status == true) {
                //     modals();
                //   } else {
                //     toast.error(val.message);
                //   }

                // })
                toast.error(val?.message, toastOptions);
              }
          });

        }
      }
    }
  };

  const authHandler = (err, data) => {
    console.log(err, data);
  };

  const emailValidation = () => {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    const validated = emailRegex.test(email);
    console.log("validated datas", validated);
    return validated;
  };

  const responseFacebook = async (response) => {
    response.type = "facebook";
    setLoginType("facebook");
    setEmail(response.email);
    console.log(">>>>>>>>>>", response);
    setData(response);
    if (response.accessToken) {
      await loginUserFacebook(response).then((data) => {
        console.log(
          "login user facebook response from db:",
          data,
          "status from db",
          data.status
        );
        if (data.status == true) {
          console.log("inside status true");
          setLogin(false);
        } else {
          console.log("inside status false");
          setShowFinishSign(true);
        }
      });
      //   setShowLog(false);
      console.log("outside cond");
      setLogin(true);
      props.onDismiss();
    } else {
      setLogin(false);
    }
  };

  const handlePhoneNumber = (value, country) => {console.log("phoneeeeeeeee" , value , country);
    const { dialCode } = country;
    let newPhoneNo = value;
    console.log('handlpeph', country?.name,country,country?.dialCode,`${country?.name}(+${country?.dialCode})`)
    if (dialCode) {
      setPhonecode(dialCode)
      setCountryCode(`${country?.name}(+${country?.dialCode})`)
      setPhoneNumber(newPhoneNo.slice(dialCode.length))
    } else if (value) {
      setPhoneNumber(value)
    }
}

  return (
    <div>
      <Modal
        show={showlog}
        centered
        scrollable
        className="modal_style"
        id="fade"
      >
        {/* <Button className='btn w-full' onClick={() =>setShowWelcome(true) } variant="outline-dark">Continue</Button> */}

        <Modal.Header className="border-0 pb-0 d-flex justify-content-end">
          <button
            type="button"
            className="btn close close_modal"
            onClick={() => props.onDismiss()}
          >
            <span aria-hidden="true">×</span>
            {/* <span class="sr-only">Close</span> */}
          </button>
        </Modal.Header>

        <Modal.Body>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={props?.logs == "login" ? "login" : "signin"}  //defaultkey
            className=""
          >
            <Row className="d-flex align-items-center justify-content-between flex-column loginsign">
              <Col className="mx-auto bg-white mb-3">
                <Nav variant="pills" className="d-flex justify-content-between login-page-sign-up-btn">
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setIsSignup(true);
                      }}
                      eventKey="signin"
                    >
                      Signup
                      <span />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setIsSignup(false);
                      }}
                      eventKey="login"
                    >
                      Login
                      <span />{" "}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col className="mx-auto mt-3">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="signin"
                    className="welcome-to-flutterpads"
                  >
                    <h6 className="mb-3">
                      Welcome to <span className="pro-title">FlutterPads</span>
                    </h6>
                    <div className="loginmodal">
                      <div className="loginform">
                        <div
                          className="country-phone-field"
                          style={{ gap: "10px" }}
                        >

                          {/* <div className="row">
                            <div className="col-12 col-md-6">
                            <InputGroup className="mb-3 country-sel">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={countryLogo}
                                alt="country"
                                className="input-logos"
                              />
                            </InputGroup.Text>
                            <div className="select withlab custom-country-select">
                              <p>Current Region</p>
                              <Form.Select
                                aria-label="Default select example"
                                id="countrycode"
                                value={
                                  console.log(
                                    "countrycode state:",
                                    countryCode
                                  ) && countryCode
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              >
                                {countrycode.map((result) => (
                                  <option>{`${result.name}(${
                                    result.dial_code
                                  })`}</option>
                                ))}
                              </Form.Select>
                            </div>
                          </InputGroup>
                            </div>

                            <div className="col-12 col-md-6">
                            <div className="error_dov">

<InputGroup className="mb-3 phone-no-input">
  <InputGroup.Text id="basic-addon1">
    <img
      src={phoneLogo}
      alt="country"
      className="input-logos"
    />
  </InputGroup.Text>

  <Form.Control
    placeholder="Phone number"
    type="text"
    value={phoneNumber}
    aria-label="Username"
    id="phone"
    onChange={(e) => {
      handleChange(e);
    }}
  />
</InputGroup>
{phoneErr ? (
  <Form.Text className="text-danger">
    Invalid phone number
  </Form.Text>
) : (
  <></>
)}
</div>
                              </div>
                          </div> */}


                        </div>

                        {/* <Form.Control placeholder="Phone number" aria-label="Username" value="phonenumber" onChange={(e)=>{handleChange(e)}} /> */}
                        <div className="mt-0">
                          <InputGroup className="mb-3 email-text-field">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={emailLogo}
                                alt="country"
                                className="input-logos"
                              />
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Email"
                              className=""
                              aria-label="Username"
                              id="email"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              autoComplete="new-password"
                            />
                          </InputGroup>

                          {emailErr ? (
                            <Form.Text className="text-danger">
                              Invalid email address
                            </Form.Text>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="mt-0 react_tele">
                          <PhoneInput
                            country={'us'}
                            value={phonecode + phoneNumber}
                          onChange={handlePhoneNumber}
                          />

  <Form.Text className="text-danger">
    {pherr}
  </Form.Text>

                        </div>


                        <div className="mt-3">
                          <InputGroup className="mb-3 email-text-field">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={lockLogo}
                                alt="password"
                                className="input-logos"
                              />
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Password"
                              className=""
                              aria-label="Username"
                              id="password"
                              type={showPassword ? "text" : "password"}
                              onChange={(e) => setPassword(e?.target?.value)}
                              autoComplete="new-password"
                            />
                            <InputGroup.Text id="basic-addon1" className="cursor_eye" onClick={() => { setShowPassword(!showPassword) }}>
                              <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} />
                            </InputGroup.Text>
                          </InputGroup>

                          {pwdErr ? (
                            <Form.Text className="text-danger">
                              {passworderrortxt}
                            </Form.Text>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="mt-3">
                          <InputGroup className="mb-3 email-text-field">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={lockLogo}
                                alt="password"
                                className="input-logos"
                              />
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="confirm password"
                              className=""
                              aria-label="Username"
                              id="password"
                              type={cshowPassword ? "text" : "password"}
                              onChange={(e) => setCpassword(e?.target?.value)}
                              autoComplete="new-password"
                            />
                            <InputGroup.Text id="basic-addon1" className="cursor_eye" onClick={() => { setCshowPassword(!cshowPassword) }}>
                              <i className={cshowPassword ? "fa fa-eye" : "fa fa-eye-slash"} />
                            </InputGroup.Text>
                          </InputGroup>

                          {cpwdErr ? (
                            <Form.Text className="text-danger">
                              {cpassworderrortxt}
                            </Form.Text>
                          ) : (
                            <></>
                          )}
                        </div>
                        <p>Send verification code to:</p>
                        <div className="logincheckbox mt-2 d-flex">

                          <div class="form-check">
                            <input class="form-check-input" 
                            onChange={(e) => {setOtptype(e?.target?.value); console.log("radio" ,e.target?.value);}}
                            type="radio" name="otp" id="flexRadioDefault1" value={"email"}
                            checked = {otptype == "email" ? true : false}
                            />
                            <label class="form-check-label" for="flexRadioDefault1">
                              Email
                            </label>
                          </div>
                          <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="otp" 
                            value={"mobile"}
                            checked = {otptype == "mobile" ? true : false}
                            onChange={(e) => {setOtptype(e?.target?.value); console.log("radio" , e.target?.value);}}
                            id="flexRadioDefault2" />
                            <label class="form-check-label" for="flexRadioDefault2">
                              Phone Number  </label>
                          </div>
                        </div>


                      </div>




                      <p className="login-privacy-policy mt-2">
                        {/* We’ll send otp you to confirm your email. Standard
                        message and data rates apply.{" "} */}
                        We’ll send a verification code to you to confirm your email. Standard message and data
rates apply.
                        <a href="/privacy-policy" target="_blank" className="orsnge_y"> Privacy Policy</a>
                      </p>

                      <div className="d-flex justify-content-center">
                        <Button
                          className="btn btn-theme w-full mt-4 sign-up-custom-btn"
                          // disabled={disable}
                          disabled={!emailValidation(email) || !(passrejx.test(password)) || !phoneNumber || !phonecode}
                          onClick={() => {
                            userSignUpFunction();
                          }}
                          variant="outline-dark"
                        >
                          Sign up
                        </Button>
                      </div>

                      <div
                        className="or-login-with position-relative"
                        style={{ margin: "20px 0p 20px 0px" }}
                      >
                        <span> or Login with </span>
                        <hr />
                      </div>


                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="login" className="login">
                    <h6 className="mb-3 header-title">
                      Welcome to{" "}
                      <span className="pro-title flutter-pads-title">
                        FlutterPads
                      </span>
                    </h6>
                    <div className="loginmodal">
                      <div className="loginform">
                        {/* <div className='select withlab'>
                            <p>Current Region</p>
                            <Form.Select aria-label="Default select example" id="countrycode" value={console.log("countrycode state:",countryCode)&&countryCode} onChange={(e)=>{handleChange(e)}}>
                            {countrycode.map((result)=><option>{`${result.name}(${result.dial_code})`}</option>)}
                            </Form.Select>
                        </div>  */}

                        {/* <Form.Control placeholder="Phone number" type="Number" aria-label="Username" id="phone" onChange={(e)=>{handleChange(e)}} />
                        {(phoneErr)?<Form.Text className='text-danger'>Invalid phone number</Form.Text>:<></>} */}
                        {/* <Form.Control placeholder="Phone number" aria-label="Username" value="phonenumber" onChange={(e)=>{handleChange(e)}} /> */}
                        <InputGroup className="mb-3 email-text-field">
                          <InputGroup.Text id="basic-addon1">
                            <img
                              src={emailLogo}
                              alt="email"
                              className="input-logos"
                            />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Email"
                            aria-label="Username"
                            id="email"
                            onChange={(e) => {
                              if (e.target.value.length <= 0) {
                                setlogemailerr("Email field is required!")
                              }
                              handleChange(e);
                            }}
                          />
                        </InputGroup>
                        {emailErr ? (
                          <Form.Text className="text-danger">
                            {logemailerr}
                          </Form.Text>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mt-3">
                        <InputGroup className="mb-3 email-text-field">
                          <InputGroup.Text id="basic-addon1">
                            <img
                              src={lockLogo}
                              alt="password"
                              className="input-logos"
                            />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Password"
                            className=""
                            aria-label="Username"
                            id="password"
                            value={logpassword}
                            type={showPasswordlogin ? "text" : "password"}
                            onChange={(e) => setLogpassword(e?.target?.value)}
                          />
                          <InputGroup.Text id="basic-addon1" className="cursor_eye" onClick={() => { setShowPasswordLogin(!showPasswordlogin) }}>
                            <i className={showPasswordlogin ? "fa fa-eye" : "fa fa-eye-slash"} />
                          </InputGroup.Text>
                        </InputGroup>

                        {pwdErr ? (
                          <Form.Text className="text-danger">
                            {logpassworderror}
                          </Form.Text>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="text-right">
                        <button type="button" className="orsnge_y fotgoe_pwd_lonk_btn" onClick={() => { setShowLog(false); setShowForgot(true) }}>Forgot Password?</button>
                      </div>
                      <p className="mt-2 login-privacy-policy">
                        We’ll send otp you to confirm your email. Standard
                        message and data rates apply.{" "}
                        <a href="/privacy-policy" className="orsnge_y"> Privacy Policy</a>
                      </p>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button
                          className="btn btn-theme w-full mt-4 login-continue-btn"
                          disabled={!emailValidation(email) || !logpassword}
                          onClick={() => {
                            console.log("clicking");
                            userSignUpFunction();
                          }}
                          variant="outline-dark"
                        >
                          Continue
                        </Button>
                      </div>
                      <div className="or my-4">
                        <span> or</span>
                        <hr />
                      </div>


                    </div>
                  </Tab.Pane>
                </Tab.Content>
                <div className="continue-social">
                  {!login && (
                    <FacebookLogin
                      className="bnt btnbord w-full"
                      variant="outline-dark"
                      appId="637697877860809"
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile, email, user_birthday"
                      callback={responseFacebook}
                      icon="fa-facebook"
                    />
                  )}
                  {/* <LoginSocialGoogle className='googlelogin'
                          isOnlyGetToken
                          //client_id={"129309824589-4mg5vvcir0lhrcica7lp1obl8kek3vr9.apps.googleusercontent.com"}
                          client_id={"954751120857-4l36ulfgjlad83tqqr4qkp7sd67n585g.apps.googleusercontent.com"}

                          // onLoginStart={onLoginStart}
                          scope="openid profile email"
                          onResolve={({ provider, data }) => {
                            console.log("token detail ", data)
                            if (data?.access_token) {
                              getGoogleUserDetails(data.access_token)
                            }
                          }}
                          onReject={(err) => {
                            console.log(err)
                            setgooglelogin(false);
                            return toast.error("oops ! cannot sign in.", toastOptions)
                          }}
                        >
                        <GoogleLoginButton />
                        </LoginSocialGoogle> */}

                  <LoginSocialGoogle
                    className="googlelogin"
                    isOnlyGetToken
                    //client_id={"129309824589-4mg5vvcir0lhrcica7lp1obl8kek3vr9.apps.googleusercontent.com"}
                    client_id={
                      "954751120857-4l36ulfgjlad83tqqr4qkp7sd67n585g.apps.googleusercontent.com"
                    }
                    // onLoginStart={onLoginStart}
                    scope="openid profile email"
                    onResolve={({ provider, data }) => {
                      console.log("token detail ", data);
                      if (data?.access_token) {
                        getGoogleUserDetails(data.access_token);
                      }
                    }}
                    onReject={(err) => {
                      console.log(err);
                      setgooglelogin(false);
                      return toast.error(
                        "oops ! cannot sign in.",
                        toastOptions
                      );
                    }}
                  >
                    <GoogleLoginButton />
                  </LoginSocialGoogle>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
      <Toaster />

      {showconfirmlog && (
        <ConfirmLog
          email={email}
          type={loginType}
          password={password}
          otptype = {otptype}
          phoneCode = {phonecode}
          phoneNo = {phoneNumber}
          onDismiss={() => setShowConfirmLog(false)}
        />
      )}
      {showfinishsign && (
        <FinishSign
          email={email}
          type={loginType}
          onDismiss={() => setShowFinishSign(false)}
        />
      )}

      {showwelcome && <WelcomeModal onDismiss={() => setShowWelcome(false)} />}

      {showforgot && (
        <ForgotModal

          onDismiss={() => { setShowForgot(false); setShowLog(true) }}
        />
      )}
    </div>
  );
};

export default Login;

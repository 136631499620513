import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

import { Link, useNavigate } from "react-router-dom";
import userIcon from "../../Assets/images/account-user-cion.png";
import basicplan from "../../Assets/images/basicplan.png"
import premiumplan from "../../Assets/images/premiumplan.png"
import ultraplan from "../../Assets/images/ultraplan.png"

import GuestSubscribeModal from "./GuestSubscribeModal";
import { Getguestusersubscrptionhooks } from "../../Hooks/useUserHook";
const GuestSubscribe = (props) => {
 
    const[guestsubscribe , setGuestsubscribe] = useState(false);
    const [subscrptions , setSubscriptions] = useState([]);
    const [singlesubscrpition , setSinglesubscription] = useState({});


    const fetchdata = ()=>{
       Getguestusersubscrptionhooks().then((data) => {
        console.log("data in host subscription" , data?.data?.record);
        setSubscriptions(data?.data?.record);
       })
    }
    useState(() => {
      fetchdata();
    } , [])



  return (
    <div id="hostaccount" className="nocenterhead account-details">
      <Header />
 
      {guestsubscribe && (
        <GuestSubscribeModal
          onDismiss={() => setGuestsubscribe(false)}
          sub = {singlesubscrpition}
          stripe = {props?.stripe}
        />
      )}
      <div className="container container_custom mt_new_aho_a">
        <div className="row">
          <div className="col-xl-12">
            <h3 className=" mt-lg-5 account">Guset Subscription Plans</h3>
         <div className="row pb-5  plansrow_sub justify-content-center">
         <div className="col-12 col-md-6 col-xl-4 mt-lg-5 mt-4 d-flex justify-content-center align-items-center">
              {/* <Link to="/account/personal-info" data = {userDatas}> */}
              {subscrptions?.map((data , i) => 
              <div className="acc-box accox_sm d-flex flex-column p-4 align-items-center justify-content-center">
              {/* <span className="fa fa-user-o" /> */}
              <img
                src={data?.name?.toLowerCase() == "basic" ? basicplan : data?.name?.toLowerCase() == "premium" ? premiumplan : ultraplan}
                alt="user-icon"
                className="user-icon-img "
              />
              <h6 className="pt-2 font_pers mt-3">{data?.name}</h6>
              <p className="pt-2 text-center price_txt_new_sub">
                  ${data?.price}
              </p>
              <p className="pt-2 text-center price_tetx_sub">
                 {data?.days} days
              </p>
              <button
                className="mt-3 mt-xl-3 mt-lg-3"
                onClick={()=>{setSinglesubscription(data) ;setGuestsubscribe(true)}}
              >
                Subscribe
              </button>
            </div>)}
              {/* </Link> */}

          
            </div>

            {/* <div className="col-12 col-md-6 col-xl-4 mt-lg-5 mt-4 d-flex justify-content-center align-items-center">
              <div className="acc-box accox_sm  d-flex flex-column p-4 align-items-center justify-content-center">
                <img
                  src={premiumplan}
                  alt="user-icon"
                  className="user-icon-img "
                />
                <h6 className="pt-2 font_pers mt-3">Premium</h6>
                <p className="pt-2 text-center price_txt_new_sub">
                    $100
                </p>
                <p className="pt-2 text-center price_tetx_sub">
                   90 days
                </p>
                <button
                  className="mt-3 mt-xl-3 mt-lg-3"
                  onClick={()=>{setGuestsubscribe(true)}}
                
                >
                  Subscribe
                </button>
              </div>

          
            </div>

            <div className="col-12 col-md-6 col-xl-4 mt-lg-5 mt-4 d-flex justify-content-center align-items-center">
              <div className="acc-box accox_sm d-flex flex-column p-4 align-items-center justify-content-center">
                <img
                  src={ultraplan}
                  alt="user-icon"
                  className="user-icon-img "
                />
                <h6 className="pt-2 font_pers mt-3">Ultra</h6>
                <p className="pt-2 text-center price_txt_new_sub">
                    $20
                </p>
                <p className="pt-2 text-center price_tetx_sub">
                   150 days
                </p>
                <button
                  className="mt-3 mt-xl-3 mt-lg-3"
                  onClick={()=>{setGuestsubscribe(true)}}

                
                >
                  Subscribe
                </button>
              </div>

          
            </div> */}
         </div>
           

         
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GuestSubscribe;

import React, { useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  Form,
  Dropdown,
  Toast,
  TabContainer,
  Row,
  Col,
  Tab,
  Nav,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import HostHeader from "../../HostHeader";
import img1 from "../../../../../Assets/images/where/img1.png";
import {
  getHostingDetails,
  getUserRoomListings,
  listSetting,
} from "../../../../../Hooks/useUserHostingHook";
import { API_URL, toastOptions } from "../../../../../config/env";
import { toast, Toaster } from "react-hot-toast";
import { getUserExperienceHostings } from "../../../../../Hooks/useUserExperienceHook";
import ListingModal from "./Modals/ListingModal";
import { GrNext, GrPrevious } from "react-icons/gr";
import HeaderDash from "../../../../Common/HeaderDash";

const Listings = () => {
  const location = useLocation();
  console.log("locationnnnnnnnnnnnnnn", location.state);
  const [userRoomListings, setUserRoomListings] = useState([]);
  const [userExperienceListings, setUserExperienceListings] = useState([]);
  const [email, setEmail] = useState("");
  const [reload, setReload] = useState(false);
  const [key, setKey] = useState("");
  const [hostDatas, setHostDatas] = useState([]);
  const [roomdata, setRoomData] = useState({});
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  console.log("key", key);
  const fetchdata = () => {
    const emailid = window.location.pathname.split("/")[3];
    const room = window.location.pathname.split("/")[4];
    console.log("rooommmmmmmmmmmmmm", room, emailid);
    if (room == "experiences") {
      console.log("rooommmmmmmmmmmmmm experiences");
      setKey("experience");
    }
    if (room == "stays") {
      console.log("rooommmmmmmmmmmmmm stays");
      setKey("stays");
    }
    if (!room) {
      setKey("stays");
    }
    setEmail(emailid);
    getUserRoomListings(emailid).then((data) => {
      setUserRoomListings(data);
    });
    getUserExperienceHostings(emailid).then((data) => {
      setUserExperienceListings(data);
    });
  };

  console.log("hostdatasgal", hostDatas);
  useEffect(() => {
    fetchdata();
  }, [reload, !show, show]);

  const images1 = <img src={img1} />;
  const check = <span className="fa fa-check-circle text-success me-1" />;
  const dots = <span className="fa fa-circle text-success me-1" />;
  const hourglass = <span className="fa fa-hourglass text-secondary me-1" />;

  const listsetting = (id, islisted, type) => {
    listSetting(id, type, !islisted).then(async (data) => {
      console.log("toast message :", data.message);
      await toast.success(data.message, toastOptions);
      setReload(true);
      fetchdata();
    });
  };

  const columns = [
    {
      name: "Listing",
      sortable: true,
      reorder: false,

      cell: (data) => (
        <>
          <Link
            to={`/roomedit/${data?._id}`}
            className="d-flex text-black nounder align-items-center"
          >
            {/* <img src={API_URL + data.photosPath + "/" + data.photos[0].name} /> */}
            <p>{data.title}</p>
          </Link>
        </>
      ),
    },
    {
      name: "status",
      reorder: false,
      cell: (data) => (
        <>
          {
            <span
            // className={
            //   data.isApproved
            //     ? data.isListed
            //       ? "fa fa-check-circle text-success me-1"
            //       : "fa fa-circle text-danger me-1"
            //     : data.isRejected
            //     ? "fa fa-times-circle-o text-danger me-1"
            //     : "fa fa-circle text-success me-1"
            // }
            />
          }{" "}
          {data.isApproved
            ? data.isListed
              ? "Listed"
              : "Unlisted"
            : data.isRejected
            ? "Rejected"
            : "Pending"}{" "}
        </>
      ),
    },
    {
      name: "List/Unlist",
      button: true,
      reorder: false,

      cell: (data) => (
        <button
          type="button"
          class="btn nowrap cus-btn-link"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          onClick={() => {
            listsetting(data?._id, data?.isListed, "room");
          }}
        >
          {data?.isListed ? "Unlist" : "List"}
        </button>
      ),
    },
    {
      name: "Instant Booking",
      // selector: (data) => data.instantbook,
      cell: (data) => (
        <div className="text-center">
          {
            <span
            // className={
            //   data.instantBooking
            //     ? "fa fa-check-circle text-success me-1"
            //     : "fa fa-times-circle-o text-danger me-1"
            // }
            />
          }
          <span>{data.instantBooking ? "Yes" : "No"}</span>
        </div>
      ),
    },
    {
      name: "Price",
      reorder: false,

      selector: (data) => "$" + data.price,
    },
    // {
    //     name:"beds",
    //     selector: (data) => data.beds
    // },
    // {
    //     name:"baths",
    //     selector: (data) => data.baths
    // },
    {
      name: "Location",
      reorder: false,

      selector: (data) =>
        data.location && data.location.city && data.location.countrycode
          ? data.location.city + "," + data.location.countrycode
          : "",
    },
    {
      name: "Action",
      reorder: false,

      cell: (data) => (
        <span
          className="list_detail_edit"
          onClick={() => {
            handleShow();
            setRoomData(data);
          }}
        >
          <i className="fa fa-edit" />
        </span>
      ),
    },
  ];

  const expcolumns = [
    {
      name: "Listing",
      sortable: true,
      reorder: false,
      cell: (data) => (
        <>
          <Link
            to={`/experience-edit/${data?._id}`}
            className="d-flex text-dark nounder align-items-center"
          >
            <img src={API_URL + data.photosPath + "/" + data.photos[0].name} />
            <p>
              <b>{data.title}</b>
            </p>
          </Link>
        </>
      ),
    },
    {
      name: "status",
      reorder: false,

      cell: (data) => (
        <>
          {
            <span
              className={
                data.isApproved
                  ? data.isListed
                    ? "fa fa-check-circle text-success me-1"
                    : "fa fa-circle text-danger me-1"
                  : data.isRejected
                  ? "fa fa-times-circle-o text-danger me-1"
                  : "fa fa-circle text-success me-1"
              }
            />
          }{" "}
          {data.isApproved
            ? data.isListed
              ? "Listed"
              : "Unlisted"
            : data.isRejected
            ? "Rejected"
            : "Pending"}{" "}
        </>
      ),
    },
    // {
    //     name:"what you will do",
    //     selector: (data) => data.whatYouWillDo
    // },
    {
      name: "Starting",
      reorder: false,

      selector: (data) => new Date(data.startTime).toLocaleDateString(),
    },
    {
      name: "Ending",
      reorder: false,

      selector: (data) => new Date(data.endTime).toLocaleDateString(),
    },
    {
      name: "List/Unlist",
      reorder: false,

      button: true,
      cell: (data) => (
        <button
          type="button"
          class="btn btn-theme nowrap"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          onClick={() => {
            listsetting(data._id, data.isListed, "experience");
          }}
        >
          {data.isListed ? "Unlist" : "List"}
        </button>
      ),
    },
    // {
    //     name:"Instant Booking",
    //     // selector: (data) => data.instantbook,
    //     cell:(data)=>(<>{<span className={data.instantBooking?'fa fa-check-circle text-success me-1':'fa fa-times-circle-o text-danger me-1'}></span>}{data.instantBooking?"Yes":"No"}
    //     </>)
    // },
    {
      name: "Price per person",
      reorder: false,

      selector: (data) => "$" + data.pricePerGuest,
    },
    // {
    //     name:"beds",
    //     selector: (data) => data.beds
    // },
    // {
    //     name:"baths",
    //     selector: (data) => data.baths
    // },
    {
      name: "Where you will be",
      reorder: false,
      selector: (data) => (data.whereYouWillBe ? data.whereYouWillBe : ""),
    },
    // {
    //     name:"baths",
    //     selector: (data) => data.lastmodification
    // }
  ];

  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop3, setDrop3] = useState(false);
  const [drop4, setDrop4] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HostDetails = [
    {
      name: "Which of these best describes your place?",
    },
    // {
    //   name: "What type of place will guests have?",
    // },
    // {
    //   name: "What kind of space will guests have?",
    // },
    {
      name: "What does your place offer?",
    },
    // {
    //   name: "Where's your place located?",
    // },
    {
      name: "Share some basics about your place",
    },
    {
      name: "Do you have a cancellation policy?",
    },
    {
      name: "Any standout amenities?",
    },
    // {
    //   name: "Next, let's add some video & photos of your place",
    // },
    {
      name: "Let's give your place a name",
    },
    {
      name: "Does your place have any of these?",
    },
    {
      name: "Now, let's describe your place in detail",
    },
    // {
    //   name: "Set your price",
    // },
    // {
    //   name: "What category is best for your place?",
    // },
  ];

  const MblData = [
    {
      listing: "Listing Name",
      status: "Listed",
      listUnlist: "Unlist",
      booking: "No",
      price: "$1000",
      location: "California",
    },
    {
      listing: "Listing Name",
      status: "Listed",
      listUnlist: "Unlist",
      booking: "No",
      price: "$1000",
      location: "California",
    },
    {
      listing: "Listing Name",
      status: "Listed",
      listUnlist: "Unlist",
      booking: "No",
      price: "$1000",
      location: "California",
    },
  ];
  return (
    <div id="listing">
      <HeaderDash />
      <HostHeader />
      <div className="container my-4 ">
        <div className="listings cmn-table-webView cmn-table-shadow mt-4 mt-lg-5">
          {key && (
            <TabContainer
              id="left-tabs-example"
              defaultActiveKey={key ? key : "stays"}
            >
              <Row className="d-flex align-items-center justify-content-between flex-column">
                <Col className="mx-auto mt-4">
                  <Nav variant="pills" className="">
                    <Nav.Item>
                      <Nav.Link eventKey={"stays"} className="custom-nav-link">
                        {" "}
                        {/* Stays <span>({userRoomListings.length})</span>{" "} */}
                        <h4 className="mb-0">Lists</h4>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col className="mx-auto">
                  <Tab.Content>
                    <Tab.Pane eventKey="stays">
                      <div className="position-relative no-data-bdr-top">
                        <DataTable
                          className="stays_tabe cmn-data-table"
                          pagination
                          columns={columns}
                          data={userRoomListings}
                        />

                        <div className="showing-records">
                          Showing 3 of 3 records
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="experience">
                      <div className="position-relative no-data-bdr-top">
                        <DataTable
                          className="expr_tabe cmn-data-table"
                          pagination
                          columns={expcolumns}
                          data={userExperienceListings}
                        />
                        <div className="showing-records">
                          Showing 3 of 3 records
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </TabContainer>
          )}
        </div>
        <div className="cmn-table-mblView">
          <h2 className="table-heading mb-4 fw-600">Listings</h2>
          <div className="datas">
            <div className="row">
              {MblData.map((item, index) => {
                return (
                  <div className="col-12">
                    <div className="table-datas">
                      <div className="mb-2">
                        <span className="name">Listing: </span>
                        <span className="value">{item.listing}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Status: </span>
                        <span className="value">{item.status}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">List/Unlist :</span>
                        <span className="value">{item.listUnlist}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Instant Booking: </span>
                        <span className="value">{item.booking}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Price: </span>
                        <span className="value">{item.price}</span>
                      </div>
                      {/* <div className="mb-2">
                        <span className="name">Location: </span>
                        <span className="value">{item.location}</span>
                      </div> */}
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
            <div className="prev-next-btn">
              <Button className="">
                <GrPrevious />
              </Button>
              <span className="f-15 fw-400">Showing 3 of 3 records</span>
              <Button className="">
                <GrNext />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
      <ListingModal
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        HostDetails={HostDetails}
        roomdata={roomdata}
        onDismiss={() => {
          setShow(true);
        }}
      />
    </div>
  );
};

export default Listings;

import React, { useEffect, useState } from 'react';
import HostHeader from '../../HostHeader';
import prof from  '../../../../../Assets/images/prof.jpg'
import { getCancellationDetails } from '../../../../../Hooks/useUserHostingHook';
import { API_URL } from '../../../../../config/env';
import { useNavigate } from 'react-router-dom';


const Cancellation = () =>{
    const navigate = useNavigate();
    const [cancellationDetails, setCancellationDetails] = useState({});
    {console.log("cancellationDetails", cancellationDetails)}

    useEffect(()=>{
        const cancellationId = window.location.pathname.split("/")[4];
        console.log("window.location.pathname.split(/)",window.location.pathname.split("/"));
        getCancellationDetails(cancellationId).then((data)=>{
            console.log("data from getCancellationDetails :",data);
            setCancellationDetails(data[0]);
        })
    },[])

    return(
        <>
        <div className='trips tripcancel'>
        <HostHeader />
          <div className='container container_custom mh-100vh-header'>
             <div className='d-flex jc-between mt-5'> 
                 <h4 class="mb-sm-0 mb-3 themeclr">Cancellation</h4>
                 <button onClick={()=>{navigate(`/host-dashboard/trips/stay/${cancellationDetails.email}`)}} className='btn btn-theme'>Back</button>
            </div>
            <div className='row mt-4'>
                <div className='col-12 col-md-6 mb-4'>
                <div className='shadowbox p-3 h-100'>
                <div className='justify-content-around align-items-center'>
                    <div className=''>
                        <div className='d-flex align-items-center'>
                            <img src={(cancellationDetails&&cancellationDetails.userDetails&&cancellationDetails.userDetails.profilePicturePath)?API_URL+"/"+cancellationDetails.userDetails.profilePicturePath:prof}  className="prof"/>
                            <p className='name_cancl'>Hi, {cancellationDetails&&cancellationDetails.userDetails&&cancellationDetails.userDetails.firstName}&nbsp;{cancellationDetails&&cancellationDetails.userDetails&&cancellationDetails.userDetails.lastName}</p>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h6><p className='themeclr mb-3'>Room Title</p> <p className='text-muted mb-3'>{cancellationDetails&&cancellationDetails.bookingDetails&&cancellationDetails.bookingDetails.roomTitle}</p></h6>
                    </div>
                    <div className=''>
                    <h6><p className='themeclr mb-3'>Booking Id</p><p className='text-muted mb-3'>{(cancellationDetails&&cancellationDetails.bookingDetails&&cancellationDetails.bookingDetails._id&&((cancellationDetails.bookingDetails._id).toString()))}</p></h6>

                    </div>
                </div>
            </div>
                </div>
                <div className='col-12 col-md-6 mb-4'>
                <div className='shadowbox p-3 h-100'>
                <div className='justify-content-around'>
                    <div className='row mt-3'>
                        <h6 className='col-12 col-md-6'><p className='themeclr mb-3'>Check in date</p><p className='text-muted mb-3'>{cancellationDetails&&cancellationDetails.bookingDetails&&new Date(cancellationDetails.bookingDetails.checkInDate).toLocaleDateString()}</p></h6>
                        <h6 className='col-12 col-md-6'><p className='themeclr mb-3'>Check out date</p><p className='text-muted mb-3'>{cancellationDetails&&cancellationDetails.bookingDetails&&new Date(cancellationDetails.bookingDetails.checkOutDate).toLocaleDateString()}</p></h6>
                    </div>
                    <div className='row'>
                        <h6 className='col-12 col-md-6'><p className='themeclr mb-3'>Cancellation date</p><p className='text-muted mb-3'>{cancellationDetails&&new Date(cancellationDetails.createdAt).toLocaleDateString()}</p></h6>
                        <h6 className='col-12 col-md-6'><p className='themeclr mb-3'>Return amount</p><p className='text-muted mb-3'>${cancellationDetails&&(cancellationDetails.cancelledAmount)}</p></h6>
                    
                    </div>
                    <div className='row'>
                        {/* <h6 className='mb-3'><span className='themeclr'>Status</span>: <span className='success-box'>Recieved</span></h6> */}
                        <h6 className='col-12 col-md-6'><p className='themeclr mb-3'>Cancellation Policy</p><p className='info-box mb-3 info-box-max'>{cancellationDetails&&cancellationDetails.bookingDetails&&cancellationDetails.bookingDetails.cancellationPolicy}</p></h6>
                       
                        <h6 className='col-12 col-md-6'><p className='themeclr mb-3'>Status</p><p className='pending-box mb-3 info-box-max'>{cancellationDetails&&cancellationDetails.status}</p></h6>

                    </div>
                </div>
            </div>

                </div>
            </div>
          
            
          </div>
          </div>
          </>
    )
}

export default Cancellation
import React, { useEffect, useState } from "react";
import { Form, Accordion } from "react-bootstrap";
import Counter from "./counter";
import { useNavigate } from "react-router-dom";
import { FcApproval } from "react-icons/fc";
import { API_URL } from "../../config/env";
import { getUserEmail, getguestusersubsctiptionhooks } from "../../Hooks/useUserHook";

const PriceDetails = (props) => {
  console.log('propsdfasdfasdf', props)
  const [filter, setFilter] = useState("");
  const [rent, setRent] = useState("")
  const navigate = useNavigate();
  // console.log('props_PriceDetails', props?.filter)
  useEffect(() => {
    async function fetchdata () {

      if (getUserEmail()) {
        var guestpayload = {
          email: getUserEmail()
        }
        console.log('guestpayload_guestpayload', guestpayload, props?.userdetails?.guestsubscribedaycount)
        getguestusersubsctiptionhooks(guestpayload).then((data) => {
          console.log('saishnakraaaaaaa', props.roomDetails.price, data, props?.userdetails?.guestsubscribedaycount)
          if ((props?.userdetails?.guestsubscribedaycount > 0) && (data?.data?.record?.offerpercentage)) {
            console.log('props.roomDetails.price', props.roomDetails.price * parseFloat(data?.data?.record?.offerpercentage) / 100)
            let percent = ((props.roomDetails.price)) * parseFloat(data?.data?.record?.offerpercentage) /  100;
            let rent = props.roomDetails.price - percent;
            console.log("rent data rent", rent)
            setRent(rent)
          } else {
            setRent(props.roomDetails.price)
          }
        })
    }
    setFilter(props?.filter);
  }
    fetchdata()

  }, [props]);
  const navigatehosthome = () => {
    navigate("/confirmandpay");
  };
  return (
    <>
      {filter == "reservebooking" ? (
        <div className="personal-info-right-side-sec pricepernight jc-between gap-3 mb-4 align-items-center">
          <div className="bord p-4">
            <div className="d-flex pricepernight gap-3 mb-4">
              {console.log("props?.roomDetails?.title", props?.roomDetails?.title, props.bookingDetails, rent)}
              {props.roomDetails.photos ? (
                <img
                  src={`${API_URL}${props.roomDetails.photosPath}/${
                    props.roomDetails.photos[0].name
                  }`}
                />
              ) : (
                <img src={props.img} />
              )}
              <div>
                <p>Room Title : {props?.roomDetails?.title}</p>
                <p className="mb-0">
                  {" "}
                  <span>
                    <b>{props.bookingDetails.nights} &nbsp;</b>
                  </span>
                  <span>nights</span>
                </p>
                <p className="f-12">{props.txt}</p>
              </div>
            </div>

            <hr className="themehr" />
            <h5 className="mb-4">Price Details</h5>
            <div className="">
              <p className="d-flex jc-between f-14 mb-2">
                {/* //old one */}
                {/* <span>
                  ${rent} X {props.bookingDetails.nights}{" "}
                  Nights
                </span>
                <span>
                  $
                  {parseInt(rent) *
                    parseInt(props.bookingDetails.nights)}
                </span> */}

                {/* new one rent replaces props?.bookingDetails?.totalAmount - props?.bookingDetails?.serviceFeeAmount */}

<span>
                  ${props?.bookingDetails?.totalAmount - props?.bookingDetails?.serviceFeeAmount} X {props.bookingDetails.nights}{" "}
                  Nights
                </span>
                <span>
                  $
                  {parseInt(props?.bookingDetails?.totalAmount - props?.bookingDetails?.serviceFeeAmount) *
                    parseInt(props.bookingDetails.nights)}
                </span>
              </p>
              <p className="d-flex jc-between f-14">
                <span>Service fee</span>
                <span>${props.bookingDetails.serviceFeeAmount}</span>
              </p>
            </div>
            <hr />
            <p className="d-flex jc-between f-14 pb-4 fw-500">
              <span>Total fee</span>

              <span>${props.bookingDetails.totalAmount}</span>
            </p>
          </div>
        </div>
      ) : (
        filter == "splitreservebooking" && (
          <>
            {props?.splitbookingdetails &&
              props?.splitbookingdetails?.splitPaymentDetails?.length > 0 &&
              props?.splitbookingdetails?.splitPaymentDetails?.map(
                (val, ind) => (
                  <div className="personal-info-right-side-sec pricepernight jc-between gap-3 mb-4 align-items-center">
                    <div className="bord p-4">
                      <div className="d-flex pricepernight gap-3 mb-4">
                        {props.roomDetails.photos ? (
                          <img
                            src={`${API_URL}${props.roomDetails.photosPath}/${
                              props.roomDetails.photos[0].name
                            }`}
                          />
                        ) : (
                          <img src={props.img} />
                        )}
                        <div>
                          <p>Room Title : {props?.roomDetails?.title}</p>
                          <p>Nights : {val?.nights}</p>
                          <p className="f-12">{props.txt}</p>
                        </div>
                        <span className="room_status_icons">
                          {val?.status == "Completed" && <FcApproval size={25} />}
                        </span>
                      </div>

                      <hr className="themehr" />
                      <h5 className="mb-4">Price Details</h5>
                      <div className="">
                        <p className="d-flex jc-between f-14 mb-2">
                          <span>
                            $ {rent} X {val?.nights} Nights
                          </span>
                          <span>
                            $ 
                            {(Number(props?.bookingDetails?.totalAmount - props?.bookingDetails?.serviceFeeAmount) *
                              Number(val?.nights)).toFixed(1)}
                          </span>
                        </p>
                        <p className="d-flex jc-between f-14">
                          <span>Service fee</span>
                          <span>
                            ${val?.serviceFee}
                          </span>
                        </p>
                      </div>
                      <hr />
                      <p className="d-flex jc-between f-14 pb-4 fw-500">
                        <span>Total fee</span>

                        <span>
                          $
                          {((Number(props?.bookingDetails?.totalAmount - props?.bookingDetails?.serviceFeeAmount) *
                              Number(val?.nights)) + Number(val?.serviceFee)).toFixed(1)}
                        </span>
                      </p>
                    </div>
                  </div>
                )
              )}
          </>
        )
      )}
    </>
  );
};

export default PriceDetails;

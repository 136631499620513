
import React, { Component, useCallback, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';


import { API_URL } from '../../../config/env';
const EditRoomCategory = (props) => {
    console.log('roomcategory', props?.data)
    
    const [amenitiesList, setAmenitiesList] = useState(localStorage.getItem("Editcategory") ? 
                    JSON.parse(localStorage.getItem("Editcategory")) : []); 
    const [selectedIndex, setSelectedIndex] = useState(localStorage.getItem("EditroomCategoryIndex") 
    ? JSON.parse(localStorage.getItem("EditroomCategoryIndex")) : []);
    var index = [];
    var category = [];

 
    const setData = async (val, index, path) => {
        category = typeof amenitiesList == 'object' ? amenitiesList : JSON.parse(amenitiesList);
        index = typeof selectedIndex == 'object' ? selectedIndex : JSON.parse(selectedIndex);
        console.log('category_data', category, 'index_data', index, val);
        console.log('!category?.includes(val?.type)', category, index,!index?.includes(val?.type), index?.includes(val?.type), index?.includes("Tv"))

        if (!index?.includes(val?.type)) {
            console.log("val in include eiji", val?.type, typeof category, category?.length);
            category.push(val)
            index?.push(val?.type);
            setAmenitiesList(category);
            setSelectedIndex(JSON.stringify(index));
            localStorage.setItem("EditroomCategoryIndex", JSON.stringify(index));
            localStorage.setItem("Editcategory", JSON.stringify(category))
        } else if (index?.includes(val?.type)) {
            let inx = index?.indexOf(val?.type);
            console.log('splice', inx);
            if (inx > -1) {
                category?.splice(inx, 1);
                index?.splice(inx, 1);
                setAmenitiesList(category);
                setSelectedIndex(JSON.stringify(index));
            }
            localStorage.setItem("EditroomCategoryIndex", JSON.stringify(index));
            localStorage.setItem("Editcategory", JSON.stringify(category))
        }
    }

    useEffect(()=>{
        props?.onstatus();
        {console.log('JSON.parse(localStorage.getItem("Editcategory"))', JSON.parse(localStorage.getItem("Editcategory")))}
        (localStorage.getItem("Editcategory"))?props.onGetPlaceCategory(JSON.parse(localStorage.getItem("Editcategory"))):localStorage.removeItem("EditroomCategoryIndex")
        setAmenitiesList(localStorage.getItem("Editcategory"))
    },[selectedIndex , amenitiesList,localStorage.getItem("Editcategory")]);

    useEffect(() => {
        console.log('props?.amenities?.rommdata', props?.roomdata?.category, localStorage.getItem("EditroomCategoryIndex"));

        if (props?.roomdata) {
            let amientiesdata = props?.roomdata?.category;
            let indexdata = [];
            // console.log("amientiesdata_amientiesdata", amientiesdata)
            amientiesdata && amientiesdata?.length > 0 && amientiesdata?.map((val) => {
                if (val?.type) {
                    { console.log("valvalvalvalvalvalval", val) }
                    indexdata.push(val?.type)
                }
            })
            console.log('amientiesdata_amientiesdata', indexdata, amientiesdata, localStorage.getItem("EditroomCategoryIndex") != null, localStorage.getItem("EditroomCategoryIndex"), props?.roomdata?.category)
            let setIndex = localStorage.getItem("EditroomCategoryIndex") != null ? localStorage.getItem("EditroomCategoryIndex") : props?.roomdata?.category ? JSON.stringify(indexdata) : selectedIndex;
           let setAmienties = localStorage.getItem("Editcategory") != null ? localStorage.getItem("Editcategory") : props?.roomdata?.category ? props?.onGetPlaceCategory(props?.roomdata?.category) :
                localStorage.removeItem("EditroomCategoryIndex");
            console.log('setIndexsetIndex', setIndex)
            localStorage.setItem('EditroomCategoryIndex', setIndex)
            console.log('amienddddddddd', setIndex, setAmienties)
            setSelectedIndex(setIndex)
            setAmenitiesList(setAmienties)

        }
    }, [localStorage.getItem("placeTypeIndex"), props])


    return (
        <div className='col-md-12'>
            <div className='rightcont rightcont placetype mb-5'>
                <Scrollbars style={{ height: "calc(100vh - 320px)" }} className="scroll">
                    {/* <h6 className='my-2'>Tell guest what your place has to offer</h6> */}
                    <div className='row  px-3  align-items-center pb-5 mt-1' data-aos="fade-up">
                        {console.log('sadfasdfasdf',typeof selectedIndex ,selectedIndex, props?.data?.content)}
                        {props.data && props.data.content.map((val, index) => {
                            if (val?.status != "Inactive") {
                                return (<>
                                    {console.log("val?.status", val, index, props.data.path)}
                                    <div className={(selectedIndex && selectedIndex?.includes(val?.type)) ? 'col-md-4 amenities active' : 'col-md-4 amenities '}>
                                        {/* {console.log("indedx : " , index ,selectedIndex.includes(index))} */}
                                        <button onClick={() => { setData(val, index, props.data.path); props?.Filter("category") }} className=''>
                                            <img src={API_URL + props.data.path + "/" + val.image} />
                                            <p>{val.type}</p>
                                        </button>
                                    </div>
                                </>)
                            }
                        }
                        )}


                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

export default EditRoomCategory
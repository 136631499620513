import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";

import Login from "../Common/Modals/LoginModal.js";
import {
  Dropdown,
  Tabs,
  Tab,
  Row,
  Col,
  Nav,
  Modal,
  Accordion,
  InputGroup,
  Form,
  Toast,
} from "react-bootstrap";
import "../../Assets/css/header.css";
import { Scrollbars } from "react-custom-scrollbars";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Counter from "./counter";
import WhereInput from "./WhereInput.js";
import Cookies from "universal-cookie";

import logo from "../../Assets/images/landingPage/flutterpadlogo.png";
import logo1 from "../../Assets/images/logo1.png";
import user from "../../Assets/images/user1.png";
import map1 from "../../Assets/images/map-1.png";
import map2 from "../../Assets/images/map-2.png";
import map3 from "../../Assets/images/map-3.png";
import map4 from "../../Assets/images/map-4.png";

import map5 from "../../Assets/images/map-5.png";
import map6 from "../../Assets/images/map-6.png";
import countrycode from "../../config/countrycode.json";
import calendar from "../../Assets/images/calender_icon.png";
import { experiencehostingfilterhook, filter } from "../../Hooks/filterHook";
import { set } from "date-fns";
import Filtermodal from "./Filter/Filtermodal.js";
import {
  getUserEmail,
  getUserDatas,
  userLogout,
  getusersdatahooks,
  getunreadnotificationhooks,
  readonehooks,
  readallhooks,
  switchhostandguesthooks,
} from "../../Hooks/useUserHook.js";
import countryname, { Unitedkingdom } from "../../config/countryname";
import { toast, Toaster } from "react-hot-toast";
import { API_URL, toastOptions } from "../../config/env.js";
import { io } from "socket.io-client";
// import {Iamflexible} from '../../config/countryname';
const Servicemodal = React.lazy(() => import("./Modals/Servicemodal"));

const Header = (props) => {
  console.log("props>>>>>", props, props.email);
  const [viewanywhere, setViewanywhere] = useState("Anywhere");
  const [viewcountry, setViewcountry] = useState("Search destinations");
  const [show, setShow] = useState(false);
  const [showlog, setShowLog] = useState(false);
  const [email, setEmail] = useState("");
  const [userDatas, setUserDatas] = useState({});
  console.log("userDatas", userDatas);
  const [showFilter, setShowFilter] = useState(false);
  const [country, setCountry] = useState([]);
  const [adult, setAdult] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [pets, setPets] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isfilterbutton, setIsfilterbutton] = useState(false);
  const [buttonfiltereddata, setButtonfiltereddata] = useState([]);
  const [iscountry, setIscountry] = useState(true);
  const [isdates, setIsdates] = useState(true);

  const [unread, setUnread] = useState([]);

  // const []

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (data, a) => {
    console.log("e data handle change", data);
    setCountry(data);
    setIscountry(false);
    setViewcountry(a);
    setViewanywhere(a);
  };

  // const socket = io(API_URL);

  const getnotification = async () => {
    let user = getUserDatas();
    let notifydata = await getunreadnotificationhooks(user?._id);
    console.log("notifydata", notifydata);
    setUnread(notifydata?.data?.data);
  };

  const handlereadone = async (id) => {
    let user = getUserDatas();
    let readone = await readonehooks(id);
    let refresh = await getnotification();
  };
  const handlereadall = async () => {
    let user = getUserDatas();
    let readone = await readallhooks(user?._id);
    let refresh = await getnotification();
  };

  useEffect(() => {
    async function fetchdata() {
      await getnotification();
    }
    fetchdata();
  }, []);

  // useEffect(()=>{
  //   socket.on("getMessage" , ()=>{
  //      getnotification()
  //   })
  // } , [socket])

  let { useremail } = useParams();
  const [showservice, setShowservice] = useState(false);
  // const [isfilter , setIsfilter] = useState(false);
  const [counter, setCounter] = useState({
    Adults: 0,
    Children: 0,
    Infants: 0,
    Pets: 0,
  });
  const [userdata, setUserdata] = useState({});
  const [open, setopen] = useState(false);
  const [topsec, setTopsec] = useState(false);

  const handleResize = () => {
    setTopsec(false);
    // setIscountry(true);
  };

  window.addEventListener("scroll", handleResize);

  const checkUserLogin = async (type) => {
    console.log("check user login :", email);
    if (email) {
      if (
        // userdata?.govtproof?.length > 0 &&
        // userdata?.govtproof[0]?.verification == "accepted"
        userDatas?.govtidverification == "accepted"
      ) {
        if (parseFloat(userDatas?.hostsubscribedaycount) > 0) {
          if (type == "room") {
            window.location.href = window.location.origin + `/host/${email}`;
          } else if (type == "experience") {
            window.location.href =
              window.location.origin + `/host-experience/${email}`;
          }
        } else {
          // toast.error("Need Host Subscription");
          toast.error("Purchase the subscription plan and then provide all your room details")
          window.location.href = window.location.origin + `/account`;
        }
      } else {
        if(userDatas?.govtidverification == "rejected") {
          // toast.error("Admin reject your government id & so please reupload your id");
          toast.error("your personal details rejected by admin, please reupload the details");
          window.location.href = window.location.origin + `/account/personal-info`;
        }
        if(userDatas?.govtidverification == "pending") {
          // toast.error("need your government id");
          toast.error("Still your personal details not provided, Please fill those details");
          window.location.href = window.location.origin + `/account/personal-info`;
        }
      }
    } else {
      setShowLog(true);
    }
  };

  const logout = () => {
    // debugger
    // cookies.remove("email");
    // cookies.remove("User-Datas");
    // window.location.href = window.location.origin;
    userLogout().then((data) => {
      window.location.href = window.location.origin;
      console.log("dataslogut", data);
      toast.success(data, toastOptions);
      if (data.status) {
        // window.location.reload();
      }
    });
  };

  const hostDashboard = async () => {
    if (email) {
      window.location.href =
        window.location.origin + `/host-dashboard/${email}`;
    } else {
      setShowLog(true);
    }
  };

  useEffect(() => {
    async function fetchdata() {
      const data = getUserEmail();
      var userdata = await getusersdatahooks(data);
      setUserdata(userdata);
    }
    fetchdata();
  }, []);

  useEffect(() => {
    const data = getUserEmail();
    setEmail(data);
    setUserDatas(getUserDatas());
    // setLogout()
  }, [showlog]);

  return (
    <div>
      {showlog && <Login onDismiss={() => setShowLog(false)} />}
      <header className="header account-header">
        <div className="container container_custom py-1">
          <div className="row">
            <div className="col-lg-3 col-6 d-flex align-items-center">
              <Link
                to="/"
                className="nav-link px-0 py-0 d-flex align-items-center gap-1 gap-sm-2"
              >
                <img
                  src={logo1}
                  className="img-fluid acc-header-logo"
                  alt="logo"
                />
                <span className="logo-txt">FlutterPads</span>
              </Link>
            </div>
            <div className="col-lg-9 col-6 text-end pe-lg-0">
              <div className="right-head header-right-head">
                <Dropdown className="user_dd ps-0">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="btn px-0 prof become-host"
                  >
                    Become a Host
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dd_menu_shadow becomeHost">
                    <Dropdown.Item
                      onClick={() => {
                        checkUserLogin("room");
                      }}
                    >
                      Host a Room
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={() => {
                        checkUserLogin("experience");
                      }}
                    >
                      Host an Experience
                    </Dropdown.Item> */}
                    {/* <button className='btn btn_grey' onClick={()=>{checkUserLogin()}}>Become a Host</button> */}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="user_dd ps-0 ms-0 ms-sm-3">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="btn btn_trans_icon px-0 prof"
                  >
                    <img
                      src={
                        userDatas && userDatas.profilePicturePath
                          ? API_URL + userDatas.profilePicturePath
                          : user
                      }
                      className="img-fluid headerprof acc-header-prof"
                      alt="user"
                    />
                    <span className="cus-ham-burger ms-3">
                      <i className="fa fa-bars" aria-hidden="true" />
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dd_menu_shadow profileDrop">
                    {userDatas && userDatas.email && (
                      <Dropdown.Item onClick={() => hostDashboard()}>
                        Dashboard
                      </Dropdown.Item>
                    )}
                    {userDatas && userDatas.email && (
                      <Link to="/account/personal-info" className="dropdown-item">
                        Account
                      </Link>
                    )}
                    {userDatas && userDatas.email && (
                      <Link to="/wishlist" className="dropdown-item">
                        Saved Listings
                      </Link>
                    )}
                    {userDatas && userDatas.email && (
                      <Link to="/subscriptions-list" className="dropdown-item">
                        Subscriptions
                      </Link>
                    )}
                    {/* {email&&<Link to="/pending-orders" className='dropdown-item'>Trips</Link>} */}
                    {userDatas && userDatas.email && (
                      <Dropdown.Item
                        onClick={async () => {
                          let payload = {
                            id: userDatas?._id,
                            host: !userDatas?.isHost,
                          };
                          let res = await switchhostandguesthooks(payload);
                          let up = await getusersdatahooks(getUserEmail());
                          setTimeout(window.location.reload(), 500);
                        }}
                      >
                        Switch To{" "}
                        {userDatas?.isHost == true ? "Travelling" : "Hosting"}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() => {
                        userDatas && userDatas.email
                          ? logout()
                          : setShowLog(true);
                      }}
                    >
                      {userDatas && userDatas.email ? "Logout" : "Signup/Login"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Toaster />
    </div>
  );
};

export default Header;

import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FloatingLabel, Form } from "react-bootstrap";

const CreateDescriptionContent = (props) => {
  const [charcount, setCharCount] = useState(0);
  const [title, setTitle] = useState("");
  const [rulecount, setRulecount] = useState(0);
  const [rules, setRules] = useState("");

  //New
  const [createtitle, setCreateTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addrules, setAddRules] = useState("");
  const handleChange = (val) => {
    if (val?.length >= 0) {
      setCharCount(val.length);
      setTitle(val);
      props.onGetDescriptionContent(val);
    } else {
      setTitle("");
      setCharCount(val.length);
      props.onGetDescriptionContent(val);
    }
  };

  const handleChangerules = (val) => {
    if (val?.length >= 0) {
      setRulecount(val.length);
      setRules(val);
      props.onGetRules(val);
    } else {
      setRules("");
      setRulecount(val.length);
      props.onGetRules(val);
    }
  };

  useEffect(() => {
    setTitle(
      localStorage.getItem("descriptionContent")
        ? localStorage.getItem("descriptionContent")
        : title
    );
    setCharCount(
      localStorage.getItem("descriptionContent")
        ? localStorage.getItem("descriptionContent").length
        : title.length
    );
    
    localStorage.getItem("descriptionContent")
      ? props.onGetDescriptionContent(
          localStorage.getItem("descriptionContent")
        )
      : localStorage.removeItem("descriptionContent");

    // setRules(
    //   localStorage.getItem("rules")
    //     ? localStorage.getItem("rules")
    //     : rules
    // );
    // setRulecount(
    //   localStorage.getItem("rules")
    //     ? localStorage.getItem("rules").length
    //     : rules.length
    // );
    // localStorage.getItem("rules")
    //   ? props.onGetRules(
    //       localStorage.getItem("rules")
    //     )
    //   : localStorage.removeItem("rules");
  }, []);


  //New
  useEffect(() => {
    setCreateTitle(localStorage.getItem("title") ? localStorage.getItem("title") : title);
    props?.onGetTitle(localStorage.getItem("title") ? localStorage.getItem("title") : title)
    setDescription(localStorage.getItem("descriptionContent") ? localStorage.getItem("descriptionContent") : description);
    setAddRules(localStorage.getItem("rules") ? localStorage.getItem("rules") : rules);

  }, [])

  useEffect(() => {

  }, [])

  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="header-txt">
        <h2 className="fw-800">Your listing details</h2>
        <p className="des-txt">
          Give your place a name and add a detailed description to share with
          guests
        </p>
      </div>
      <div className="listing-details mt-4">
        <div className="mb-3">
          <p className="mb-3 fw-600 orange-txt">Create a title</p>
          <Form.Control
            type="text"
            className="listingInp"
            placeholder="Write your title"
            id="createtitle"
            value={createtitle}
            onChange={(e) => {setCreateTitle(e?.target?.value); props?.onGetTitle(e?.target?.value)}}
          />
        </div>
        <div className="mb-3">
          <p className="mb-3 fw-600 orange-txt">Create a description</p>
          <Form.Control
            maxlength="2000"
            as="textarea"
            placeholder="Write your description"
            className="listingInp txtarea"
            id="description"
            value={description}
            onChange={(e) => {
              setDescription(e?.target?.value);
              props.onGetDescriptionContent(e?.target?.value);
            }}
          />
        </div>
        <div>
          <p className="mb-3 fw-600 orange-txt">Add your house rules</p>
          <Form.Control
            maxlength="2000"
            as="textarea"
            className="listingInp txtarea"
            placeholder="Write your house rules"
            id="addrules"
            value={addrules}
            onChange={(e) => {
              setAddRules(e?.target?.value);
              props?.onGetAddRules(e?.target?.value);
            }}
          />
        </div>
      </div>
      {/* <div>
        <h3 className="mb-4">Create your Description</h3>
        <Form className="p-0">
          <FloatingLabel controlId="floatingTextarea2">
            <Form.Control
              maxlength="2000"
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              value={title}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
          </FloatingLabel>
          <div className="char mt-2">
            {" "}
            <span>{charcount && charcount}</span>/2000
          </div>
          <h3 className="mb-4 mt-4">Create your Rules</h3>
          <FloatingLabel controlId="floatingTextarea2">
            <Form.Control
              maxlength="2000"
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              value={rules}
              onChange={(e) => {
                handleChangerules(e.target.value);
              }}
            />
          </FloatingLabel>
          <div className="char mt-2">
            {" "}
            <span>{rulecount && rulecount}</span>/2000
          </div>
        </Form>
      </div> */}
    </div>
  );
};

export default CreateDescriptionContent;

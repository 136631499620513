import React from "react";
import {
  getallcmsdatahook,
  getallfaqcmsdatahook,
} from "../../Hooks/useUserHook";
import HostHeader from "../BecomeHost/AfterHost/HostHeader";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Header from "./Header";

const FAQ = () => {
  const [faq, setFaq] = useState([]);
  console.log('faqdataaaaa', faq)
  useEffect(() => {
    async function fetchdata() {
      console.log("");
      await getallfaqcmsdatahook().then((result) => {
        console.log("get all crm hook result", result?.data?.data);
        result?.data?.data?.map((data, i) => (data.sno = i + 1));
        setFaq(result?.data?.data);
      });
    }
    fetchdata();
  }, []);
  // const cmsFilter = (data) => {
  //   try{

  //     let filterdata = cms?.find(el => (el.identifier == data && el?.status == "active"));
  //     console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
  //     return filterdata;
  //   } catch (e) {
  //     console.log("cmsFilter_err", e)
  //   }
  // }
  const [first, setFirst] = useState(null);

  const handleClick = (dataId) => {
    setFirst((prevFirst) => (prevFirst === dataId ? null : dataId));
  };

  return (
    <>
      {/* <HostHeader /> */}
      <Header />
      {/* FAQ Section */}

      <section className="pad_y">
        <div className="container">
          <div className="row justify-content-center">
            <div>
              <div className="tle_sec">
                <h1 className="acc_head">Frequently asked questions</h1>
                <p className="mt-3 acc_subtle">
                  Quick answers to questions you may have.
                </p>
              </div>

              {/* <div className="acc_sec mt-5">
                <div class="accordion" id="accordionExample">
                  {faq && faq?.length > 0 && faq?.map((data, i) => (
                    <div key={i} class="accordion-item acc_itm_pad">
                      <h2 class="accordion-header" id={`heading${i}`}>
                        <button
                          className={`accordion-button acc_new_btn ${
                            first === data._id ? "" : "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${i}`}
                          aria-expanded={first === data._id ? "true" : "false"}
                          aria-controls={`collapse${i}`}
                          onClick={() => handleClick(data._id)}
                        >
                          {data?.question}
                        </button>
                      </h2>
                      <div
                        id={`collapse${i}`}
                        className={`accordion-collapse collapse ${
                          first === data._id ? "show" : ""
                        }`}
                        aria-labelledby={`heading${i}`}
                      >
                        <div class="accordion-body acc_body">
                          <p className="acc_p">
                            {ReactHtmlParser(data?.answer)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button acc_new_btn collapsed"
                        // class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        How does FlutterPads work?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Quisquam doloribus veniam assumenda adipisci id
                          officiis animi repellat molestias. Cupiditate, soluta!
                          Quae adipisci atque itaque repellat deserunt excepturi
                          qui voluptatem dignissimos corrupti, beatae pariatur
                          laborum est ad quaerat rerum quibusdam, facere vitae
                          accusamus consequatur ipsa earum exercitationem error
                          nihil? Modi, aperiam.
                        </p>

                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Praesentium fugit id incidunt vero molestiae
                          nesciunt repellat quidem, velit accusantium dolorem!
                          Officia dolores quidem iste natus nostrum! Harum
                          corporis consequuntur esse culpa sequi aliquid,
                          possimus vel architecto tempora reiciendis soluta
                          mollitia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button acc_new_btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        How do I transfer homes?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Quisquam doloribus veniam assumenda adipisci id
                          officiis animi repellat molestias. Cupiditate, soluta!
                          Quae adipisci atque itaque repellat deserunt excepturi
                          qui voluptatem dignissimos corrupti, beatae pariatur
                          laborum est ad quaerat rerum quibusdam, facere vitae
                          accusamus consequatur ipsa earum exercitationem error
                          nihil? Modi, aperiam.
                        </p>

                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Praesentium fugit id incidunt vero molestiae
                          nesciunt repellat quidem, velit accusantium dolorem!
                          Officia dolores quidem iste natus nostrum! Harum
                          corporis consequuntur esse culpa sequi aliquid,
                          possimus vel architecto tempora reiciendis soluta
                          mollitia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item acc_itm_pad">
                    <h2
                      class="accordion-header"
                      id="panelsStayOpen-headingFour"
                    >
                      <button
                        class="accordion-button acc_new_btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseFour"
                      >
                        How long can I live with FlutterPads?
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Quisquam doloribus veniam assumenda adipisci id
                          officiis animi repellat molestias. Cupiditate, soluta!
                          Quae adipisci atque itaque repellat deserunt excepturi
                          qui voluptatem dignissimos corrupti, beatae pariatur
                          laborum est ad quaerat rerum quibusdam, facere vitae
                          accusamus consequatur ipsa earum exercitationem error
                          nihil? Modi, aperiam.
                        </p>

                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Praesentium fugit id incidunt vero molestiae
                          nesciunt repellat quidem, velit accusantium dolorem!
                          Officia dolores quidem iste natus nostrum! Harum
                          corporis consequuntur esse culpa sequi aliquid,
                          possimus vel architecto tempora reiciendis soluta
                          mollitia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item acc_itm_pad">
                    <h2
                      class="accordion-header"
                      id="panelsStayOpen-headingFive"
                    >
                      <button
                        class="accordion-button acc_new_btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseFive"
                      >
                        Are there any other fees or taxes other than the rent?
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Quisquam doloribus veniam assumenda adipisci id
                          officiis animi repellat molestias. Cupiditate, soluta!
                          Quae adipisci atque itaque repellat deserunt excepturi
                          qui voluptatem dignissimos corrupti, beatae pariatur
                          laborum est ad quaerat rerum quibusdam, facere vitae
                          accusamus consequatur ipsa earum exercitationem error
                          nihil? Modi, aperiam.
                        </p>

                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Praesentium fugit id incidunt vero molestiae
                          nesciunt repellat quidem, velit accusantium dolorem!
                          Officia dolores quidem iste natus nostrum! Harum
                          corporis consequuntur esse culpa sequi aliquid,
                          possimus vel architecto tempora reiciendis soluta
                          mollitia.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  */}

              {/* New Acc */}

              <div className="acc_sec mt-5">
                <div class="accordion" id="accordionExample">
                  {faq &&
                    faq?.length > 0 &&
                    faq.map((data, i) => {
                      // {
                      //   console.log("dfasgvdfasdfgjhasdf", val);
                      // }
                      return (
                        <div class="accordion-item acc_itm_pad" key={i}>
                          <h2 class="accordion-header" id={`heading${i}`}>
                            <button
                             className={`accordion-button acc_new_btn ${
                              first === data._id ? "" : "collapsed"
                            }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${i}`}
                              aria-expanded={first === data._id ? "true" : "false"}
                              aria-controls={`collapse${i}`}
                              onClick={() => handleClick(data._id)}
                            >
                              {data?.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${i}`}
                            className={`accordion-collapse collapse ${
                              first === data._id ? "show" : ""
                            }`}
                            aria-labelledby={`heading${i}`}
                            
                          >
                            <div class="accordion-body acc_body">
                              <p
                                className="mt-3 acc_p"
                                dangerouslySetInnerHTML={{
                                  __html: data?.answer,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FAQ;

{
  /* <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button acc_new_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        How does FlutterPads work?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui tempora enim sed temporibus veritatis,
                          asperiores dolore, similique totam voluptatibus error
                          optio eligendi quod laudantium sunt exercitationem.
                          Numquam deserunt consequuntur laudantium!
                        </p>
                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Nisi optio tempore, porro ut eum aliquid, libero
                          excepturi quod inventore minima officiis distinctio
                          laborum voluptate maxime odit cumque, nesciunt quidem
                          saepe!
                        </p>
                      </div>
                    </div>
                  </div> */
}
{
  /* <div className="acc_sec mt-5">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button acc_new_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        How does FlutterPads work?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui tempora enim sed temporibus veritatis,
                          asperiores dolore, similique totam voluptatibus error
                          optio eligendi quod laudantium sunt exercitationem.
                          Numquam deserunt consequuntur laudantium!
                        </p>
                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Nisi optio tempore, porro ut eum aliquid, libero
                          excepturi quod inventore minima officiis distinctio
                          laborum voluptate maxime odit cumque, nesciunt quidem
                          saepe!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button acc_new_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        How does FlutterPads work?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui tempora enim sed temporibus veritatis,
                          asperiores dolore, similique totam voluptatibus error
                          optio eligendi quod laudantium sunt exercitationem.
                          Numquam deserunt consequuntur laudantium!
                        </p>
                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Nisi optio tempore, porro ut eum aliquid, libero
                          excepturi quod inventore minima officiis distinctio
                          laborum voluptate maxime odit cumque, nesciunt quidem
                          saepe!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button acc_new_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        How does FlutterPads work?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui tempora enim sed temporibus veritatis,
                          asperiores dolore, similique totam voluptatibus error
                          optio eligendi quod laudantium sunt exercitationem.
                          Numquam deserunt consequuntur laudantium!
                        </p>
                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Nisi optio tempore, porro ut eum aliquid, libero
                          excepturi quod inventore minima officiis distinctio
                          laborum voluptate maxime odit cumque, nesciunt quidem
                          saepe!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button acc_new_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                      >
                        How does FlutterPads work?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui tempora enim sed temporibus veritatis,
                          asperiores dolore, similique totam voluptatibus error
                          optio eligendi quod laudantium sunt exercitationem.
                          Numquam deserunt consequuntur laudantium!
                        </p>
                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Nisi optio tempore, porro ut eum aliquid, libero
                          excepturi quod inventore minima officiis distinctio
                          laborum voluptate maxime odit cumque, nesciunt quidem
                          saepe!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button acc_new_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="true"
                        aria-controls="collapseFive"
                      >
                        How does FlutterPads work?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse "
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui tempora enim sed temporibus veritatis,
                          asperiores dolore, similique totam voluptatibus error
                          optio eligendi quod laudantium sunt exercitationem.
                          Numquam deserunt consequuntur laudantium!
                        </p>
                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Nisi optio tempore, porro ut eum aliquid, libero
                          excepturi quod inventore minima officiis distinctio
                          laborum voluptate maxime odit cumque, nesciunt quidem
                          saepe!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item acc_itm_pad">
                    <h2 class="accordion-header" id="headingSix">
                      <button
                        class="accordion-button acc_new_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="true"
                        aria-controls="collapseSix"
                      >
                        How does FlutterPads work?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body acc_body">
                        <p className="acc_p">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui tempora enim sed temporibus veritatis,
                          asperiores dolore, similique totam voluptatibus error
                          optio eligendi quod laudantium sunt exercitationem.
                          Numquam deserunt consequuntur laudantium!
                        </p>
                        <p className="mt-3 acc_p">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Nisi optio tempore, porro ut eum aliquid, libero
                          excepturi quod inventore minima officiis distinctio
                          laborum voluptate maxime odit cumque, nesciunt quidem
                          saepe!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */
}

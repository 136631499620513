import React, { useEffect, useState } from "react";

import HostCard from "../../../Cards/HostCard";
import HostHeader from "../../../HostHeader";
import { toast, Toaster } from "react-hot-toast";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const SplitModal = (props) => {
  const [cancel, setCancel] = useState(true);
  const [cancellationAmount, setCancellationAmount] = useState(0);
  const [splitbookingdata, setSplitBookingData] = useState({});

  useEffect(() => {
    setSplitBookingData(props?.splitbookingDatas);
    console.log(
      "enter this modal",
      props?.splitbookingDatas,
      props?.splitPaymentDetails
    );
  }, []);

  useEffect(() => {
    // if(props.data&&props.data._id){
    //     const data = {
    //         bookingId : props.data._id,
    //         cancellationPolicy : props.data.cancellationPolicy,
    //         cancelledAmount : (parseInt(props.data.totalAmount) - parseInt(props.data.serviceFeeAmount))
    //     }
    //     console.log('datacanceldetails',data)
    //     getCancellationAmount(data).then((result)=>{
    //         setCancellationAmount(result.cancellationAmount);
    //     })
    // }
  }, []);

  console.log("props data", props);
  return (
    <>
      <div className="no-stat-new">
        {/* <div className='no-stat-cont'>
                                                <span className='fa fa-clock-o'></span>
                                                <p>You don’t have any guests checking out today or tomorrow.</p>
                                            </div> */}
        <Modal
          show={props.show}
          onHide={props.handleClose}
          centered
          className="split_modal_popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Split Trip Amount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row withstat">
              {props?.splitPaymentDetails &&
                props?.splitPaymentDetails?.length > 0 &&
                props?.splitPaymentDetails.map((val, index) => (
                  <>
                    <div className="cols col-xl-12 col-lg-12 mb-3">
                      <div className="shadowbox p-4 br-5">
                        <div className="d-flex align-items-center">
                          {/* <img src={prof} className="prof" /> */}
                          {/* <h6 className='mb-3'><b>{val.roomTitle} at {val.accommodationAddress.city}</b></h6> */}
                        </div>
                        <div className="graybox br-0 mt-3 py-3 mobi_fle_ger">
                          <div className="d-flex jc-between">
                            <h6 className="mb-3 f-14">
                              <p className="themeclr">Booked by</p>
                              <p className="text-muted">{val.email}</p>
                            </h6>
                          </div>

                          <div className="d-flex jc-between">
                            <h6 className="mb-3 f-14">
                              <p className="themeclr">Room Title</p>
                              <p className="text-muted">
                                {splitbookingdata?.roomTitle}
                              </p>
                            </h6>
                          </div>
                          <div className="d-flex jc-between">
                            <h6 className="mb-3 f-14">
                              <p className="themeclr">Check In</p>{" "}
                              <p className="text-muted">
                                {new Date(val.startDate).toLocaleDateString()}
                              </p>
                            </h6>
                          </div>

                          <div className="d-flex jc-between">
                            <h6 className="mb-3 f-14">
                              <p className="themeclr">CheckOut</p>{" "}
                              <p className="text-muted">
                                {new Date(val.endDate).toLocaleDateString()}
                              </p>
                            </h6>
                          </div>

                          <div className="d-flex jc-between">
                            <h6 className="mb-3 f-14">
                              <p className="themeclr">Nights</p>{" "}
                              <p className="text-muted">{val?.nights}</p>
                            </h6>
                          </div>
                          <div className="d-flex jc-between">
                            <h6 className="mb-3 f-14">
                              <p className="themeclr">Amount</p>{" "}
                              <p className="text-muted">{val?.splitamount}</p>
                            </h6>
                          </div>
                          <div className="d-flex jc-between">
                            <h6 className="mb-3 f-14">
                              <p className="themeclr">serviceFee</p>{" "}
                              <p className="text-muted">{val?.serviceFee}</p>
                            </h6>
                          </div>
                        </div>
                        {/* <h6 className='mb-3 f-14 mt-3 d-flex jc-between'>
                                    <span className='text-muted'>{val.splitamount} Night(s)</span></h6>
                                <hr className='themeclr' /> */}
                        <h6 className="mb-0 f-14 mt-3 d-flex jc-between">
                          <span className="themeclr">TotalAmount:</span>{" "}
                          <span className="success-box suc_box_blue">
                            ${Number(val?.splitamount) + Number(val?.serviceFee) }
                          </span>
                        </h6>

                        {/* {(props.isPending)? 
                                        // <div className='d-flex mt-3'><button onClick={()=>{approve(true,index,false)}} type="button" class="btn btn-theme  me-2">Accept</button><button onClick={()=>{approve(false,index,true)}} type="button" class="btn btn-theme ">Decline</button></div>:<></>
                                        }  */}
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="btn-theme"
              onClick={props.handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Toaster />
    </>
  );
};

export default SplitModal;

import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FloatingLabel, Form } from "react-bootstrap";

const EditCreateTitle = (props) => {
    const [charcount, setCharCount] = useState(0);
    const [title, setTitle] = useState("");
    const handleChange = (val) => {
        console.log()
        if (val != "") {
            setCharCount(val.length);
            setTitle(val);
            props.onGetTitle(val);
        } else {
            setCharCount(val.length);
            props.onGetTitle(val);
            setTitle("");
        }
    };

    useEffect(() => {
        if (props?.roomdata) {
            let title = localStorage.getItem("title") != null ? localStorage.getItem("title") : props?.roomdata?.title ? props?.roomdata?.title : "";
            let titlelen = localStorage.getItem("title") != null ? localStorage.getItem("title")?.length : props?.roomdata?.title ? props?.roomdata?.title?.length : "";
            setTitle(title);
            setCharCount(titlelen)
        }
    }, [localStorage.getItem("title"), props]);

    return (
        <div className="col-lg-12 col-xl-12 col-md-12">
            <div className="create-title-scroll placetype  placetype_heoght_fix mb-5">
                <Scrollbars
                    style={{ height: "calc(100vh - 320px)" }}
                    className="scroll"
                >
                    <div
                        className="row  px-3 align-items-center pb-5"
                        data-aos="fade-up"
                    >
                        <div>
                            <h3 className="mb-4">Create your title</h3>

                            <Form className="p-0">
                                <FloatingLabel controlId="floatingTextarea2">
                                    <Form.Control
                                        maxlength="50"
                                        as="textarea"
                                        placeholder={title ? title : "Enter your Title"}
                                        style={{ height: "100px" }}
                                        value={title}
                                        onChange={(e) => {
                                            handleChange(e.target.value); props?.Filter("title")
                                        }}
                                    />
                                </FloatingLabel>
                                <div className="char mt-2">
                                    {" "}
                                    <span>{charcount && charcount}</span>/50
                                </div>
                            </Form>
                        </div>
                    </div>
                </Scrollbars>
            </div>
        </div>
    );
};

export default EditCreateTitle;

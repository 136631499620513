import React from "react";
import ReCAPTCHA from "react-google-recaptcha";


const Captchaa = (props) => {
   console.log("sitekey", "6LeO2SIpAAAAAE9Vi4I_C4r0yVpHvPWKHRVcgQF4")
    return (
        <>
            <div className="captcha mt-3 mb-2">
                <ReCAPTCHA
                //local
                    //  sitekey="6LezIlwpAAAAACMuVvOPplTSXeqnpoY1NEk7mDZ9" 
                    // testnet 
                    // sitekey="6Ldge2spAAAAAMVg0q3wDhgJgsgr_-v_YiFSt-bd"
                    //mainnet
                    // sitekey="6LcFa3IpAAAAACNC2sel-nqIJA4HnMBZ3Q_N2x59"

                    sitekey = "6LeoJHwpAAAAAOZRq1gquvh0KSn77Ji6lyRhZ4mV"
                    
                      onChange={props.onchange}                
                />
            </div>

        </>
    )
}


export default Captchaa;
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import cardimg1 from "../../../../Assets/images/guest1.png";
import { LuPlusCircle, LuMinusCircle } from "react-icons/lu";
import Form from "react-bootstrap/Form";
import HeaderDashOne from "../../../Common/HeaderDashOne";

const AreaForGuest = () => {
  return (
    <>
      <HeaderDashOne />
      <div className="container container_custom">
        <div className="house-rules common-dash-guest py-4 py-lg-5">
          <div className="header-sec">
            <h1 className="mb-1 fw-700 header-txt">
              Add rooms to your listing
            </h1>
            <p className="mb-0 fw-500 header-desc">
              Add all the rooms guests can use - even shared areas
            </p>
          </div>
          <div className="row">
            <div className="order-2 order-md-1 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="mt-4">
                <h4 className="mb-0 fw-600">Rooms in your listing</h4>
                <ul className="ps-0 setrule mt-3">
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">Bedrooms</p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">2 Bedrooms</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">Bathrooms</p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">2 Bathroom</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">Common areas</p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">2 Common areas</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">Beds</p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">5 beds</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="order-1 order-md-2 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center mt-3 mt-lg-0">
              <img src={cardimg1} className="img-fluid guest" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-start mt-2">
            <button className="btn btn-theme">Save</button>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default AreaForGuest;

import React, { useState, useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Login from "../Common/Modals/LoginModal.js";
import {
  Dropdown,
  Tabs,
  Tab,
  Row,
  Col,
  Nav,
  Modal,
  Accordion,
  InputGroup,
  Form,
  Toast,
} from "react-bootstrap";
import "../../Assets/css/header.css";
import "../../Assets/css/landing.css";
import { Scrollbars } from "react-custom-scrollbars";
import Datepicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
// import Counter from './counter';
// import WhereInput from './WhereInput.js';
// import Cookies from 'universal-cookie';

import logo from "../../Assets/images/logo.png";
import user from "../../Assets/images/user.png";
import map1 from "../../Assets/images/map-1.png";
import map2 from "../../Assets/images/map-2.png";
import california from "../../Assets/images/california.png";
import map3 from "../../Assets/images/map-3.png";
import map4 from "../../Assets/images/map-4.png";

import map5 from "../../Assets/images/map-5.png";
import map6 from "../../Assets/images/map-6.png";
import countrycode from "../../config/countrycode.json";
import calendar from "../../Assets/images/calender_icon.png";
import { experiencehostingfilterhook, filter } from "../../Hooks/filterHook";
import { set } from "date-fns";
// import Filtermodal from './Filter/Filtermodal.js';
import {
  getUserEmail,
  getUserDatas,
  userLogout,
} from "../../Hooks/useUserHook.js";
import countryname, { Unitedkingdom } from "../../config/countryname";
import { toast, Toaster } from "react-hot-toast";
import { API_URL, toastOptions } from "../../config/env.js";
// import {Iamflexible} from '../../config/countryname';
// const Servicemodal = React.lazy(() => import("./Modals/Servicemodal"));

import residental from "../../Assets/images/landingPage/residential.png";
import car from "../../Assets/images/landingPage/car.png";
import smily from "../../Assets/images/landingPage/smily.png";
import calendarimg from "../../Assets/images/landingPage/calender.png";
import location from "../../Assets/images/landingPage/location.png";
import rightarrow from "../../Assets/images/landingPage/right-arrow.png";
import downarrow from "../../Assets/images/landingPage/downarrow.png";
// import Logo from "../../Assets/images/landingPage/logo1.png";
import calendar1 from "../../Assets/images/landingPage/calendar1.png";
import location1 from "../../Assets/images/landingPage/location1.png";
import guest1 from "../../Assets/images/landingPage/guest1.png";
import rightarrow1 from "../../Assets/images/landingPage/rightarrow1.png";
import arrow2 from "../../Assets/images/landingPage/Arrow.png";

const FiltersCopy = (props) => {
  console.log("props>>>>>", props, props.email);
  const [viewanywhere, setViewanywhere] = useState("Anywhere");
  const [viewcountry, setViewcountry] = useState("");
  const [show, setShow] = useState(false);
  const [showlog, setShowLog] = useState(false);
  const [email, setEmail] = useState("");
  const [userDatas, setUserDatas] = useState({});
  console.log("userDatas", userDatas);
  const [showFilter, setShowFilter] = useState(false);
  const [country, setCountry] = useState([]);
  const [adult, setAdult] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [pets, setPets] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isfilterbutton, setIsfilterbutton] = useState(false);
  const [buttonfiltereddata, setButtonfiltereddata] = useState([]);
  const [iscountry, setIscountry] = useState(true);
  const [isdates, setIsdates] = useState(true);
  const [arrival, setArrival] = useState(false);
  const [departure, setDeparture] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (data, a) => {
    console.log("e data handle change", data);
    setCountry(data);
    setIscountry(false);
    setViewcountry(a);
    setViewanywhere(a);
  };
  let { useremail } = useParams();
  const [showservice, setShowservice] = useState(false);
  const [selection, setSelection] = useState("Stays");
  // const [isfilter , setIsfilter] = useState(false);
  const [counter, setCounter] = useState({
    Adults: 0,
    Children: 0,
    Infants: 0,
    Pets: 0,
  });

  useEffect(() => {
    if (window.location.pathname.split("/")[2] == "filter") {
      console.log("filter in url");
      let params = new URLSearchParams(document.location.search);
      let name = params.get("payload");
      console.log("payload in url", name);
      if (name) {
        let payload = JSON.parse(name); //localStorage.getItem("FIL-PAY-LOAD")
        console.log("filter in url", payload);
        setCountry(payload?.country ? payload?.country : "");
        setStartDate(
          payload?.checkInDate ? new Date(payload?.checkInDate) : ""
        );
        setEndDate(
          payload?.checkOutDate ? new Date(payload?.checkOutDate) : ""
        );
        setAdult(payload?.adult ? payload?.adult : 0);
        setChildren(payload?.children ? payload?.children : 0);
        setInfants(payload?.infants ? payload?.infants : 0);
        setPets(payload?.pets ? payload?.pets : 0);
        setViewcountry(payload?.viewcountry ? payload?.viewcountry : 0);
        props.filter(payload);
      }
    }

    if (window.location.pathname.split("/")[3] == "stay") {
      console.log(
        `window.location.pathname.split("/")[3]`,
        window.location.pathname.split("/")[3]
      );
      setSelection("Stays");
    }
    if (window.location.pathname.split("/")[3] == "experience") {
      console.log(
        `window.location.pathname.split("/")[3]`,
        window.location.pathname.split("/")[3]
      );
      setSelection("Experience");
    }
  }, []);

  const [open, setopen] = useState(false);
  const [topsec, setTopsec] = useState(false);
  const handleadultplus = () => {
    if (adult + children < 16) {
      setAdult(adult + 1);
    }
  };
  const handleadultminus = () => {
    if (children > 0 || infants > 0 || pets > 0) {
      // if(children == 0 || infants == 0 || pets == 0)
      // if(adult>0)
      // {
      //   setAdult(adult-1)
      // }
      if (adult > 1) {
        setAdult(adult - 1);
      }
    } else if (adult > 0) {
      setAdult(adult - 1);
    }
  };
  const handlechildplus = () => {
    if (adult + children < 16) {
      setChildren(children + 1);
    }
    if (children == 0 && adult == 0) setAdult(1);
  };
  const handlechildminus = () => {
    if (children > 0) {
      setChildren(children - 1);
    }
  };
  const handleinfantplus = () => {
    if (infants < 5) {
      setInfants(infants + 1);
    }
    if (infants == 0 && adult == 0) setAdult(1);
  };
  const handleinfantminus = () => {
    if (infants > 0) {
      setInfants(infants - 1);
    }
  };
  const handlepetplus = () => {
    if (pets < 5) {
      setPets(pets + 1);
    }

    if (pets == 0 && adult == 0) setAdult(1);
  };
  const handlepetminus = () => {
    if (pets > 0) setPets(pets - 1);
  };

  const handleResize = () => {
    setTopsec(false);
    // setIscountry(true);
  };

  window.addEventListener("scroll", handleResize);

  const checkUserLogin = async (type) => {
    console.log("check user login :", email);
    if (email) {
      if (type == "room") {
        window.location.href = window.location.origin + `/host/${email}`;
      } else if (type == "experience") {
        window.location.href =
          window.location.origin + `/host-experience/${email}`;
      }
    } else {
      setShowLog(true);
    }
  };

  const logout = () => {
    userLogout().then((data) => {
      console.log("dataslogut", data);
      toast.success(data, toastOptions);
      if (data.status) {
        window.location.reload();
      }
    });
  };

  const hostDashboard = async () => {
    if (email) {
      window.location.href =
        window.location.origin + `/host-dashboard/${email}`;
    } else {
      setShowLog(true);
    }
  };

  function showHeader() {
    setopen(true);
  }

  const [cusDate, setCusDate] = useState();

  const handleDateChange = (date) => {
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate("");
      // dt[1] = ""
      // dt[0] = date;
      return;
    }
    if (!startDate || arrival) {
      setStartDate(date);
      setArrival(!arrival);
      setDeparture(true);
      return;
    } else if (startDate && departure && !endDate) {
      if (startDate < date) {
        setEndDate(date);
        // dt[1] = date
      } else {
        setStartDate(date);
        // dt[0] = date
      }
      return;
    }
    if (startDate && endDate && arrival) {
      setStartDate(date);
    } else if (startDate && endDate && departure) {
      setEndDate(date);
    }
    if (departure && !startDate && !endDate) {
      setEndDate(date);
      // setStartDate();
      setDeparture(false);
    }
    // if (arrival) {
    //   setDeparture(false);
    // } else if (departure) {
    //   setArrival(false);
    // }
  };

  const filterdata = async () => {
    console.log("filter data");
    //  setIsfilter(true);
    setTopsec(false);
    console.log("date", startDate, endDate);
    let payload = {
      country: country,
      checkInDate: startDate,
      checkOutDate: endDate,
      adult: adult,
      children: children,
      infants: infants,
      pets: pets,
      guest: adult + children,
      skip: 0,
      limit: 10,
      //  startdate : startDate,
      //  enddate : endDate
      //  count : counter
    };
    console.log("payload", payload);
    navigate(
      `/landing-map/filter/${selection?.toLowerCase()}?payload=${JSON.stringify(
        payload
      )}`
    );
    props.filter(payload);
    // await filter(payload)
    //   .then((data) => {
    //     console.log("filter return : ", data);
    //     props.onSetFilteredData(data);
    //   })
    //   .catch((e) => console.log("filter return error", e));
  };
  //  useEffect(()=>{
  //   async function fetchdata(){
  //     let email = await cookies.get('email');
  //     console.log("emailid ",email)
  // }
  // fetchdata();
  //  })

  const setLogout = async () => {
    console.log("enterrrrrrrrrring");
    if (
      props.email != "undefined" &&
      props.email != undefined &&
      props.email != ""
    ) {
      await logout();
      props.email = "undefined";
    }
  };

  useEffect(() => {
    const data = getUserEmail();
    setEmail(data);
    setUserDatas(getUserDatas());
    // setLogout()
  }, [showlog]);
  //    const filterbuttondata = (val) => {
  //     console.log("props" , val);
  //     if(val.data.type == 'success')
  //         setIsfilterbutton(true)
  //         setButtonfiltereddata(val.data.record);
  // }

  const filterdataexperience = async () => {
    console.log("experience filter data");
    //  setIsfilter(true);
    setTopsec(false);
    console.log("date", startDate, endDate);
    let payload = {
      country: country,
      checkInDate: startDate,
      checkOutDate: endDate,
      adult: adult,
      children: children,
      infants: infants,
      pets: pets,
      guest: adult,
      //  startdate : startDate,
      //  enddate : endDate
      //  count : counter
    };
    console.log("payload", payload);
    props.expfilter(payload);
    // await experiencehostingfilterhook(payload)
    //   .then((data) => {
    //     console.log("filter return : ", data);
    //     props.onSetFilteredData(data);
    //   })
    //   .catch((e) => console.log("filter return error", e));
  };

  const handleclear = async () => {
    setCountry("");
    setStartDate("");
    setEndDate("");
    setAdult(0);
    setChildren(0);
    setInfants(0);
    setPets(0);
    setViewcountry("");
    navigate(`/landing-map/filter/${selection?.toLowerCase()}`);
  };

  const handleArrival = () => {
    // if (startDate) {
    setArrival(true);
    setDeparture(false);
    // }
  };

  const handleDeparture = () => {
    setDeparture(true);
    setArrival(false);
  };

  return (
    <div className="filter pb-0 pt-0 pt-md-0 pt-lg-0 pt-xl-5">
      {showlog && <Login onDismiss={() => setShowLog(false)} />}

      <div className="filter-section justify-content-center align-items-center">
        <div className="custom-container-filter custom-container-filter-copy">
          <div className="row">
            <div className="col-xl-12 col-lg-12 pt-lg-4 pt-xl-0 justify-content-start pt-md-4 pt-sm-4 pt-4 d-flex align-items-center">
              <div
                className="stay-rental-exp d-flex align-items-center ms-0 justify-content-between"
                style={{ overflow: "auto" }}
              >
                <div
                  className={selection == "Stays" ? "stay stay-bg" : "stay"}
                  onClick={() => {
                    if (selection != "Stays") {
                      props.selection("Stays");
                      setSelection("Stays");
                      navigate("/landing-map");
                      props.clearfil();
                      handleclear();
                    }
                  }}
                >
                  {/* <img src={residental} alt="stay" /> */}
                  <p className="mb-0">Stays</p>
                </div>
                {/* <div className="stay">
                  <img src={car} alt="stay" />
                  <p>Rental car</p>
                </div> */}
                {/* <div
                  className={
                    selection == "Experience" ? "stay stay-bg" : "stay"
                  }
                  onClick={() => {
                    if (selection != "Experience") {
                      props.selection("Experience");
                      setSelection("Experience");
                      navigate("/landing-map");
                      props.clearfil();
                      handleclear();
                    }
                  }}
                >

                  <p className="mb-0">Experience</p>
                </div> */}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 d-flex align-items-center mb-3">
              <div
                className="col_filter_align d-flex align-items-center justify-content-between align-items-center filter-all-categories"
                style={{ width: "100%" }}
              >
                {selection == "Stays" && (
                  <>
                    <div className=" col_card_in custom-width-collapse filter-section-dec-wid custom_arri_depar_picker">
                      <Dropdown className="dd_dropdown_card">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic-2"
                          className="inbtn btn btn_trans_icon p-0 custom_date_picker_sec"
                        >
                          <div
                            className={
                              arrival
                                ? "disabled_statedate_true cmn_date_picker_cursor"
                                : "disabled_statedate_false cmn_date_picker_cursor"
                            }
                            onClick={() => handleArrival()}
                          >
                            <img
                              src={calendar1}
                              alt="arrival"
                              className="arrival-calender_img cmn_logo img-fluid"
                            />
                            <span className="cmn_txt">
                              {startDate
                                ? new Date(startDate).toLocaleDateString()
                                : "Arrival"}
                            </span>
                          </div>
                          <img
                            src={rightarrow1}
                            className="right_arrow_logo img-fluid"
                            alt="arrow"
                          />
                          <div
                            className="departure-sec departure-sec-custom custom-width-collapse"
                            onClick={() => handleDeparture()}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className={
                                  departure
                                    ? "disabled_statedate_true cmn_date_picker_cursor"
                                    : "disabled_statedate_false cmn_date_picker_cursor"
                                }
                                // onClick={() => handleDeparture()}
                              >
                                {/* <img src={rightarrow} alt="rightarrow-logo" /> */}
                                {/* <input
                                  type="text"
                                  // readOnly="true"
                                  // placeholder={
                                  //   startDate && endDate
                                  //     ? `${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`
                                  //     : "Departure"
                                  // }
                                  placeholder="Departure"
                                  value={
                                    endDate
                                      ? new Date(endDate).toLocaleDateString()
                                      : "Departure"
                                  }
                                  className="ps-1 ps-sm-1 ps-md-2 ps-lg-2 ps-xl-3 departure_custom_input_color"
                                /> */}
                                <span className="departure_custom_input_color cmn_txt">
                                  {endDate
                                    ? new Date(endDate).toLocaleDateString()
                                    : "Departure"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dd_menu_shadow_card country_card filter_date_picker_cutom">
                          <Datepicker
                            onChange={(date) => handleDateChange(date)}
                            selectsStart={true}
                            monthsShown={2}
                            minDate={new Date()}
                            shouldCloseOnSelect={false}
                            startDate={startDate}
                            // selected = {startDate}
                            endDate={endDate}
                            inline={true}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <img
                      src={arrow2}
                      alt="arrow"
                      className="img-fluid ryt_arrow"
                    />
                    <div className=" col_card_in custom-width-collapse filter-section-dec-wid">
                      <Dropdown className="dd_dropdown_card">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="btn btn_trans_icon p-0 location_custom_mbl d-flex align-items-center"
                        >
                          {/* <p className="inside_card_heading">Where</p>

                    <p className="mb-0 p-input">
                      <input
                        type="text"
                        id="destination"
                        name="destination"
                        placeholder={viewcountry}
                        className="form-control form_control_card"
                      />
                    </p> */}
                          {/* <img
                      src={calendarimg}
                      alt="arrival"
                      className="arrival-calender_img"
                    />
                    <span className="ps-3">Arrival</span> */}
                          <img
                            src={location1}
                            alt="location"
                            className="location_img cmn_logo img-fluid"
                          />
                          <span className="cmn_txt">
                            {viewcountry ? viewcountry : "Location"}
                          </span>
                          {/* <img src={downarrow} alt="downarrow" /> */}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dd_menu_shadow_card country_card ">
                          <p className="drop_header">Search by region</p>
                          <div className="row">
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={california}
                                    className="img-fluid country_img"
                                    alt="California"
                                    // onClick={() => {
                                    //   handleChange(
                                    //     countryname.Iamflexible,
                                    //     "Iamflexible"
                                    //   );
                                    // }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  California
                                </p>
                              </Dropdown.Item>
                            </div>
                            {/* <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map1}
                                    className="img-fluid country_img"
                                    alt="I'm flexible"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Iamflexible,
                                        "Iamflexible"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  I'm flexible
                                </p>
                              </Dropdown.Item>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item href="#/action-1" className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map2}
                                    className="img-fluid country_img"
                                    alt="Europe"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Europe,
                                        "Europe"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">Europe</p>
                              </Dropdown.Item>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map3}
                                    className="img-fluid country_img"
                                    alt="United Kingdom"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Unitedkingdom,
                                        "Unitedkingdom"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  United Kingdom
                                </p>
                              </Dropdown.Item>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map4}
                                    className="img-fluid country_img"
                                    alt="United States"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Unitedstates,
                                        "unitedstates"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  United States
                                </p>
                              </Dropdown.Item>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map5}
                                    className="img-fluid country_img"
                                    alt="Canada"
                                    onClick={() => {
                                      handleChange(
                                        countryname.canada,
                                        "canada"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">Canada</p>
                              </Dropdown.Item>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map6}
                                    className="img-fluid country_img"
                                    alt="Middle East"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Middleeast,
                                        "Middleeast"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  Middle East
                                </p>
                              </Dropdown.Item>
                            </div> */}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <img
                      src={arrow2}
                      alt="arrow"
                      className="img-fluid ryt_arrow"
                    />
                    <div className=" col_card_in custom-width-collapse filter-section-dec-wid">
                      <Dropdown className="dd_dropdown_card">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="btn btn_trans_icon p-0 inbtn d-flex align-items-center"
                        >
                          {/* <div className="d-flex align-items-center justify-content-between h-100">
                      <div>
                        <p className="inside_card_heading">Who</p>
                        <p className="inside_card_heading_sm">
                          {adult || children
                            ? "Guest:" + (adult + children)
                            : "Add Guests"}
                          {infants ? ",Infants:" + infants : ""}
                          {pets ? ",Pets:" + pets : ""}
                        </p>
                      </div>
                      <Link
                        to="/"
                        className="btn btn_blue pt-1"
                        onClick={filterdata}
                      >
                        <i className="fa fa-search" aria-hidden="true" />
                      </Link>
                    </div> */}
                          <img
                            src={guest1}
                            alt="guest"
                            className="guest_logo cmn_logo img-fluid"
                          />
                          <span className="cmn_txt">
                            {parseFloat(adult) > 0 || parseFloat(children) > 0
                              ? `Guest : ${parseInt(adult) +
                                  parseInt(children)}`
                              : "Guest"}
                            {parseFloat(infants) > 0 &&
                              `, Infants : ${parseInt(infants)}`}
                          </span>
                          {/* <img src={downarrow} alt="downarrow" /> */}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dd_menu_shadow_card who_card">
                          {/* Counter */}
                          <div className="who_main">
                            <div className="who_sub">
                              <div className="row align-items-center">
                                <div className="col-12 col-sm-6">
                                  <p className="who_title">Adults</p>
                                  <p className="who_desc mb-0">
                                    Ages 13 or above
                                  </p>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div className="d-flex align-items-center justify-content-end">
                                    <div
                                      disabled={adult == 0}
                                      onClick={handleadultminus}
                                      className={
                                        adult <= 0
                                          ? "minus_div icon_div disabledbox"
                                          : "minus_div icon_div "
                                      }
                                    >
                                      <i
                                        className="fa fa-minus"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="number_div mx-3">
                                      {adult}
                                    </div>
                                    <div
                                      onClick={handleadultplus}
                                      className={
                                        adult + children > 15
                                          ? "plus_div icon_div disabledbox"
                                          : "plus_div icon_div"
                                      }
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="hr_who" />
                              <div className="row align-items-center">
                                <div className="col-12 col-sm-6">
                                  <p className="who_title">Children</p>
                                  <p className="who_desc mb-0">Ages 2-12</p>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div className="d-flex align-items-center justify-content-end">
                                    <div
                                      disabled={children <= 0}
                                      onClick={handlechildminus}
                                      className={
                                        adult <= 0
                                          ? "minus_div icon_div disabledbox"
                                          : "minus_div icon_div "
                                      }
                                    >
                                      <i
                                        className="fa fa-minus"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="number_div mx-3">
                                      {children}
                                    </div>
                                    <div
                                      onClick={handlechildplus}
                                      className={
                                        adult + children > 15
                                          ? "plus_div icon_div disabledbox"
                                          : "plus_div icon_div"
                                      }
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="hr_who" />
                              <div className="row align-items-center">
                                <div className="col-12 col-sm-6">
                                  <p className="who_title">Infants</p>
                                  <p className="who_desc mb-0">Under 2</p>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div className="d-flex align-items-center justify-content-end">
                                    <div
                                      disabled={infants <= 0}
                                      onClick={handleinfantminus}
                                      className={
                                        adult <= 0
                                          ? "minus_div icon_div disabledbox"
                                          : "minus_div icon_div "
                                      }
                                    >
                                      <i
                                        className="fa fa-minus"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="number_div mx-3">
                                      {infants}
                                    </div>
                                    <div
                                      onClick={handleinfantplus}
                                      className={
                                        infants >= 5
                                          ? "plus_div icon_div disabledbox"
                                          : "plus_div icon_div"
                                      }
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="hr_who" />
                              <div className="row align-items-center">
                                <div className="col-12 col-sm-6">
                                  <p className="who_title">Pets</p>
                                  <p className="who_desc mb-0">
                                    <a
                                      className="who_service_1"
                                      onClick={() => setShowservice(true)}
                                    >
                                      Bringing a service animal?
                                    </a>
                                  </p>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div className="d-flex align-items-center justify-content-end">
                                    <div
                                      disabled={pets <= 0}
                                      onClick={handlepetminus}
                                      className={
                                        adult <= 0
                                          ? "minus_div icon_div disabledbox"
                                          : "minus_div icon_div "
                                      }
                                    >
                                      <i
                                        className="fa fa-minus"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="number_div mx-3">
                                      {pets}
                                    </div>
                                    <div
                                      onClick={handlepetplus}
                                      className={
                                        pets >= 5
                                          ? "plus_div icon_div disabledbox"
                                          : "plus_div icon_div"
                                      }
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="search-btn custom-width-collapse filter-section-dec-wid filter-search-button">
                      <Button variant="primary" onClick={() => filterdata()}>
                        Search
                      </Button>
                      {/* <Button variant="primary" onClick={() => navigate("/landing-map")}>Clear</Button> */}
                    </div>
                  </>
                )}

                {selection == "Experience" && (
                  <>
                    <div className=" col_card_in custom-width-collapse filter-section-dec-wid custom_arri_depar_picker experience_filter_map">
                      <Dropdown className="dd_dropdown_card">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic-2"
                          className="inbtn btn btn_trans_icon p-0 custom_date_picker_sec"
                        >
                          <div
                            className={
                              arrival
                                ? "disabled_statedate_true cmn_date_picker_cursor"
                                : "disabled_statedate_false cmn_date_picker_cursor"
                            }
                            onClick={() => handleArrival()}
                          >
                            <img
                              src={calendar1}
                              alt="arrival"
                              className="arrival-calender_img cmn_logo img-fluid"
                            />
                            <span className="cmn_txt">
                              {startDate
                                ? new Date(startDate).toLocaleDateString()
                                : "Arrival"}
                            </span>
                          </div>
                          <img
                            src={rightarrow1}
                            className="right_arrow_logo img-fluid"
                            alt="arrow"
                          />
                          <div
                            className="departure-sec departure-sec-custom custom-width-collapse ps-2 ps-xl-0 ps-lg-2 filter-section-dec-wid"
                            onClick={() => handleDeparture()}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className={
                                  departure
                                    ? "disabled_statedate_true cmn_date_picker_cursor"
                                    : "disabled_statedate_false cmn_date_picker_cursor"
                                }
                                // onClick={() => handleDeparture()}
                              >
                                {/* <img src={rightarrow} alt="rightarrow-logo" />
                                <input
                                  type="text"
                                  // placeholder={
                                  //   startDate && endDate
                                  //     ? `${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`
                                  //     : "Departure"
                                  // }
                                  value={endDate ? endDate : "Departure"}
                                  className="ps-2 ps-xl-3 ps-lg-2"
                                /> */}
                                <span className="cmn_txt">
                                  {endDate ? endDate : "Departure"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dd_menu_shadow_card country_card filter_date_picker_cutom">
                          <Datepicker
                            onChange={(date) => handleDateChange(date)}
                            selectsStart={true}
                            monthsShown={2}
                            minDate={new Date()}
                            shouldCloseOnSelect={false}
                            startDate={startDate}
                            endDate={endDate}
                            inline={true}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {/* <div className="departure-sec custom-width-collapse ps-2 ps-xl-0 ps-lg-2 filter-section-dec-wid">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={rightarrow} alt="rightarrow-logo" />
                        <input
                          type="text"
                          placeholder={
                            startDate && endDate
                              ? `${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`
                              : "Departure"
                          }
                          className="ps-2 ps-xl-3 ps-lg-2"
                        />
                      </div>
                    </div> */}
                    <img
                      src={arrow2}
                      alt="arrow"
                      className="img-fluid ryt_arrow"
                    />
                    <div className=" col_card_in custom-width-collapse filter-section-dec-wid">
                      <Dropdown className="dd_dropdown_card">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="btn btn_trans_icon p-0 d-flex align-items-center"
                        >
                          {/* <p className="inside_card_heading">Where</p>

                    <p className="mb-0 p-input">
                      <input
                        type="text"
                        id="destination"
                        name="destination"
                        placeholder={viewcountry}
                        className="form-control form_control_card"
                      />
                    </p> */}
                          {/* <img
                      src={calendarimg}
                      alt="arrival"
                      className="arrival-calender_img"
                    />
                    <span className="ps-3">Arrival</span> */}
                          <img
                            src={location1}
                            alt="location"
                            className="location_img cmn_logo img-fluid"
                          />
                          <span className="cmn_txt">
                            {viewcountry ? viewcountry : "Location"}
                          </span>
                          {/* <img src={downarrow} alt="downarrow" /> */}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dd_menu_shadow_card country_card ">
                          <p className="drop_header">Search by region</p>
                          <div className="row">
                            {/* <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map1}
                                    className="img-fluid country_img"
                                    alt="I'm flexible"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Iamflexible,
                                        "Iamflexible"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  I'm flexible
                                </p>
                              </Dropdown.Item>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item href="#/action-1" className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map2}
                                    className="img-fluid country_img"
                                    alt="Europe"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Europe,
                                        "Europe"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">Europe</p>
                              </Dropdown.Item>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map3}
                                    className="img-fluid country_img"
                                    alt="United Kingdom"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Unitedkingdom,
                                        "Unitedkingdom"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  United Kingdom
                                </p>
                              </Dropdown.Item>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map4}
                                    className="img-fluid country_img"
                                    alt="United States"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Unitedstates,
                                        "unitedstates"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  United States
                                </p>
                              </Dropdown.Item>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map5}
                                    className="img-fluid country_img"
                                    alt="Canada"
                                    onClick={() => {
                                      handleChange(
                                        countryname.canada,
                                        "canada"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">Canada</p>
                              </Dropdown.Item>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={map6}
                                    className="img-fluid country_img"
                                    alt="Middle East"
                                    onClick={() => {
                                      handleChange(
                                        countryname.Middleeast,
                                        "Middleeast"
                                      );
                                    }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  Middle East
                                </p>
                              </Dropdown.Item>
                            </div> */}
                            <div className="col-12 col-sm-6 col-md-4">
                              <Dropdown.Item className="p-0">
                                <div className="card card_country">
                                  <img
                                    src={california}
                                    className="img-fluid country_img"
                                    alt="California"
                                    // onClick={() => {
                                    //   handleChange(
                                    //     countryname.Iamflexible,
                                    //     "Iamflexible"
                                    //   );
                                    // }}
                                  />
                                </div>
                                <p className="drop_header_country">
                                  California
                                </p>
                              </Dropdown.Item>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="search-btn custom-width-collapse filter-section-dec-wid filter-search-button">
                      <Button
                        variant="primary"
                        onClick={() => filterdataexperience()}
                        className="experience_filter_map_search_btn"
                      >
                        Search
                      </Button>
                      {/* <Button variant="primary" onClick={() => navigate("/landing-map")}>Clear</Button> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersCopy;

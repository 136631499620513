import React, { useEffect, useState } from "react";
import HostHeader from "./HostHeader";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import cardimg3 from "../../../Assets/images/book1.png";

const ConfirmGuest = () => {
  return (
    <div>
      <HostHeader />
      <div className="container your-guest common-dash-guest py-4 py-lg-5">
        <div className="header-sec">
          <h1 className="mb-2 fw-700 header-txt">
            Get ready for your first guests
          </h1>
          <p className="mb-0 fw-500 header-desc">
            Great hosting starts with great communication. Here’s what your
            guests need to know first.
          </p>
        </div>
        <div className="row align-items-center mt-4 mt-lg-5">
          <div className="order-2 order-sm-1 col-12 col-sm-6 col-md-6">
            <div className="modalright">
              <ul className="ps-0">
                <li className="mb-4">
                  <Link to="">
                    Demo text example one
                    <span className="fa fa-chevron-right ms-2" />
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="">
                    Demo text example one
                    <span className="fa fa-chevron-right ms-2" />
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="">
                    Demo text example one
                    <span className="fa fa-chevron-right ms-2" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="order-1 order-sm-2 col-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center mb-3 mb-lg-0">
            <div className="host-dashboard-img-sec">
              <img src={cardimg3} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConfirmGuest;

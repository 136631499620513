import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import languag from "../../../config/languages.json";
import AddLang from "./AddLang";
const EditProfileModal = ({ show, handleClose }) => {
  const [language, setLanguage] = useState(false);
  return (
    <>
      {language && (
        <AddLang
          onDismiss={() => setLanguage(false)}
        />
      )}
      <Modal show={show} centered className="modal_style">
        <Modal.Header className="border-0 pb-0">
          <button
            type="button"
            className="btn close close_modal"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="edit_profileModal">
            <h4 className="mb-0 fw-700 mb-3">Edit Profile</h4>
            <div className="mb-3">
              <h6 className="label fw-700">About</h6>
              <textarea className="form-control" />
            </div>
            <div className="spokenlang mb-4 mt-4">
              <h6 className="fw-700">Languages I speak</h6>
              <div id="langs" className="profile">
                {/* {choosen &&
                  choosen?.length > 0 &&
                  choosen.map((a) => {
                    return ( */}
                <div className="bordbox badge text-dark px-2 py-2 br-10 me-2 mt-2 mb-2">
                  <span>
                    {/* {a.length > 0 ? a : ""} */}
                    Tamil
                  </span>
                  <Button
                    variant="link"
                    className="p-0 ms-1 fa fa-times nounder text-dark f-14"
                    //   onClick={(e) => removechoose(e)}
                  />
                </div>
                {/* );
                  })} */}
              </div>
              <Button
                variant="link"
                className="p-0 orsnge_y f-14 "
                onClick={() => setLanguage(true)}
              >
                Add more
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="btn btn-theme">
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditProfileModal;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import cardimg1 from "../../../../Assets/images/guest1.png";
import { LuPlusCircle, LuMinusCircle } from "react-icons/lu";
import Form from "react-bootstrap/Form";
import HeaderDash from "../../../Common/HeaderDash";
import HeaderDashOne from "../../../Common/HeaderDashOne";

const SetRules = () => {
  const history = useNavigate();
  const [showedit, setShowEdit] = useState(false);

  return (
    <>
      <HeaderDashOne />
      <div className="container container_custom">
        <div className="house-rules common-dash-guest py-4 py-lg-5">
          <div className="header-sec">
            <h1 className="mb-1 fw-700 header-txt">Set your house rules</h1>
            <p className="mb-0 fw-500 header-desc">
              Guests must agree to your house rules before they book.
            </p>
          </div>
          <div className="row">
            <div className="order-2 order-md-1 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="mt-4">
                <h4 className="mb-0 fw-600">Your house rules</h4>
                <ul className="ps-0 setrule mt-3">
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">
                          Number of guests above 18 allowed
                        </p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">1 Adult</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">
                          Number of children younger than 18 allowed
                        </p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">2 Children</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">
                          Number of infants (under 2 years) allowed
                        </p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">1 Infant</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">
                          Number of pets allowed
                        </p>
                        <div className="d-flex align-items-center">
                          <Button className="cmn-plus-minus">
                            <LuMinusCircle />
                          </Button>
                          <span className="mx-2 fw-600">1 Pet</span>
                          <Button className="cmn-plus-minus">
                            <LuPlusCircle />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">Is smoking allowed?</p>
                        <div className="d-flex align-items-center gap-3">
                          <div className="d-flex align-items-center gap-2">
                            <Form.Check
                              type="checkbox"
                              className="custom-check"
                            />
                            <span className="fw-500">Yes</span>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <Form.Check
                              type="checkbox"
                              className="custom-check"
                            />
                            <span className="fw-500">No</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="mb-0 rule-heading">Events allowed?</p>
                        <div className="d-flex align-items-center gap-3">
                          <div className="d-flex align-items-center gap-2">
                            <Form.Check
                              type="checkbox"
                              className="custom-check"
                            />
                            <span className="fw-500">Yes</span>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <Form.Check
                              type="checkbox"
                              className="custom-check"
                            />
                            <span className="fw-500">No</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <p className="mb-0 rule-heading">Additional rules:</p>
                    <div
                      className={
                        !showedit
                          ? "d-flex justify-content-between align-items-center additional-rules"
                          : "d-none"
                      }
                    >
                      <p className="mb-0 fw-500 f-14">None set</p>

                      <a
                        href="#"
                        className="edit f-12"
                        onClick={() => setShowEdit(true)}
                      >
                        Edit
                      </a>
                    </div>

                    <div
                      className={!showedit ? "d-none" : "bord br-10 p-3 mt-2"}
                    >
                      <h6 className="d-flex justify-content-between">
                        Additional rules{" "}
                        <button
                          onClick={() => setShowEdit(false)}
                          className="closebtn"
                        >
                          X
                        </button>{" "}
                      </h6>
                      <p className="f-12 mb-2 text-muted">
                        You can include any requirements around safety concerns,
                        shared space rules, and your community regulations.
                      </p>
                      <textarea className="form-control" />
                      <hr />
                      <div className="d-flex justify-content-between">
                        <Button variant="Link">Cancel</Button>
                        <Button variant="" className="btn-theme">
                          Save
                        </Button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="order-1 order-md-2 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center mt-3 mt-lg-0">
              <img src={cardimg1} className="img-fluid guest" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-start mt-2">
            <button className="btn btn-theme">Save</button>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default SetRules;

import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import CameraModal from "../../Common/Modals/CameraModal";
import { Form } from "react-bootstrap";
import calendar from "../../../Assets/images/CreateRoom/calendar.png";

const LastQuest = (props) => {
  const [showcammodal, setShowCamModal] = useState(false);
  // const [legalList, setLegalList] = useState([]);
  const [legalList, setLegalList] = useState("");
  console.log("sadfhaksdfkasdfh", legalList);

  const setData = (e) => {
    console.log("valasdfasdfasdf", e.target.value);
    let data = e.target.value;
    setLegalList(data);
    props.onLastQuest(data);
  };

  useEffect(() => {
    setLegalList(
      localStorage.getItem("legal") ? localStorage.getItem("legal") : legalList
    );
    localStorage.getItem("legal")
      ? props.onLastQuest(localStorage.getItem("legal"))
      : localStorage.removeItem("legal");
  }, []);

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 cancellationPolicy">
      {showcammodal && <CameraModal onDismiss={() => setShowCamModal(false)} />}
      <div className="header-txt">
        <h2 className="fw-800">Do you have a cancellation policy?</h2>
        <p className="des-txt">
          Choose one of the following for your cancelation policy
        </p>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-sm-12 col-md-6 col-lg-8 order-2 order-md-1">
          <div className="">
            <h5 className="fw-700 mb-2 pb-1">Choose cancellation policy</h5>
            {/* {props.data &&
            props.data.content.map((val) => (
              <div>
                {val?.status != "Inactive" && (
                  <div className="mb-2">
                    <Form.Check
                      type="checkbox"
                      id={`${val.type}`}
                      label={`${val.type}`}
                      name="cancellation"
                      value={val.type}
                      checked={legalList.includes(val.type)}
                      onChange={(e) => {
                        setData(e);
                      }}
                    />
                  </div>
                )}
              </div>
            ))} */}


          {props.data &&
            props.data.content.map((val) => (  <div className="mb-2">
              <Form.Check
                type="checkbox"
                id={`${val.type}`}
                label={`${val.type}`}
                name="cancellation"
                value={val.type}
                checked={legalList.includes(val.type)}
                onChange={(e) => {
                  setData(e);
                }}
              />
            </div>))}

            {/* <div className="mb-2">
              <Form.Check
                type="checkbox"
                id="Moderate"
                label="Moderate"
                name="cancellation"
              />
            </div>
            <div>
              <Form.Check
                type="checkbox"
                id="Strict"
                label="Strict"
                name="cancellation"
              />
            </div> */}
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-4 order-1 order-md-2 d-flex align-items-center justify-content-center mb-4 mb-md-0">
          <img src={calendar} className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default LastQuest;

import React, { useState, useEffect } from "react";
import { Offcanvas, Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../Assets/images/landingPage/flutterpadlogo.png";
import facebook from "../../Assets/images/landingPage/facebook.png";
import instagram from "../../Assets/images/landingPage/instagram.png";
import twitter from "../../Assets/images/landingPage/twitter.png";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import {
  getalllandcmsdata,
  getjoinushooks,
  getUserDatas,
  getUserEmail,
} from "../../Hooks/useUserHook";
import { toast, Toaster } from "react-hot-toast";
import Login from "./Modals/LoginModal";
const Footer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [email, setEmail] = useState("");
  const [showmap, setShowmap] = useState(false);

  const handleClosemap = () => setShowmap(false);
  const handleShowmap = () => setShowmap(true);
  const [showlog, setShowLog] = useState(false);
  const [userDatas, setUserDatas] = useState({});
  const [social, setSocial] = useState({});
  const [cmsdata, setCmsData] = useState([])

  let navigate = useNavigate();

  useEffect(() => {
    const data = getUserEmail();
    setEmail(data);
    setUserDatas(getUserDatas());
    if (data) {
      // setShowLog(true)
    }
    getjoinushooks().then((res) => {
      console.log("Get join us hooks", res?.record?.details);
      setSocial(res?.record?.details);
    });
  }, [showlog]);


  useEffect(() => {
    async function fetchdata() {
      const res = await getalllandcmsdata();
      console.log("res cmsdata", res)
      if (res?.data?.type == "success") {
        setCmsData(res?.data?.data)
      }
    }
    fetchdata();
  }, [])



  const cmsFilter = (data) => {
    try {
      let filterdata = cmsdata?.find(
        (el) => el.Identifier == data && el?.status == "active"
      );
      console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
      return filterdata;
    } catch (e) {
      console.log("cmsFilter_err", e);
    }
  };

  const checkUserLogin = async (type) => {
    try {
      console.log("checkuserlogin", type);
      if (email) {
        if (
         // userDatas?.govtproof?.length > 0 &&
        // userDatas?.govtproof[0]?.verification == "accepted"
        userDatas?.govtidverification == "accepted"
        ) {
          if (parseFloat(userDatas?.hostsubscribedaycount) > 0) {
            if (type == "room") {
              window.location.href = window.location.origin + `/host/${email}`;
            } else if (type == "experience") {
              window.location.href =
                window.location.origin + `/host-experience/${email}`;
            }
          } else {
            // toast.error("Need Host Subscription!..");
            toast.error("Purchase the subscription plan and then provide all your room details")
          }
        } else {
          if(userDatas?.govtidverification == "rejected") {
            toast.error("Admin reject your government id & so please reupload your id");
            window.location.href = window.location.origin + `/account/personal-info`;
          }
          if(userDatas?.govtidverification == "pending") {
            // toast.error("need your government id");
            toast.error("Still your personal details not provided, Please fill those details");
            window.location.href = window.location.origin + `/account/personal-info`;
          }
        }
      } else {
        setShowLog(true);
      }
    } catch (e) {
      console.log("checkUserLogin_err", e);
    }
  };

  return (
    // <div className="footer">
    //   {/* <div className='container container_custom'>
    //      <div className='row align-items-center'>
    //         <div className='col-md-3'>
    //             <ul>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Help center</a></li>
    //                 <li><a href="#">Aircover</a></li>
    //                 <li><a href="#">Safty Information</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>

    //             </ul>
    //         </div>
    //         <div className='col-md-3'>
    //         <ul>
    //                 <li><a href="#">Community</a></li>
    //                 <li><a href="#">Help center</a></li>
    //                 <li><a href="#">Aircover</a></li>
    //                 <li><a href="#">Safty Information</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>

    //             </ul>
    //         </div>
    //         <div className='col-md-3'>
    //         <ul>
    //                 <li><a href="#">Hosting</a></li>
    //                 <li><a href="#">Help center</a></li>
    //                 <li><a href="#">Aircover</a></li>
    //                 <li><a href="#">Safty Information</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>

    //             </ul>
    //         </div>
    //         <div className='col-md-3'>
    //         <ul>
    //                 <li><a href="#">About</a></li>
    //                 <li><a href="#">Help center</a></li>
    //                 <li><a href="#">Aircover</a></li>
    //                 <li><a href="#">Safty Information</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>

    //             </ul>
    //         </div>

    //      </div>
    //   </div> */}
    //   {/* <div className='showmap'>
    //      <button className='showmap' onClick={handleShowmap}><span className="fa fa-map"></span>Show map</button>
    //   </div> */}

    //   <Modal
    //     show={showmap}
    //     onHide={handleClosemap}
    //     centered
    //     scrollable
    //     className="modal_style"
    //   >
    //     <Modal.Header className="border-0 pb-0">
    //       <button
    //         type="button"
    //         className="btn close close_modal"
    //         onClick={() => handleClosemap()}
    //       >
    //         <span aria-hidden="true">×</span>
    //         <span class="sr-only">Close</span>
    //       </button>
    //     </Modal.Header>
    //     <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
    //     <Modal.Footer>
    //       <Button variant="secondary" onClick={handleClosemap}>
    //         Close
    //       </Button>
    //       <Button variant="primary" onClick={handleClosemap}>
    //         Save Changes
    //       </Button>
    //     </Modal.Footer>
    //   </Modal>
    //   <div className="fixedfooter">
    //     <div className="container container_custom copy">
    //       <div className="row align-items-center justify-content-between">
    //         <div className="col-md-6 my-md-3 my-2">
    //           <ul className="mb-0">
    //             <li>&copy; 2022 Lorem, Inc</li>
    //             <li>
    //               <span>.</span>
    //               <a href="#"> Privacy</a>
    //             </li>
    //             <li>
    //               <span>.</span>
    //               <a href="#">Terms</a>
    //             </li>
    //             <li>
    //               <span>.</span>
    //               <a href="#"> Sitemap</a>
    //             </li>
    //             <li>
    //               <span>.</span>
    //               <a href="#">Company Details</a>
    //             </li>
    //           </ul>
    //         </div>
    //         <div className="col-md-6 text-md-end my-md-3 my-2">
    //           <ul className="mb-0">
    //             <li>
    //               <span className="fa fa-globe me-2" />English(IN)
    //             </li>
    //             <li>INR</li>
    //             <li onClick={handleShow}>
    //               Support & Resource <i className="fa fa-chevron-up ms-1" />
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Offcanvas
    //     show={show}
    //     onHide={handleClose}
    //     placement="bottom"
    //     className="footer"
    //   >
    //     <Offcanvas.Header closeButton className="pb-0" />
    //     <Offcanvas.Body>
    //       <div className="container container_custom">
    //         <div className="row">
    //           <div className="col-md-3 col-sm-6">
    //             <ul>
    //               <li>Support</li>
    //               <li>
    //                 <a href="#">Help center</a>
    //               </li>
    //               <li>
    //                 <a href="#">FlutterPads</a>
    //               </li>
    //               <li>
    //                 <a href="#">Safty Information</a>
    //               </li>
    //               <li>
    //                 <a href="#">Supporting people with disabilities</a>
    //               </li>
    //               <li>
    //                 <a href="#">Cancellation options</a>
    //               </li>
    //               <li>
    //                 <a href="#">Our COVID-19 Response</a>
    //               </li>
    //               <li>
    //                 <a href="#">Report a neighbourhood concern</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="col-md-3 col-sm-6">
    //             <ul>
    //               <li>Community</li>
    //               <li>
    //                 <a href="#">spareresort.org: disaster relief housing</a>
    //               </li>
    //               <li>
    //                 <a href="#">Support Afghan refugees</a>
    //               </li>
    //               <li>
    //                 <a href="#">Combating discrimination</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="col-md-3 col-sm-6">
    //             <ul>
    //               <li>Hosting</li>
    //               <li>
    //                 <a href="#">Try hosting</a>
    //               </li>
    //               <li>
    //                 <a href="#">AirCover for Hosts</a>
    //               </li>
    //               <li>
    //                 <a href="#">Explore hosting resources</a>
    //               </li>
    //               <li>
    //                 <a href="#">Visit our community forum</a>
    //               </li>
    //               <li>
    //                 <a href="#">How to host responsibly</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="col-md-3 col-sm-6">
    //             <ul>
    //               <li>About</li>
    //               <li>
    //                 <a href="#">Newsroom</a>
    //               </li>
    //               <li>
    //                 <a href="#">Learn about new features</a>
    //               </li>
    //               <li>
    //                 <a href="#">Letter from our founders</a>
    //               </li>
    //               <li>
    //                 <a href="#">Careers</a>
    //               </li>
    //               <li>
    //                 <a href="#">Investors</a>
    //               </li>
    //               <ul className="ul-inline-block">
    //                 <li>
    //                   <a href="#">
    //                     <span className="fa fa-facebook" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="#">
    //                     <span className="fa fa-twitter" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="#">
    //                     <span className="fa fa-instagram" />
    //                   </a>
    //                 </li>
    //               </ul>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </Offcanvas.Body>
    //   </Offcanvas>
    // </div>
    <>
      {showlog && (
        <Login
          logs={"login"}
          onDismiss={() => {
            setShowLog(false);
          }}
        />
      )}
      <div className="footer-section">
        <div className="container footer-custom-container footer-head">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
              <div className="d-flex block_mob_sel_new">
                <div className="logo-section">
                  <img src={logo} alt="logo" />
                </div>
                <div className="d-flex flex-column block_mob_sel_new_padin ps-3 ps-xl-5 ps-lg-5 ps-md-5 ps-sm-5 pt-3">
                  <div className="content-des">
                    {cmsFilter("land_banner06")?.Heading ? cmsFilter("land_banner06")?.Heading : ""}
                  </div>
                  <div className="d-flex pt-4 align-items-center">
                    {/* <span className="social-net pe-4">Social</span> */}
                    {/* <img
                      src={instagram}
                      alt="instagram"
                      className="insta-img pe-2"
                    /> */}
                    {/*<img
                      src={facebook}
                      alt="facebook"
                      className="fb-img pe-2"
                    />
                    <img src={twitter} alt="twitter" className="twitter-img" /> */}
                    {console.log(
                      "window.location.origin",
                      window.location.origin,
                      social?.instagram
                    )}
                    {/* href={`/${social?.instagram}`} */}
                    {/* <a href={cmsFilter("land_banner06")?.Content ? cmsFilter("land_banner06")?.Content : ""}  target="_blank"> */}
                    <a href={social?.instagram} target="_blank">
                      <span className="social-icon me-2">
                        <GrInstagram size={18} />
                      </span>
                    </a>
                    <a href={social?.facebook} target="_blank">
                    {/* <a href={cmsFilter("land_banner06")?.Content1 ? cmsFilter("land_banner06")?.Content1 : ""} target="_blank"> */}
                      <span className="social-icon me-2">
                        <FaFacebookF size={18} />
                      </span>
                    </a>
                    {/* <a href={cmsFilter("land_banner06")?.Content2 ? cmsFilter("land_banner06")?.Content2 : ""} target="_blank"> */}
                    <a href={social?.twitter} target="_blank">

                      <span className="social-icon">
                        <FiTwitter size={18} />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <div className="pt-3">
                <p className="header-text pb-4">FlutterPads</p>

                {/* <p
                  to="/"
                  className="sub-content"
                  onClick={() => checkUserLogin("room")}
                >
                  Host a Room
                </p> */}
                <p
                  // to="/"
                  className="sub-content"
                  onClick={() => checkUserLogin("room")}
                >
                  Host a Room
                </p>
                <Link to='/privacy-policy'
                  // to="/"
                  className="sub-content"
                  // onClick={() => checkUserLogin("room")}
                >
                  Privacy Policy
                </Link>
                {/* <p
                  // to="/"
                  className="sub-content"
                  // onClick={() => checkUserLogin("room")}
                >
                  Host an Experience
                </p>
                <Link
                  to="/terms-of-service"
                  // to="/"
                  className="sub-content"
                  // onClick={() => checkUserLogin("room")}
                >
                  Terms & Conditions
                </Link>
                {/* <p
                  to="/"
                  className="sub-content"
                  onClick={() => checkUserLogin("experience")}
                >
                  Host an Experience
                </p> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <div className="pt-3">
                <p className="header-text pb-4">Support</p>
                <Link to="/faq" className="sub-content">
                  FAQ
                </Link>
                {/* <Link to="/" className="sub-content">
                  Blog
                </Link> */}
                <Link to="/about-us" className="sub-content">
                  About
                </Link>
                <Link to="/terms-of-service" className="sub-content">
                  Terms of Service
                </Link>
                <Link to="/contact-us" className="sub-content">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <hr className="text-white" /> */}
        <div className="bottom-footer py-4 px-0 container justify-content-between footer-custom-container">
          <div className="row">
            <dic className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="copyright-content">
                {/* &copy; {cmsFilter("land_banner06")?.Content3 ? cmsFilter("land_banner06")?.Content3 : ""} */}
                &copy; 2024 FlutterPads Holding Inc. All rights reserved
              </div>
            </dic>
        
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

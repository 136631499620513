
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { API_URL } from '../../../config/env';


const EditAmenities = (props) => {

    // Editamenities
    console.log('props jije', props)
    console.log('localStorage.getItem("Editamenities")', localStorage.getItem("Editamenities"))
    const [amenitiesList, setAmenitiesList] = useState(localStorage.getItem("Editamenities") ?
        JSON.parse(localStorage.getItem("Editamenities")) : []);
    console.log('amenitiesList', amenitiesList)
    const [selectedIndex, setSelectedIndex] = useState(localStorage.getItem("EditamenitiesIndex") ? JSON.parse(localStorage.getItem("EditamenitiesIndex")) : []);

    var category = [];
    var index = [];

    const setData = async (val ,ind, path) => {
        console.log('amenitiesList', amenitiesList == 'object', amenitiesList)
        category =amenitiesList != undefined ? typeof amenitiesList == 'object' ? amenitiesList : JSON.parse(amenitiesList) : [];
        index = amenitiesList != undefined ? typeof selectedIndex == 'object' ? selectedIndex : JSON.parse(selectedIndex) : [];
        console.log('category_data', category, 'index_data', index, val);
        console.log('!category?.includes(val?.type)', category, index,!index?.includes(val?.type), index?.includes(val?.type))

        if (!index?.includes(val?.type)) {
            console.log("val in include eiji", val, val?.type, typeof category, category?.length);
            category.push(val)
            index?.push(val?.type);
            setAmenitiesList(category);
            setSelectedIndex(index);
            console.log('111 category datasssssssss', category, index)
            localStorage.setItem("EditamenitiesIndex", JSON.stringify(index))
            localStorage.setItem("Editamenities", JSON.stringify(category))
        } else if (index?.includes(val?.type)) {
            let inx = index?.indexOf(val?.type);
            console.log('splice', inx);
            if (inx > -1) {
                category?.splice(inx, 1);
                index?.splice(inx, 1);
                setAmenitiesList(category);
                setSelectedIndex(index);
            }
            console.log('category datasssssssss', category, index)
            localStorage.setItem("EditamenitiesIndex", JSON.stringify(index));
            localStorage.setItem("Editamenities", JSON.stringify(category));
        }
    }


    console.log('localStorage.getItem("amenities")', localStorage.getItem("amenities"))
    useEffect(() => {
        props?.onstatus();
        (localStorage.getItem("Editamenities"))? props?.onGetAmenities(JSON.parse(localStorage.getItem("Editamenities"))) : localStorage.removeItem("EditroomCategoryIndex")


        setAmenitiesList(localStorage.getItem("Editamenities"))
    }, [selectedIndex, amenitiesList, localStorage.getItem("Editamenities"), localStorage.getItem("EditamenitiesIndex")]);


    useEffect(() => {
        if (props?.roomdata) {
            let amientiesdata = props?.roomdata?.amenities;
            let indexdata = [];
            let amientiesdetails = []
            amientiesdata && amientiesdata?.length > 0 && amientiesdata?.map((val) => {
                if (val?.type) {
                    console.log('vvvvvvvvvvv', val?.type)
                    indexdata.push(val?.type)
                }
            })
            amientiesdata && amientiesdata?.length > 0 && amientiesdata?.map((val) => {
                if (val?.type) {
                    console.log('vvvvvvvvvvv', val)
                    amientiesdetails.push(val)
                }
            })
            let setIndex = localStorage.getItem("EditamenitiesIndex") != null ? localStorage.getItem("EditamenitiesIndex") : props?.roomdata?.amenities ? JSON.stringify(indexdata) : selectedIndex;
            let setAmienties = localStorage.getItem("Editamenities") != null ? localStorage.getItem("Editamenities") : 
            props?.roomdata?.amenities ? props?.onGetAmenities(amientiesdetails) :
            console.log('indexdata_indexdata', indexdata, localStorage.getItem("EditamenitiesIndex"), localStorage.getItem("Editamenities"), setIndex);
            setSelectedIndex(setIndex)
            setAmenitiesList(setAmienties)

        }
        
    }, [localStorage.getItem("EditamenitiesIndex"), props])
    console.log("selectedIndex", selectedIndex)

    return (
        <div className='col-md-112'>
            <div className='rightcont rightcont placetype mb-5'>
                <Scrollbars style={{ height: "calc(100vh - 320px)" }} className="scroll">

                    <h4 className='mb-2 ms-3'>Do you have any standout aminities?</h4>
                    <div className='row  px-3  align-items-center pb-5 mt-4' data-aos="fade-up">
                        {console.log('props.data', props.data.content)}
                        {props?.data && props?.data?.content.map((val, index) =>
                            <>
                                {console.log("indedx : ", val, val?.type)}
                                {val?.status != "Inactive" && <div className={(selectedIndex && selectedIndex?.includes(val?.type)) ? 'col-md-4 amenities active' : 'col-md-4 amenities '}>
                                    {/* {console.log("indedx : " , index ,selectedIndex.includes(index))} */}
                                    <button onClick={() => { setData(val, index, props.data.path); props?.Filter("amenities") }} className=''>
                                        <img src={API_URL + props.data.path + "/" + val.image} />
                                        <p>{val.type}</p>
                                    </button>
                                </div>}
                            </>
                        )}


                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

export default EditAmenities
import React, { Component, useEffect, useState } from "react";

import Scrollbars from "react-custom-scrollbars";
import { Button, Form } from "react-bootstrap";

import room1 from "../../../Assets/images/hostroom/roombig1.png";
import room2 from "../../../Assets/images/hostroom/roombig2.png";
import room3 from "../../../Assets/images/hostroom/roombig3.png";
import room4 from "../../../Assets/images/hostroom/roombig4.png";
import room5 from "../../../Assets/images/hostroom/roombig5.png";
import guest1 from "../../../Assets/images/landingPage/card/guest.png";
import bed1 from "../../../Assets/images/landingPage/card/bed.png";
import bathroom1 from "../../../Assets/images/landingPage/card/bathroom.png";
import bed2 from "../../../Assets/images/landingPage/bed.png";
import bathroom2 from "../../../Assets/images/landingPage/bath.png";
import amenity from "../../../Assets/images/hostroom/amenity.png";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL, GOOGLE_MAP_API_KEY } from "../../../config/env";
import { getUserDatas, getUserEmail, getsubscriptiondetailshooks } from "../../../Hooks/useUserHook";
import ShowAllImg from "../../Common/Modals/ShowAllImg";
import AvailCont from "../../Common/AvailCont";
import { Freeze } from "react-freeze";
import RoomMap from "../../Maps/RoomMap";
import { getAdminDetails } from "../../../Hooks/useUserHostingHook";

const CheckList = (props, { shouldSuspendRendering }) => {
  const [previewURL, setPreviewURL] = useState("");
  const [instantBooking, setInstantBooking] = useState(false);
  const [userdata, setUserDatas] = useState(getUserDatas());
  const [showallimg, setShowAllImg] = useState(false);

  const [cover, setCover] = useState(false);
  const [placeoffer, setPlaceOffer] = useState(false);
  const [healthsafety, setHealthSafety] = useState(false);
  const [avail, setAvail] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [roomDetails, setRoomDetails] = useState({});
  const [review, setReview] = useState(false);
  const [bedCount, setBathCount] = useState(0);
  const [bedRoomCount, setBedRoomCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rentAmount, setRentAmount] = useState(0);
  const [daysDiff, setDaysDiff] = useState(0);
  const [bookingDetails, setBookingDetails] = useState({});
  const [adminDetails, setAdminDetails] = useState([]);
  const [email, setEmail] = useState("");
  const [isRefundable, setIsRefundable] = useState(false);
  const [guestCount, setGuestCount] = useState({});
  const [userRoomDatas, setUserRoomDatas] = useState({});
  const [roomId, setRoomId] = useState("");
  const [serviceFee, setServiceFee] = useState(0);
  const [serviceFeeTotal, setServiceFeeTotal] = useState(0);
  const [guest, setGuest] = useState(0);
  const [overAllRating, setOverAllRating] = useState({});
  const [ratings, setRatings] = useState({});
  const [hostDetails, setHostDetails] = useState({});
  const [showlog, setShowLog] = useState(false);
  // 9.9252007 78.1197754

  // lat: 10.99835602,lng: 77.01502627,
  const [position, setPosition] = useState({
    lat: 9.9252007,
    lng: 78.1197754,
  });
  console.log("possitiondata", position, serviceFeeTotal)
  const [coordsLoaded, setCoordsLoaded] = useState(false);
  const [exludedates, setExcludedates] = useState([]);
  const [specialdates, setSpecialdates] = useState([]);
  const [daterefresh, setDaterefresh] = useState(true);
  const [givereview, setGiveReview] = useState(false);
  const [isExcluded, setExcluded] = useState(false);
  const [userdatas, setUserdatas] = useState({});
  const [defaultprice, setDefaultprice] = useState(0);
  const [suboffer, setSuboffer] = useState(null);
  const [subofferamount, setSubofferamount] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [hostingdata, setHostingData] = useState([]);
  const [normalrentamount, setNormalRentAmount] = useState(0);
  const [carddata, setCardData] = useState([]);
  const [normalpercentage, setNormalPercentage] = useState("");
  const [guestcountdata, setGuestCountData] = useState(0);

  console.log("propspropspropspropsprops",normalpercentage, props, props.data.photos)
  useEffect(() => {
    console.log('props?.data?.photos?.length', props?.data?.photos, props?.data?.photos?.length, JSON.parse(localStorage.getItem("photos"))[0], JSON.parse(localStorage.getItem("photos")), localStorage.getItem("photos")[0])
    let data = props?.data?.photos != undefined ?
      props?.data?.photos[0] : JSON.parse(localStorage.getItem("photos"))[0];
    console.log("asdfasdfasdf", data)
    // const imageurl = URL.createObjectURL(data);
    // setPreviewURL(imageurl);
    props.onGetCheckList(instantBooking);
console.log("daa3adsadfdas",  JSON.parse(localStorage.getItem('imageUrl'))[0])
    // document.getElementById('img-fluid img_full_height').src = JSON.parse(localStorage.getItem('imageUrl'))[0]
  }, [instantBooking]);

  const handleDateChange = (date) => { };
  const guestcount = (val) => {
    setGuestCount(val);
  };
  const cancellationPolicy = (data) => {
    setIsRefundable(data);
  };

  useEffect(() => {
if(localStorage.getItem("address")) {
  const experienceDatas = JSON.parse(localStorage.getItem("address"));
  const address = experienceDatas?.street + "," + experienceDatas?.suite + "," + experienceDatas?.city + "," + experienceDatas?.state;
  getCoordinates(address);
}
  }, [localStorage.getItem("address")]);


  const getCoordinates = async (address) => {
    if(address != undefined && address != "undefined") {
        console.log("address in geocoding :",address, GOOGLE_MAP_API_KEY);
        await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + '&key=' + GOOGLE_MAP_API_KEY)
        .then(response => response.json())
        .then((data) => {
            console.log('getCoordinates data', data)
            if (data.results[0]) {
                const latitude = data.results[0].geometry.location.lat;
                const longitude = data.results[0].geometry.location.lng;
                //console.log("complete data from geocoding fetch", data, "lat lng from room address", { latitude, longitude });
                setPosition({ lat: latitude, lng: longitude });
                setCoordsLoaded(true);
            }
        })
    }

}

useEffect(() => {
  console.log("props data", roomDetails.price);
  const mail = getUserEmail();
  setEmail(mail);

  // getHostingId();
  getData();
}, [showlog]);

const getData = () => {
  getadmindetails();
};

useEffect(() => {
  async function fetchData() {
    let data = await getsubscriptiondetailshooks();
    console.log("useEffect_getsubscriptiondata", data?.record, data?.record?.find((el) => el["type"] == "Guest"));
    let rec = data?.record?.find((el) => el["type"] == "Guest");
    let percentage = rec["offerpercentage"];
    console.log('percentagedta', percentage)
    setNormalRentAmount(localStorage.getItem("price"))
    setNormalPercentage(percentage);
    console.log('adminDetailsadminDetailsadminDetails', adminDetails)
    var fee = adminDetails?.reduce(function(acc, obj) {
      return acc + (Number(localStorage.getItem("price")) * parseFloat(obj.percentage)) / 100;
    }, 0);
    console.log("asdfasdfasdfar4efds", fee)
    setServiceFeeTotal(fee);
    setRentAmount(localStorage.getItem("price"))
  }
  fetchData();
}, [props, adminDetails]);


const getadmindetails = () => {
  getAdminDetails().then((data) => {
    console.log("admin details :", data);
    setAdminDetails(data);
    const serviceFeePercentage = data.filter((e) => e.name == "servicefee");
    console.log("serviceFeePercentage", serviceFeePercentage[0]);
    // setServiceFee(serviceFeePercentage[0]?.percentage);
  });
};


console.log('userdata datasgal', userdata)


  return (
    <>
      {/* <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="rightcont placetype mb-5 checklist">
            <div
              className="row px-3 align-items-center height-100"
              data-aos="fade-up"
            >
              <Scrollbars
                style={{ height: "calc(100vh - 320px)" }}
                className="scroll"
              >
                <div className="bg-white pb-5 br-10">
                  <img
                    src={previewURL && previewURL}
                    className="img-fluid checkout_listing_img"
                  />
                  <h3 className="place-title mt-3">{props.data.title}</h3>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <h6 className="fw-500">
                      {props.data.privacyType} in{" "}
                      {props.data.propertyTypeGroup.type} hosted by{" "}
                      {userdata?.firstName} {userdata?.lastName}
                    </h6>

                  </div>
                  <hr />
                  <p className="f-14 break_tetx_new_room">
                    {props?.data?.floorPlan.map((data) => {
                      if (data?.status == "Active") {
                        return (
                          <>
                            {data.type}:&nbsp;{data.count}
                            <span>&nbsp;|&nbsp;</span>
                          </>
                        );
                      }
                    })}
                  </p>
                  <hr />
                  <p className="f-14">{props.data.descriptionContent}</p>
                  <hr />
                  <div>
                    <Form.Check
                      type="switch"
                      onChange={(e) => {
                        console.log("e.target.checked", e.target.checked);
                        setInstantBooking(e.target.checked);
                      }}
                      id="custom-switch"
                      className="ps-0 align-items-center"
                      label="Instant Booking"
                    />
                  </div>
                  <hr />
                  <h6 className="fw-700">Amenities</h6>
                  <div className="">
                    {props.data.amenities.map((data) => (
                      <>
                        <span className="badge bg-secondary me-1">
                          {data.type}
                        </span>
                      </>
                    ))}
                  </div>
                  <hr />
                  <h6 className="fw-700">Location</h6>
                  <p>
                    {props.data.address.street},{props.data.address.area},
                    {props.data.address.city},{props.data.address.state},
                    {props.data.address.postcode}.
                  </p>
                  <p className="f-12">
                    We’ll only share your address with guests who are booked as
                    outlined in our
                    <a href="/privacy-policy" className="orsnge_y">
                      {" "}
                      <b> Privacy Policy</b>{" "}
                    </a>{" "}
                    .
                  </p>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div> */}
      {showallimg && (
        <ShowAllImg
          // photos={room1}
          // photosPath={roomDetails.photosPath}
          onDismiss={() => setShowAllImg(false)}
        />
      )}
      <div className="col-lg-12 col-xl-12 col-md-12">
        <div className="preview-listing expdetails">
          <div className="header-sec">
            <h2 className="fw-700">Preview your listing</h2>
            <p className="des-txt">
              This is a preview of your listing.
              <span className="orange-txt">
                It is not yet live on the FlutterPads site.
              </span>
            </p>
            <div className="mt-4 d-flex align-items-center gap-3">
              <Button className="CreateListing_btn cmn-bg-orange" onClick={() => props?.create()}>
                Create Listing
              </Button>
              <Button className="back_btn cmn-bg-black-bdr" onClick={() => props?.back()}>Back</Button>
            </div>
          </div>

          <hr className="header-bdm-hr" />
          <div className="host_addr mt-3">
            <div>
              <p className="detail_heading">{props?.data?.title}</p>
              <h5 className="loc_Details mb-0">
                {console.log('props.data.address.street', props.data.address.street, props.data.address.street != "")}
                <span>  {props.data.address.street}{props.data.address.street != "" ? "," : ""}{props.data.address.area},
                  {props.data.address.city},{props.data.address.state},
                  {props.data.address.postcode}.</span>
                <span className="member_price">${Math.ceil(
                      Number(normalrentamount) -
                        (Number(normalrentamount) * Number(normalpercentage)) /
                          100
                    )

                }/month Member-Price</span>
              </h5>
            </div>
            <div>
              <img
                src={require("../../../Assets/images/favicon1.png")}
                className="img-fluid logo"
              />
            </div>
          </div>
          <div className="detail_images mt-3">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3 px-1 showinthis767">
                {
                  console.log('sadfasdfasdfasdf',
                  props?.data?.photos != undefined ?
                  props?.data?.photos[0] : (JSON.parse(localStorage.getItem("photos"))[0]))
                }
                <img src={JSON.parse(localStorage.getItem('imageUrl'))[0]} className="img-fluid img_full_height" />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="row sub-image">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 mb-lg-0 col">
                    <div className="px-1">
                    <img src={JSON.parse(localStorage.getItem('imageUrl'))[1]} className="img-fluid img_half_height"/>
                      {/* <img src={(previewURL != "" && previewURL != undefined) && previewURL && URL.createObjectURL(props?.data?.photos != undefined ?
                        props?.data?.photos[1] : JSON.parse(localStorage.getItem("photos"))[1])} className="img-fluid img_half_height" /> */}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 mb-lg-0 col">
                    <div className="mb-2 px-1">
                    <img src={JSON.parse(localStorage.getItem('imageUrl'))[2]} className="img-fluid img_half_height" />
                      {/* <img src={(previewURL != "" && previewURL != undefined) && previewURL && URL.createObjectURL(props?.data?.photos != undefined ? 
    props?.data?.photos[2] :  JSON.parse(localStorage.getItem("photos"))[2])} className="img-fluid img_half_height" /> */}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 mb-lg-0 col">
                    <div className="mb-2 px-1">
                    <img src={JSON.parse(localStorage.getItem('imageUrl'))[3]} className="img-fluid img_half_height" />
                      {/* <img src={(previewURL != "" && previewURL != undefined) && previewURL && URL.createObjectURL(props?.data?.photos != undefined ? 
    props?.data?.photos[3] :  JSON.parse(localStorage.getItem("photos"))[3])} className="img-fluid img_half_height" /> */}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 mb-lg-0 col">
                    <div
                      className="show-all-img px-1"
                      onClick={() => setShowAllImg(true)}
                    >
                      <img src={JSON.parse(localStorage.getItem('imageUrl'))[4]} className="img-fluid img_half_height img_half_last_img" />
                      {/* <img
                        src={(previewURL != "" && previewURL != undefined) && previewURL && URL.createObjectURL(props?.data?.photos != undefined ? 
                          props?.data?.photos[4] :  JSON.parse(localStorage.getItem("photos"))[4])}
                        className="img-fluid img_half_height img_half_last_img"
                      /> */}
                      {/* <span className="show_all_btn">View All Photos</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overview mb-4 mt-2">
            <h2 className="mb-0">Overview</h2>
            <div className="my-3">
              <div className="card_ftr_secc mt-2">
                <div className="d-flex align-items-center ">
                  <img
                    src={guest1}
                    alt="guest"
                    className="me-2 img-fluid cmn_logo"
                  />
                  {console.log('floorplandatsgal', JSON.parse(localStorage.getItem("floorPlan")).find((el) =>{  return(el.type.includes('guest')) }))}
                  <span>{JSON.parse(localStorage.getItem("floorPlan")).find((el) =>{  return(el.type.includes('guest')) }) != undefined ? JSON.parse(localStorage.getItem("floorPlan")).find((el) =>{  return(el.type.includes('guest')) }).count: ""} guest</span>
                </div>
                <div className="d-flex align-items-center ">
                  <img
                    src={bed1}
                    alt="bed"
                    className="me-2 img-fluid cmn_logo"
                  />
                 <span>{JSON.parse(localStorage.getItem("floorPlan")).find((el) =>{  return(el.type.includes('Bedroom ')) }) != undefined ? JSON.parse(localStorage.getItem("floorPlan")).find((el) =>{  return(el.type.includes('Bedroom ')) }).count : ""} bedrooms</span>
                </div>
                <div className="d-flex align-items-center ">
                  <img
                    src={bathroom1}
                    alt="bathroom"
                    className="me-2 img-fluid cmn_logo"
                  />
                  <span>{JSON.parse(localStorage.getItem("floorPlan")).find((el) =>{  return(el.type.includes('Bathroom')) }) != undefined ? JSON.parse(localStorage.getItem("floorPlan")).find((el) =>{  return(el.type.includes('Bathroom')) }).count : ""} bathrooms</span>
                </div>
              </div>
            </div>
            <p className="mb-0">
              {localStorage.getItem("descriptionContent")}
              {/* From flexible length stays and no security deposits to affordable
              rates in prime neighborhoods, our properties go through a strict
              in-person vetting process to ensure that the quality standards,
              amenities, and accuracy of property description meet and exceed
              our requirements. */}
            </p>
          </div>
          <hr className="custom_overview_hr" />
          <div className="row mt-4">
            <div className="order-2 order-sm-1 col-xxl-8 col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12 leftcont">
              <div className="detail_content py-2" />
              <h2 className="name themeclr fw-600 mb-2">Rooms</h2>
              <div className="row mt-4 pb-3">
                <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-4 col-xxl-4 mb-3 mb-lg-4 mb-xxl-0">
                  <div className="card card_sleep">
                    <div className="card-body">
                      <p className="name fw-600">Master Bedroom</p>
                      <p className="detail_desc_grey f-16">
                        Rest in a king sized bed fit for two with an ensuite
                        bathroom
                      </p>
                      <div className="room_det mt-2">
                        <img
                          src={bed2}
                          className="room_img img-fluid"
                          alt="bed"
                        />
                        <img
                          src={bathroom2}
                          className="room_img img-fluid"
                          alt="bathroom"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="name themeclr fw-600 mb-2 mt-3">
                Amenities and Features
              </h2>
              <div className="amenties-card mt-4">
                {console.log('localStorge.getItem("amenitiesIndex")', JSON.parse(localStorage.getItem("amenitiesIndex")))}
                {localStorage.getItem("amenitiesIndex") &&  JSON.parse(localStorage.getItem("amenitiesIndex"))?.length > 0 &&  JSON.parse(localStorage.getItem("amenitiesIndex")).map((val) => <div className="cmn-card">
                  <div className="d-flex align-items-center amenities">
                    <img src={amenity} className="place_icon" alt="" />
                    <span className="ms-2 type f-16">{val}</span>
                  </div>
                </div>)}
              </div>
              <h2 className="mb-0 fw-600 mt-4">Booking Dates</h2>
              <div className="position-relative experience_calendar mt-4 no_border_picker">
                <Datepicker
                  className=""
                  onChange={(date) => {
                    handleDateChange(date);
                  }}
                  // selectsStart={true}
                  monthsShown={2}
                  minDate={new Date()}
                  // shouldCloseOnSelect={false}
                  // excludeDates={exludedates}
                  // selected={startDate}
                  // startDate={startDate}
                  // endDate={endDate}
                  inline={true}
                // holidays={holidays}
                />
              </div>
            </div>
            <div className="order-1 order-sm-2 col-xxl-4 col-xl-5 col-lg-5 col-md-4 col-sm-12 col-12 col_4_tiv rightcont_don_mob">
              <AvailCont
                id={roomId}
                email={email}
                onSetGuestCount={guestcount}
                roomDetails={roomDetails}
                onSetCancellation={cancellationPolicy}
                serviceFee={parseInt(serviceFeeTotal)}
                rentAmount={rentAmount}
                days={1}
                legal={roomDetails.legal}
                amountPerNight={roomDetails.price}
                startDate={startDate}
                endDate={endDate}
                spl={specialdates}
                subofferamount={subofferamount}
                suboffer={suboffer}
                defaultprice={defaultprice}
                exludedates={exludedates}
                normalrentamount={normalrentamount}
              />
            </div>
          </div>
          <hr className="custom_overview_hr mt-4" />
          <div>
            {/* <h2 className="name themeclr fw-600 mb-2 pt-4 pb-4">Location</h2> */}

            {/* <Freeze freeze={shouldSuspendRendering}>
              <div className="mapComponent map_detail">
                <RoomMap position={position} />
              </div>
            </Freeze> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckList;

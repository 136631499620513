import React, { useEffect, useState } from 'react';
import {Button, Modal} from "react-bootstrap"
import { toast, Toaster } from "react-hot-toast"
import img1 from "../../../../../../Assets/images/where/img1.png"
import { withdrawrequesthook } from '../../../../../../Hooks/filterHook';
import { getUserDatas, getUserEmail } from '../../../../../../Hooks/useUserHook';
// import { raisedisputehook } from '../../../../../../Hooks/adminHook';
// import { getUserDatas } from '../../../../../../Hooks/useUserHook';

const CreateDispute = (props) => {
    console.log('props?.avaliablebalance',props?.avaliablebalance)
    const[createdispute, setCreateDispute] = useState(true);
    const [withdraw , setWithdraw] = useState();
    console.log('withdraw>>>>>>>',withdraw)
    const [errors , setErrors] = useState("");
    console.log('errors',errors)
    const email = getUserEmail();

    useEffect(()=>{
        console.log("props?.avaliablebalance" , props?.avaliablebalance);
    })

    const handleamount = async(val) => {
        setErrors("");
        console.log("handle amount", val);
        if ( !/^[0-9]+$/.test(val) || val == 0) 
        { 
            setErrors("Invalid withdraw amount.") ;
        }
        if(isNaN(val) ){
            setErrors("Withdraw amount must be a number");
        }
        
        else{
            setWithdraw(val);
        }
    }

    const handlesubmit = async()=>{
        let user = getUserDatas();
        // if(user?.bankaccountstatus == "Approved"){
            await withdrawrequesthook(email , withdraw).then((result)=>{
                console.log("result" , result);
                if(result?.data?.type == "error"){
                    // debugger
                    console.log("result" , );
                    toast.error(result?.data?.msg);
                    // window.alert(result?.data?.msg)
                }
                else{
                    setCreateDispute(false);
                    props.onDismiss()
                }
            })
        // }
        // else if(user?.bankaccountstatus == "Pending"){
        //     toast.error("Your bank details is waiting for admin approval!")
        // }
        // else if(user.bankaccountstatus == "Rejected"){
        //     toast.error("your bank details rejected by admin!")
        // }
        // else if(!user.bankaccountstatus){
        //     toast.error("upload your bank details!")
        // }
    }
    

    return(
        <>
           <Modal show={createdispute} centered scrollable size="md" className='modal_style   dispute'>
                <Modal.Header className='border-0 pb-0'>
                    <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div>

                    <div class="text-center">
                        <h4 class="txtblue fw-500 mb-4">Withdraw Request</h4>
                       
                        </div>


                      
                        {/* <p>Dispute Image</p> */}
                        {/* <div className='uploadfile'>
                        <span className='fa fa-image'></span>
                            <input type="file" className='form-control' 
                             onChange={(e)=>{
                                console.log("e" , e?.target?.files[0] , URL.createObjectURL(e?.target?.files[0]));
                                setImage(e?.target?.files[0]);
                                setImageshow(URL.createObjectURL(e?.target?.files[0]))
                             }}/>
                            <img src={imageshow} className="img d-none" />
                            <button className='btn btn-link'>upload</button>
                           
                        </div> */}
                        <p className='mt-3 name themeclr fw-600 f-14 mb-2'>Enter Withdraw Amount : </p>
                        <input className="form-control font_cot_big" type="text" min={0}  max = {props?.avaliablebalance}
                            onChange ={
                                
                                (e)=>{setWithdraw();
                                    handleamount(e?.target?.value);
                                }
                            }
                        ></input>
                        <p className='text-danger er_text mt-3'>{errors}</p>
                        {/* <p className='mt-3'>Dispute Reason </p> */}
                       {/* <textarea rows="5" className='form-control mb-4 mt-3'
                           
                           
                        ></textarea> */}
                        <div className='text-right'>
                       <button className='btn btn-theme flex-shrink-0 my-4' disabled = {withdraw > props?.avaliablebalance  ||  isNaN(withdraw) || !withdraw || errors } onClick={()=>handlesubmit()}>Withdraw to bank account</button>
                       </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateDispute